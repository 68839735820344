import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";
import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Inbox, LogOut, Taskboard } from "../../../Constant";
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import { getProfileImageUrl } from "../../../_helper/Images/ImageHelper";

const UserHeader = () => {
  const navigate = useNavigate();
  const { authenticated, logout , userData} = useAuth();
  const [profile, setProfile] = useState('');
  const { layoutURL } = useContext(CustomizerContext);

  useEffect(() => {
    if (authenticated && userData) {
      setProfile(userData.image || man);   
    } else {
      setProfile(man);
    }
  }, [authenticated, userData]);
  

  const UserMenuRedirect = (redirect) => {
    navigate(redirect);
  };
  
  const handleLogout = (event) => {
    event.preventDefault();
    logout();
    // window.location.reload();
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <Image
          attrImage={{
            className: "img-30 rounded-circle media", // Added img-fluid class for responsiveness
            src: getProfileImageUrl(profile),
            alt: "",
          }}
        />
        <div className="media-body">
          <span>{userData.fullname}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {userData.role} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/account/${layoutURL}`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`),
          }}>
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`),
          }}>
          <FileText />
          <span>{Taskboard}</span>
        </LI>
        <LI attrLI={{ onClick: handleLogout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
