import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4
import { Btn } from "../../../../../AbstractElements";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";

export default function Contributors({
  handleNextSecondStep,
  control,
  errors,
  assetIndex,
  resetField, 
  clearErrors,
  selectedAsset,
  setValue,
}) {
  const {
    register,
  } = useForm();

  const [formGroups, setFormGroups] = useState([{ id: uuidv4() }]);
  const [contributors, setContributors] = useState([]);

  // console.log ("contributors",contributors);
  const handleAdd = () => {
    setFormGroups([...formGroups, { id: uuidv4() }]); 
  };

  const handleDelete = (index) => {
    if (formGroups.length > 1) {
      setFormGroups(formGroups.slice(0, -1));
      resetField(`contributor_name_${assetIndex}_${index}`);
      resetField(`contributor_role_${assetIndex}_${index}`);
      resetField(`contributor_percentage_${assetIndex}_${index}`);
    }
  };
  // console.log("Files -> selectedAsset", selectedAsset);
  // console.log("Contributors -> contr", contributors);

  useEffect(() => {
    register();
    if (selectedAsset && selectedAsset.contributors) {
      setContributors(selectedAsset.contributors);
      selectedAsset.contributors.forEach((contributor, index) => {
        setValue(`contributor_name_${assetIndex}_${index}`, contributor.name || "");
        setValue(`contributor_role_${assetIndex}_${index}`, contributor.role || "");
        setValue(`contributor_percentage_${assetIndex}_${index}`, contributor.percentage || "");
      });
    } else {
      setContributors([]);
    }
  }, [selectedAsset, setValue, formGroups, register]);

  return (
    <Form className="theme-form mt-3" onSubmit={handleNextSecondStep}>
      {formGroups.map(({ id }, i) => ( 
        <div className="row" key={id}>
          <FormGroup className="col-sm-3 mb-3">
            <Label className="col-form-label">Contributor Name</Label>
            <Controller
              name={`contributor_name_${assetIndex}_${i}`}
              control={control}
              defaultValue={contributors[i]?.name || ""}
              /* rules={{ required: "Contributor is required" }} */
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Contributor Name"
                  className="form-control"
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors(`contributor_name_${assetIndex}_${i}`);
                  }}

                />
              )}
            />
            {/* <span style={{ color: "red" }}>
              {errors[`contributor_name_${assetIndex}_${i}`] &&
                errors[`contributor_name_${assetIndex}_${i}`].message}
            </span> */}
          </FormGroup>
          <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Role</Label>
          <Controller
            name={`contributor_role_${assetIndex}_${i}`}
            control={control}
            defaultValue={contributors[i]?.contributor_role || ""}
            /* rules={{ required: "Role is required" }} */
            render={({ field }) => (
              <Input
                {...field}               
                type="select"
                placeholder="Select Type"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`contributor_role_${assetIndex}_${i}`);
                }}
              >
                <option value="">Select...</option>
                <option value="composer">Composer</option>
                <option value="lyricist">Lyricist</option>
                <option value="engineer">Mastering Engineer</option>
                <option value="producer">Producer</option>
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`contributor_role_${assetIndex}_${i}`] &&
              errors[`contributor_role_${assetIndex}_${i}`].message}
          </span> */}
        </FormGroup>
        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Percentage</Label>
          <InputGroup>
            
            <Controller
              name={`contributor_percentage_${assetIndex}_${i}`}
              control={control}
              defaultValue={contributors[i]?.contributor_percentage || ""}
              /* rules={{ required: "Percentage is required" }} */
              render={({ field }) => (
                <input
                  {...field}
                  className="form-control"
                  type="number"
                  placeholder="%"
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors(`contributor_percentage_${assetIndex}_${i}`);
                  }}
                />
              )}
            />
            <InputGroupText>{"%"}</InputGroupText>
            </InputGroup>
            {/* <span style={{ color: "red" }}>
              {errors[`contributor_percentage_${assetIndex}_${i}`]
               && errors[`contributor_percentage_${assetIndex}_${i}`].message}
            </span> */}
        
        </FormGroup>
          <Col className="m-t-40">
            <div style={{ display: "flex", gap: "10px" }}>
              <FormGroup className="mb-3">
                <Btn
                  attrBtn={{
                    type: "button",
                    size: "s",
                    color: "primary",
                    outline: true,
                    onClick: handleAdd,
                  }}
                >
                  <i className="fa fa-plus-square me-1"></i>
                </Btn>
              </FormGroup>
              {i > 0 && (
                <FormGroup className="mb-3">
                  <Btn
                    attrBtn={{
                      type: "button",
                      size: "s",
                      color: "warning",
                      outline: true,
                      onClick: () => handleDelete(i),
                    }}
                  >
                    <i className="fa fa-minus-square"></i>
                  </Btn>
                </FormGroup>
              )}
            </div>
          </Col>

          <hr />
        </div>
      ))}
    </Form>
  );
}
