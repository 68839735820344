import ImageSlider from "./ImageSlider";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
} from "reactstrap";
import { Fragment } from "react";
import React, { useState, useEffect, useContext } from "react";
import MainTabs from "./MainTabs/Index";
import ProductActivity from './ProductActivity';
import {
  Breadcrumbs,
  Btn,
  H4,
  H6,
} from "../../../../../AbstractElements";
import MetadataInput from "./MetadataInput";
import { useNavigate, useParams  } from "react-router-dom";
import { useReleaseContext } from "../../../../../_helper/Release/ReleaseProvider";
import CustomizerContext from '../../../../../_helper/Customizer';
import { useForm } from "react-hook-form";
import NewAsset from "./Modals/NewAssetModal";
import { useDspContext } from "../../../../../_helper/DSP/DspProvider";
import { toast } from "react-toastify";
import  GridLoader  from "../../../../Loaders/GridLoader"
import  ReleaseService  from "../../../../../Services/release/ReleaseService";
import Swal from "sweetalert2";
import MissingDataAlert from './MissingDataAlert';
import { useAuth } from '../../../../../_helper/Auth/AuthProvider';

const ReleasePage = () => {
  const { dsps, importData, fetchDsp, addDSP } = useDspContext();
  const { userData } = useAuth();
  const isAdmin = userData?.role === 'admin';

  const {
    artists,
    fetchProductById,
    genres,
    countries,
    fetchArtists,
    fetchGenres,
    getAllCountries,
    loading,
    updateProductMetadata,
    updateProductPicture,
    fetchedProductassets,
  } = useReleaseContext();

  // console.log("from Provider",assets)

  const { control, handleSubmit, errors, setValue, clearErrors, resetField, trigger, getValues } = useForm({});

  const { id } = useParams();
  const navigate = useNavigate();
  const [productID, setProductId] = useState(id);
  const { layoutURL } = useContext(CustomizerContext);
  const [showModal, setShowModal] = useState(false);
  const [fetchedProduct, setFetchedProduct] = useState(null);
  const [assetsUpdated, setAssetsUpdated] = useState(false);
  const [loadingData, setLoadingData] = useState(true); 
  const [missingData, setMissingData] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchProductById(productID);
        setFetchedProduct(response); 
        //setAssets(response.assets);
        setLoadingData(false); 
      } catch (error) {
        console.error("Failed to fetch product:", error);
      }
    };
    fetchArtists();
    fetchData();
    fetchGenres();
    fetchDsp();
    getAllCountries();

    return () => {
    };
  }, [productID, assetsUpdated]);
  
  const onUpdateMetada = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const formData = getValues();
    try {
      const { success, error } = await updateProductMetadata(productID, formData);
      if (success) {
        const response = await fetchProductById(productID);
        setFetchedProduct(response);
        toast.success("Product Metadata Updated Successfully!");
      } else {
        console.error("Failed to update metadata:", error);
      }
    } catch (error) {
      console.error("Failed to update metadata:", error);
    }
  };

  const handleDelivery = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once delivered, you will no longer be able to update the product!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, deliver it!'
    }).then(async (result) => { 
      if (result.isConfirmed) {
        // console.log('Product delivered!');
        try {
          const isValidProduct = await ReleaseService.validateProduct(productID);
          if (!isValidProduct) {
            console.error("isValidProduct is undefined");
          } else if (isValidProduct.missingInfo.length === 0) {
            // Delivery logic
          } else {
            setMissingData(isValidProduct.missingInfo);
          }
        } catch (error) {
          console.error("Error validating product:", error);
        }
      }
    });
  };
  
  
  
  
  
  

  

  return (
    <Fragment>
      <Breadcrumbs
        parent="Releases"
        title="Product Page"
        mainTitle="Product Page"
        buttons={[
          <Btn
            attrBtn={{
              size: "l",
              color: "primary",
              onClick: () => {
                navigate(
                  `${process.env.PUBLIC_URL}/app/releases/catalog/${layoutURL}`
                );
              },
            }}
          >
            <i className="fa fa-arrow-left me-1"></i>
            {"Back"}
          </Btn>,
          <Btn
            attrBtn={{
              size: "l",
              color: "primary",
              onClick: onUpdateMetada, 
            }}
          >
            <i className="fa fa-edit me-1"></i>
            Update Metatada
          </Btn>,
          
          <Btn
            attrBtn={{
              size: "l",
              color: "primary",
              onClick: handleDelivery, 
              //onClick: () => setShowModal(true),
            }}
          >
            <i className="fa fa-check me-1"></i>
              Deliver
          </Btn>,
        ]}
      />
      {loadingData ? ( 
        <GridLoader />
      ) : (
        
        <Container fluid={true}>

        {missingData.length > 0 && (
          <MissingDataAlert missingData={missingData} onDelivery={handleDelivery} />
        )}

        <div>
        
          <Row className="product-page-main p-0">
            <Col xxl="4" md="4" className="box-col-5">


                <Card>
                  <CardHeader>
                    <H4>Artwork</H4>
                    <H6>{fetchedProduct.title}</H6>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <ImageSlider fetchedProduct={fetchedProduct} updateProductPicture={updateProductPicture} imageSize="100px" />
                    </Row>
                  </CardBody>
                </Card>
              
            </Col>
                
            <Col md="8" className="box-col-5">

                <MetadataInput
                  control={control}
                  errors={errors}
                  loading={loading}
                  artists={artists}
                  genres={genres}
                  setValue={setValue}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  fetchedProduct={fetchedProduct} 
                  onSubmit={handleSubmit(onUpdateMetada)}
                />
 
            </Col>
          </Row>
         
          </div>

          {/* <ProductActivity isAdmin={isAdmin} histories={fetchedProduct.histories} /> */}


        

            <MainTabs
              productId={fetchedProduct?.id}
              assets={fetchedProductassets}
              artist={fetchedProduct?.user}
              //setAssets={setAssets}
              countries={countries}
              additionalInfo={fetchedProduct?.additional_information}
              genres={genres}
              artists={artists}
              dsps={dsps}
              fetchedProduct={fetchedProduct} 
              i={fetchedProduct?.assets.length}
            />
          
        </Container>
        )}
      
    </Fragment>
  );
};
export default ReleasePage;
