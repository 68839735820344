import React, { Fragment, useState } from 'react';
import { Row, Card, Col } from 'reactstrap';
import { H6, P, ToolTip, Btn } from '../../../../AbstractElements';
import { NoBookmarksFound } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { Heart, Share, Play, MessageCircle, Download, Airplay } from 'react-feather'; 

const TiktokFeed = ({ tiktokFeedVideos }) => {

    const [basictooltip, setbasictooltip] = useState(false);
    const toggle = () => setbasictooltip(!basictooltip);

    return (
        <Fragment>
            {tiktokFeedVideos.length > 0 ? (
                <Row id='bookmarkData'>
                    {tiktokFeedVideos.map((video) => (
                        <Col key={video.id} xl='3' md='4' className='mb-4'>
                            <Card className='p-3 border-0 shadow-sm bg-light'>
                                <div className='text-center'>
                                    <div className='mb-1' style={{ height: '200px', overflow: 'hidden' }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundImage: `url(${video.cover})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        />
                                    </div>
                                    <span className='text-center text-muted'>
                                        {new Date(video.created_at).toLocaleDateString()}
                                    </span>
                                    <div className='text-center'>
                                        <H6 className='mb-0'>{video.title}</H6>
                                        <P className='text-muted mb-2' >
                                            <Link to={video.video_id} target='_blank' rel='noopener noreferrer'>
                                                {video.video_id}
                                            </Link>
                                        </P>
                                        <div className='text-muted'>
                                            <span title='shares'>
                                                <Share size={16} style={{ marginRight: '5px' }} /> {video.share_count || 0} |
                                            </span>

                                            <span title='likes'>
                                                <Heart size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> {video.digg_count || 0} |
                                            </span>

                                            <span title='plays'>
                                                <Play size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> {video.play_count || 0} |
                                            </span>

                                            <span title='comments'>
                                                <MessageCircle size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> {video.comment_count || 0} |
                                            </span>
                                            <span title='downloads'>
                                                <Download size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> {video.download_count || 0} |
                                            </span>

                                            <span title='collection'>
                                                <Airplay size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> <span >{video.collect_count || 0}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                <Col xl='12' className='text-center'>
                       Not available Yet
                </Col>
            )}
        </Fragment>
    );
};

export default TiktokFeed;
