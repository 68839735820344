import React, { Fragment, useCallback, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Btn, UL, LI, Image } from "../../../../AbstractElements";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import AddDspModal from "../NewDSP/AddDspModal";
import { useDspContext } from "../../../../_helper/DSP/DspProvider";
import moment from "moment";
import AdditionalRowsModal from "./AdditionalRowsModal";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import EmptyDataTable from "../../../Dashboard/Common/EmptyDataTable";
const DspTable = ({
  dsps,
  inUseFilter,
  setInUseFilter,
  showAddDspModal,
  setShowAddDspModal,
  onDeleteDsp,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState([]);
  // const [inUseFilter, setInUseFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [showAddDspModal, setShowAddDspModal] = useState(false);
  const [initialRowsToShow, setInitialRowsToShow] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const { register, trigger, getValues, control, setValue } = useForm();
  const { addDsp, updateDsp } = useDspContext();

  // const handleRowSelected = useCallback((state) => {
  //     setSelectedRows(state.selectedRows);
  //   }, []);

  useEffect(() => {
    setData(dsps);
  }, [dsps]);



  const handleDelete = (dspId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete this DSP?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onDeleteDsp(dspId);
        setData(data.filter((item) => item.id !== dspId));
      }
    });
  };

  // const handleShowAddDspModal = () => {
  //   setShowAddDspModal(true);
  // };

  const handleUpdate = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const formData = getValues();

    const { success, error } = await updateDsp(selectedRows.id, formData);
    if (success) {
      const updatedData = data.map((item) => {
        if (item.id === selectedRows.id) {
          return {
            ...item,
            ...formData,
          };
        }
        return item;
      });
      setData(updatedData);
      handleCloseModal();
      toast.success("DSP updated successfully!");
    } else {
      console.error("Error updating DSP:", error);
      toast.error("Error updating DSP!");
    }
  };

  const handleAddDsp = async (newDspData) => {
    const response = await addDsp(newDspData);
    if (response.success) {
      const newDspWithId = response.dsp;
      setData([...data, newDspWithId]);
      setShowAddDspModal(false);
      toast.success("DSP Added successfully!");
    } else {
      // console.error("Error Adding DSP:", error);
      toast.error("Error adding DSP!");
    }
  };

  // const handleShowModal = () => {
  //   setShowModal(true);
  // };

  const handleCloseModal = () => {
    setShowModal(false);
    setInitialRowsToShow(5);
  };

  const handleShowMore = (row) => {
    setSelectedRows(row);
    setShowModal(true);
  };

  // const handleFilterUsed = () => {
  //   setInUseFilter(true);
  // };

  // const handleFilterNotUsed = () => {
  //   setInUseFilter(false);
  // };

  // const clearFilters = () => {
  //   setInUseFilter(null);
  // };

  const columns = [
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
    },
    {
      name: "URL",
      selector: (row) => row["url"],
      sortable: true,
    },
    //todo: create a new logo for the default mode this working on Dark Mode
    {
      name: "Logo",
      cell: (row) => (
        <div>
          <UL attrUL={{ className: "d-inline-block" }} >
            <LI
              attrLI={{ className: "d-inline-block " }}
            >
              <Image
                attrImage={{
                  className: "img-80",
                  src: row["logo"],
                  alt: "Dsp Image",
                }}
              />
            </LI>
          </UL>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row["description"],
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="todo">
          <div className="todo-list-wrapper">
            <div className="todo-list-container todo-list-footer">
              <ul className="simple-list">
                <li className="task border-0">
                  <div className="task-container">
                    <span className="task-action-btn">
                      <span
                        className="action-box large icon-edit"
                        title="Update"
                        onClick={() => handleShowMore(row)}
                      >
                        <i className="icon">
                          <i className="fa fa-edit"></i>
                        </i>
                      </span>
                      <span
                        className="action-box large delete-btn"
                        title="Delete"
                        onClick={() => handleDelete(row.id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                      <span
                        className="action-box large delete-btn"
                        title="Update"
                        onClick={() => {}}
                      >
                        <i className="icon">
                          <i className="icon-edit"></i>
                        </i>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
      center: true,
    },
    {
      name: "Territories",
      selector: (row) => row["territories"],
      sortable: true,
    },
    {
      name: "Lead Time",
      selector: (row) => moment(row["lead_time"]).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    {
      name: "XML File",
      selector: (row) => row["xml_file"],
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>
        moment(row["created_at"]).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) =>
        moment(row["updated_at"]).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
  ];

  const filteredData = data.filter((item) => {
    if (inUseFilter === null) {
      return true;
    } else {
      return item.in_use === (inUseFilter ? 1 : 0);
    }
  });

  const initialColumns = columns.slice(0, 6);

  return (
    <Fragment>
      <AddDspModal
        show={showAddDspModal}
        handleClose={() => setShowAddDspModal(false)}
        handleAddDsp={handleAddDsp}
      />
  
      {filteredData.length > 0 ? (
        <DataTable
          data={filteredData}
          columns={initialColumns}
          striped={true}
          center={true}
          pagination
          paginationPerPage={5}
          clearSelectedRows={toggleDelete}
          highlightOnHover
        />
      ) : (
        <EmptyDataTable />
      )}
  
      <AdditionalRowsModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        data={data}
        selectedRows={selectedRows}
        handleUpdate={handleUpdate}
        register={register}
        Controller={Controller}
        control={control}
        setValue={setValue}
      />
    </Fragment>
  );
};

export default DspTable;
