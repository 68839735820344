import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import Error503 from '../Components/Pages/ErrorPages/ErrorPage503';
import PrivateRoute from './PrivateRoute';
import FileDisplayPage from '../Components/Application/SmartLink/Common/FileDisplayPage';
import GeniusLayout from "../Components/Application/GeniuesLink/GeniusLayout";
const LayoutRoutes = () => {
  return (
    <Routes>
      {routes.map(({ path, Component, roles }, i) => (
        <Route key={i}  element={<PrivateRoute roles={roles} />}>
          <Route element={<AppLayout />}>
            <Route path={path} element={Component} />
          </Route>
        </Route>
      ))}
      <Route path={`${process.env.PUBLIC_URL}/app/transfer/:param/:layout`} element={<FileDisplayPage />} />
      <Route path={`${process.env.PUBLIC_URL}/smartLink/:productName`} element={<GeniusLayout />} />
      <Route path="*" element={<Error503 />} />
    </Routes>
  );
};

export default LayoutRoutes;