import axiosAccounting from "../../api/accounting";

const ProductService = {
  getAllProducts: async () => {
    try {
      const response = await axiosAccounting.get("/products");
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to fetch products", error);
      return { success: false, error };
    }
  },

  syncProducts: async () => {
    try {
      const response = await axiosAccounting.post("/save-all");
      if (response.status >= 200 && response.status < 300) {
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || "Failed to sync products");
      }
    } catch (error) {
      console.error("Failed to sync products", error);
      throw error;
    }
  },
  getProductById: async (id) => {
    try {
      const response = await axiosAccounting.get(`/products/${id}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to fetch product with id ${id}`, error);
      return { success: false, error };
    }
  },
  getAssetById: async (id) => {
    try {
      const response = await axiosAccounting.get(`/assets/${id}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to fetch asset with id ${id}`, error);
      return { success: false, error };
    }
  },
  createContributor: async (assetId, contributorData) => {
    try {
      const response = await axiosAccounting.post(
        `/assets/${assetId}/contributors`,
        contributorData
      );
      return { success: true, data: response.data };
    } catch (error) {
      console.error(
        `Failed to create contributor for asset with id ${assetId}`,
        error
      );
      return { success: false, error };
    }
  },
  updateContributor: async ({id, formData}) => {
    // console.log("updateContributor", id, formData);
    // console.log("id", id);
    try {
      const response = await axiosAccounting.put(
        `/assets/contributors/${id}`,
        formData
      );
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to update contributor with id ${id}`, error);
      return { success: false, error };
    }
  },

  deleteContributor: async (id) => {
    try {
      const response = await axiosAccounting.delete(`/assets/contributors/${id}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to delete contributor with id ${id}`, error);
      return { success: false, error };
    }
  },
  
  getRoyalties: async () => {
    try {
      const response = await axiosAccounting.get("/royalties");
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to fetch royalties", error);
      return { success: false, error };
    }
  },
};

export default ProductService;
