import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
} from "reactstrap";
import { P, Btn, Breadcrumbs } from "../../../../../../AbstractElements";
import { useReleaseContext } from "../../../../../../_helper/Release/ReleaseProvider";
import { useForm, FormProvider } from "react-hook-form";
import NewAsset from "../Modals/NewAssetModal";
import AssetTable from "./Asset";
import Countries from "./Countries";
import Dsp from "./Dsp";
import { toast } from "react-toastify";
import { set } from "date-fns";
import GridLoader from "../../../../../Loaders/GridLoader";
const MainTabs = ({
  artist,
  countries,
  additionalInfo,
  dsps,
  artists,
  genres,
  i,
  productId,
  assets,
  fetchedProduct,
  setAssets,
  onUpdateFormCompletion,
}) => {
  //? Form Solution
  const newAssetFormMethods = useForm();
  const updateAssetFormMethods = useForm();

  const { saveSecondStepData, updateAsset, updateAssets, loading , updateProductAddInfos  } =
    useReleaseContext();

  const [activeTab, setActiveTab] = useState("1");

    //todo: supposed to be parsed from the backend
  const [selectedCountries, setSelectedCountries] = useState(
    additionalInfo
      ? additionalInfo.flatMap((info) => JSON.parse(info.selected_countries))
      : []
  );
  const [selectedCountryRows, setSelectedCountryRows] = useState([]);

  //todo: supposed to be parsed from the backend
  const [selectedDsps, setSelectedDsps] = useState(
    additionalInfo
      ? additionalInfo.flatMap((info) => JSON.parse(info.selected_dsps))
      : []
  );
  const [selectedDspRows, setSelectedDspRows] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [newAsset, setNewAsset] = useState({});
  // const [stateUpdate, setStateUpdate] = useState(false);

// console.log("assets",assets);
//? changes to the table without refreshing the page
//todo: manage the Assets state in the context after creating the asset
  const onNewAsset = (newAsset) => {
    console.log("newAsset after Adding ",newAsset);
  setNewAsset(newAsset);
  }

  // console.log("selectedDspRows",selectedDspRows); 
  // console.log("selectedCountryRows",selectedCountryRows);
  // console.log("selectedDsps", selectedDsps);
  // console.log("dsps", dsps);

  const updateAdditionalInfos = async (type) => {
    let data;
    let successMessage;
    let errorMessage;
  
    if (type === 'dsps') {
      data = { selectedDsps: selectedDspRows };
      successMessage = 'DSPs updated successfully';
      errorMessage = 'Failed to update DSPs';
    } else if (type === 'countries') {
      data = { selectedCountries: selectedCountryRows };
      successMessage = 'Countries updated successfully';
      errorMessage = 'Failed to update countries';
    }
  
    const response = await updateProductAddInfos(productId, data);
    if (response.success) {
      toast.success(successMessage);
      // Update the states
      if (type === 'dsps') {
        setSelectedDsps(selectedDspRows);
      } else if (type === 'countries') {
        setSelectedCountries(selectedCountryRows);
      }
    } else {
      toast.error(errorMessage);
    }
  };
  


  // const handleAssetUpdate = (updatedAsset) => {
  //   setAssets(prevAssets => prevAssets.map(asset => asset && asset.id === updatedAsset.id ? updatedAsset : asset));
  // };
 
  // useEffect(() => {
  //   setStateUpdate(true);
  // }, [assets, stateUpdate]);

  //todo: add the gridloader for the tabs inside card 
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <Col md="20">
            <div className="d-flex justify-content-end">
              {activeTab === "1" && (
                <Btn
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    outline: true,
                    onClick: () => setShowModal(true), 
                  }}
                >
                  <i className="fa fa-plus-square me-1"></i>
                  Add Asset
                </Btn>
              )}
              {activeTab === "2" && (
                <Btn
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    outline: true,
                      onClick: () => updateAdditionalInfos('dsps'),
                  }}
                >
                  <i className="fa fa-edit me-1"></i>
                  Update Dsp
                </Btn>
              )}
              {activeTab === "3" && (
                <Btn
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    outline: true,
                    onClick: () => updateAdditionalInfos('countries'),
                  }}
                >
                  <i className="fa fa-edit me-1"></i>
                  Update Countries
                </Btn>
              )}
            </div>
          </Col>
        </CardHeader>
        {loading ? (
      <GridLoader />
    ) : (
        <div className="product-page-main">
          <Row className="m-0">
            <Col sm="12">
              <Nav tabs className="border-tab nav-primary mb-0 ">
                <NavItem id="myTab" role="tablist">
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    Asset
                  </NavLink>
                  <div className="material-border"></div>
                </NavItem>
                <NavItem id="myTab" role="tablist">
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    DSP
                  </NavLink>
                  <div className="material-border"></div>
                </NavItem>
                <NavItem id="myTab" role="tablist">
                  <NavLink
                    className={activeTab === "3" ? "active" : ""}
                    onClick={() => setActiveTab("3")}
                  >
                    Countries
                  </NavLink>
                  <div className="material-border"></div>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <FormProvider {...updateAssetFormMethods}>
                    <AssetTable
                      assets={assets}
                      //setAssets={setAssets}
                      loading={loading}
                      artist={artist}
                      newAsset={newAsset}
                      //handleAssetUpdate={handleAssetUpdate}
                      artists={artists}
                      genres={genres}
                      fetchedProduct={fetchedProduct}
                      i={i}
                      // UpdateAssetRecord={UpdateAssetRecord}
                    />
                  </FormProvider>
                </TabPane>
                <TabPane tabId="2">
                  <Dsp
                    dsps={dsps}
                    selectedDsps={selectedDsps}
                    setSelectedDsps={setSelectedDsps}
                    setSelectedDspRows={setSelectedDspRows}
                    selectedDspRows={selectedDspRows}
                    //updateDsp
                  />
                </TabPane>
                <TabPane tabId="3">
                  <Countries
                    countries={countries}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    selectedCountryRows={selectedCountryRows}
                    setSelectedCountryRows={setSelectedCountryRows}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
         )}
      </Card>
      <FormProvider {...newAssetFormMethods}>
        <NewAsset
          show={showModal}
          handleClose={() => setShowModal(false)}
          // handleAddAsset={handleAddAsset}
          artists={artists}
          productId={productId}
          onNewAsset={onNewAsset}
          // loading={loading}
          genres={genres}
          i={i}
        />
      </FormProvider>
    </Fragment>
  );
};

export default MainTabs;
