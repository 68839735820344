import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { H5, Btn, Spinner, UL } from "../../../../AbstractElements";
import {
  Email,
  Password,
  Artist,
  Label as labelrole,
  Staff,
  CreateNewUser,
  User_Name,
  User_FullName,
  Username,
  Submit,
} from "../../../../Constant";
import { Typeahead } from "react-bootstrap-typeahead";
import GridLoader from "../../../Loaders/GridLoader";
const FormLayout = ({
  register,
  errors,
  handleSubmit,
  loading,
  watch,
  permissions,
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const handlePermissionsChange = (selected) => {
    setSelectedPermissions(selected);
  };


  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <H5>Create New Partner</H5>
            <span>{"Carefully fill out the form and create a new partner"}</span>
          </CardHeader>
          <CardBody>
            {loading ? (
              <GridLoader/>
            ) : (
              <Form className="theme-form" onSubmit={handleSubmit}>
                <div className="row">
                  <FormGroup className="col-sm-6 mb-3">
                    <Label className="col-form-label col-sm-3">{Email}</Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        {...register("email", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.email && "Email is required"}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="col-sm-6 mb-3">
                    <Label className="col-form-label col-sm-3">
                      {User_FullName}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Fullname"
                        {...register("fullname", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.fullname && "Fullname is required"}
                      </span>
                    </Col>
                  </FormGroup>
                </div>
                <div className="row">
                  <FormGroup className="col-sm-6 mb-3">
                    <Label className="col-sm-3 col-form-label">
                      {User_Name}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        {...register("name", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.name && "Name is required"}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="col-sm-6 mb-3">
                    <Label className="col-sm-3 col-form-label">
                      {Username}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Username"
                        {...register("username", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.username && "Username is required"}
                      </span>
                    </Col>
                  </FormGroup>
                </div>
                <div className="row">
                  <FormGroup className="col-sm-6 mb-3">
                    <Label className="col-sm-3 col-form-label">
                      Phone Number
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone Number"
                        {...register("phonenumber", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.phonenumber && "Phone Number is required"}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup className="col-sm-6 mb-3">
                    <Label className="col-sm-3 col-form-label">
                      {Password}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        {...register("password", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.password && "Password is required"}
                      </span>
                    </Col>
                  </FormGroup>
                </div>
                <FormGroup className="mt-4">
                  <Row>
                    <Label className="col-form-label col-sm-3 pt-0">
                      User Type
                    </Label>
                    <Col sm="9">
                      <div className="radio radio-primary ms-2">
                        <input
                          type="radio"
                          name="role"
                          id="radio1"
                          value="artist"
                          {...register("role", { required: true })}
                        />
                        <Label for="radio1">{Artist}</Label>
                      </div>
                      <div className="radio radio-primary ms-2">
                        <input
                          type="radio"
                          name="role"
                          id="radio3"
                          value="staff"
                          {...register("role")}
                        />
                        <Label for="radio3">{Staff}</Label>
                      </div>
                      <span style={{ color: "red" }}>
                        {errors.role && "User Type is required"}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mt-4">
                  <Row>
                    <Label
                      className="col-form-label col-sm-3 pt-0"
                      for="multiple-typeahead"
                    >
                      {" "}
                      Permissions
                    </Label>
                    <Col sm="9">
                      <div>
                        <Typeahead
                          id="multiple-typeahead"
                          clearButton
                          defaultSelected={permissions.slice(0, 5)}
                          labelKey={"name"}
                          multiple
                          options={permissions}
                          placeholder="Choose a permission..."
                          selected={selectedPermissions}
                          onChange={handlePermissionsChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                <hr />
                <FormGroup className="row text-center">
                  <Col md={{ size: 6, offset: 3 }}>
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "m-r-15",
                        type: "submit",
                        style: {
                          padding: "10px 20px",
                          fontSize: "16px",
                        },
                      }}
                    >
                      {loading ? "Loading..." : Submit}
                    </Btn>
                  </Col>
                </FormGroup>
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FormLayout;
