import React, { useState } from "react";
import { Col, Row, Button } from "reactstrap";

import { H6, Image, P, Progressbar } from "../../../../AbstractElements";

import { AccountProgress } from "../../../../Constant";
import { getProfileImageUrl } from "../../../../_helper/Images/ImageHelper";
import CustomizerContext from "../../../../_helper/Customizer";
const CusClass = ({ item, onEdit }) => {

  const { mix_background_layout} = React.useContext(CustomizerContext);
  // Dropdown toggle
  //const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Define color classes for each role
  const roleBadgeColors = {
    admin: "badge-primary",
    artist: "badge-warning",
    staff: "badge-secondary",
    label: "badge-success",
  };

  const statusBadgeColors = {
    true: "badge-success",
    false: "badge-primary",
  };

  const formatDateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = new Date(item.created_at).toLocaleString(
    "en-US",
    formatDateOptions
  );
    //TODO : enhance this DARK/LIGHT mode Solution
  const textColorClass = mix_background_layout === 'light-only' ? 'text-black' : 'text-white';

  return (
    <Col className="col-xxl-4" md="6">
      <div className="project-box">
        <span className={`badge ${roleBadgeColors[item.role]}`}>
          {item.role}
        </span>
        <div className="media">
          <Image
            attrImage={{
              className: "img-70 me-2 rounded-circle",
              src: getProfileImageUrl(item.image),
              alt: "",
            }}
          />
          <div className="align-self-center">
          <H6 attrH6={{ className: 'f-light' }}>{item.name}</H6>
          </div>
        </div>

        <Row className="details">
          <Col xs="10">
            <span style={{ fontWeight: "bold" }} className={'f-light'}>
              {" "}
              Email:{" "}
            </span>
            <span className="f-light">
              <b>{item.email}</b>
            </span>
          </Col>
          <Col xs="10">
            <span style={{ fontWeight: "bold" }} className={'f-light'}>
              {" "}
              Username:{" "}
            </span>
            <span className="f-light">
              <b>{item.username}</b>
            </span>
          </Col>
          <Col xs="10">
            <span style={{ fontWeight: "bold" }} className={'f-light'}>
              {" "}
              Creation Date:{" "}
            </span>
            <span className="f-light">
              <b>{formattedDate}</b>
            </span>
          </Col>
          <Col xs="10" className="d-flex align-items-center">
            <span style={{ fontWeight: "bold" }} className={'f-light'}>
              {" "}
              Security Status:{" "}
            </span>
            &nbsp;
            <span
              className={`f-light ${
                item.status_compte ? "text-success" : "text-danger"
              }`}
            >
              <b>{item.status_compte ? "Activated" : "Disabled"}</b>
            </span>
          </Col>
        </Row>

        <div className="project-status mt-4">
          <div className="media mb-0">
          <P attrPara={{ className: 'f-light' }}>{item.progress}% </P>
            <div className="media-body text-end">
            <span className={'f-light'}>{AccountProgress}</span>
            </div>
          </div>
          <div className="progress">
            <div
              className="progress-bar bg-primary"
              role="progressbar"
              style={{ width: `${item.progress}%` }}
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>

        {/* Edit button */}
        <div className="d-flex justify-content-center mt-2">
          <Button color="primary" onClick={() => onEdit(item)}>
            Edit
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default CusClass;
