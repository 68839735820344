import React, { Fragment } from 'react';
import { Row, Card, Col } from 'reactstrap';
import { H6, P, Image } from '../../../../AbstractElements';
import { NoBookmarksFound } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { Heart, Share, Play, MessageCircle, Download, Airplay } from 'react-feather';

const YoutubeFeed = ({ youtubeFeed }) => {

  return (
    <Fragment>
      {youtubeFeed.length > 0 ? (
        <Row id='bookmarkData'>
          {youtubeFeed.map((video) => (
            <Col key={video.id} xl='3' md='4' className='mb-4'>
              <Card className='p-3 border-0 shadow-sm bg-light'>
                <div className='text-center'>
                  <div className='mb-1' style={{ height: '100%', overflow: 'hidden' }}>
                    <Image
                      attrImage={{
                        src: video.thumbnailUrl,
                        alt: 'Video Thumbnail',
                        className: 'img-fluid mb-3',
                      }}
                    />
                  </div>
                  <span className='text-center text-muted'>
                    {new Date(video.publishedAt).toLocaleDateString()}
                  </span>
                  <div className='text-center'>
                  <span className='text-muted mb-0 font-weight-bold'>
                    {video.title}
                    </span>

                    <P className='text-muted mb-2 mt-2'>
                      <Link to={video.videoId} target='_blank' rel='noopener noreferrer'>
                        {video.videoId}
                      </Link>
                    </P>
                    <div className='text-muted'>
                      <span title='Likes'>
                        <Heart size={16} style={{ marginRight: '5px' }} /> {video.likeCount || 0} |
                      </span>
                      <span title='Views'>
                        <Play size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> {video.viewCount || 0} |
                      </span>
                      <span title='Comments'>
                        <MessageCircle size={16} style={{ marginLeft: '5px', marginRight: '5px' }} />{' '}
                        {video.commentCount || 0} |
                      </span>
                      <span title='Shares'>
                        <Share size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> {video.shareCount || 0} 
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Col xl='12' className='text-center'>
          Not Available Yet
        </Col>
      )}
    </Fragment>
  );
};

export default YoutubeFeed;
