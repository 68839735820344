import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H6, Alerts , P , H4 } from '../../../../AbstractElements';
import Chart from 'react-apexcharts'
import { generateDownloadByDsp } from './DownloadsData';
import AnalyticsService from '../../../../Services/analytics/AnalyticsService';
import GridLoader from '../../../Loaders/GridLoader';

const columns = [
  {
    name: 'Title',
    selector: row => row.title,
  },
  {
    name: 'Downloads',
    selector: row => row.streams,
  },
  {
    name: 'Downloads Change',
    selector: row => row.streams_change,
  },
];


const DownloadByDsp = ({selectedTimeRange}) => {
  const [loading, setLoading] = useState(true);
  const [edata, setEdata] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null); 
    try {
      const response = await AnalyticsService.getDownloadsData(selectedTimeRange);
      // console.log('Downloads data:', response.Downloads_by_dsp);
      setEdata(response.Downloads_by_dsp);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data. Please try again later.'); 
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedTimeRange);
  }, [selectedTimeRange]);

  const downloadData = generateDownloadByDsp(edata);

  return (
    <Container>
  {loading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
    <GridLoader />
  </div>
) : error ? (
  <div className="text-center">
    <Alerts attrAlert={{ color: 'primary ' }} >
      <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
      <P>{error}</P>
      <hr />
      <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
    </Alerts>
  </div>
) : (
      <>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <h6>Downloads Chart</h6>
              </CardTitle>
            </CardHeader>
            <CardBody>
                {downloadData.series.length ? (
                  <Chart options={downloadData.options} series={downloadData.series} type="line" height={350} />
                ) : (
                  <div className="text-center">Not available yet</div>
                )}
              </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <H6>Asset Downloads</H6>
              </CardTitle>
            </CardHeader>
            <CardBody>
                <DataTable columns={columns} data={edata} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      </>
  )}
    </Container>
  );
};

export default DownloadByDsp;
