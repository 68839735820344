// BarcodeService.js
import axios from "../../api/axios";

const BarcodeService = {
  getAllUPCs: async () => {
    try {
      const response = await axios.get("/upcs/getAllUPCs");
      const { upcs } = response.data;
      return { success: true, upcs };
    } catch (error) {
      console.error("Failed to fetch UPCs", error);
      return { success: false, error };
    }
  },
  
  importData: async (file) => {

      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post("/upcs/barcodeImport", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, error: 'Failed to import file', error_code: response.status };
      }

  },

  
  importProducts: async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
  
      const response = await axios.post("/releases/transferProducts", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log("File imported", response);
  
      if (response.status === 200) {
        return { success: true, transfers: response.data.transfers };
      } else if (response.status === 422 && response.data.missing_artists && response.data.missing_artists.length > 0) {
        const errorMessage = `${response.data.error}. ${response.data.missing_artists.join(', ')}`;
        console.error(errorMessage);
        return { success: false, error: errorMessage };
      } else {
        console.error('Error during file import:', response.data);
        return { success: false, error: 'Error importing the file. Please try again.' };
      }
    } catch (error) {
      console.error('Error importing product:', error);
      throw error; // Propagate the error back to the caller
    }
  },
  

  generateSmartLink: async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post("/smart/generateSmartLink", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        return { success: true, smartLink: response.data.smartLink };
      } else {
        return { success: false, error: 'Failed to generate smart link', error_code: response.status };
      }
    } catch (error) {
      console.error('Error generating smart link:', error);
      return { success: false, error: 'Failed to generate smart link', error_code: error.response.status };
    }
  },

  deleteUPC: async (upcId) => {
    try {
      const response = await axios.delete(`/upcs/deleteUPC/${upcId}`);
      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, error: 'Failed to delete UPC', error_code: response.status };
      }
    } catch (error) {
      console.error("Error deleting UPC", error);
      return { success: false, error: 'Failed to delete UPC', error_code: error.response.status };
    }
  },

  getSmartLinks: async () => {
    try {
      const response = await axios.get("/smart/getSmartLink");
      const { smartLinks } = response.data;
      return { success: true, smartLinks };
    } catch (error) {
      console.error("Failed to fetch smart links", error);
      return { success: false, error };
    }
  },


};



export default BarcodeService;
