import React, { Fragment, useCallback, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Btn } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import EmptyDataTable from "../../../Dashboard/Common/EmptyDataTable";
import CustomizerContext from "../../../../_helper/Customizer";

const SmartlinkTable = ({
  smartLinks,
  handleGenerateLink,
  fetchSmartLinks,
}) => {
  const [loading, setLoading] = useState(false);
  const { layoutURL } = useContext(CustomizerContext);
  const history = useNavigate();

  const handleImport = async (event) => {
    const file = event.target.files[0];
    const confirmed = await Swal.fire({
      title: "Confirm Import",
      text: "Are you sure you want to import the file?",
      icon: "question",
      showCancelButton: true,
    });

    if (confirmed.isConfirmed) {
      setLoading(true);
      try {
        await handleGenerateLink(file);
        fetchSmartLinks();
      } catch (error) {
        console.error("Error during file import:", error);
        Swal.fire({
          title: "Error",
          text: "Error importing the file. Please try again.",
          icon: "error",
        });
      }

      setLoading(false);
    }
  };

  const handleClick = (param) => {
    history(`${process.env.PUBLIC_URL}/app/transfer/${param}/${layoutURL}`, {
      state: { generateLink: param },
    });
  };
  const columns = [
    {
      name: "Filename",
      selector: (row) => row.filename,
      sortable: true,
    },
    {
      name: "Public Link",
      cell: (row) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            handleClick(row.generated_link);
          }}
        >
          {row.generated_link}
        </Link>
      ),
    },
    {
      name: "Created At",
      selector: (row) => moment(row.created_at).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
  ];

  const sortedSmartLinks = smartLinks
    .slice()
    .sort(
      (a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    );

  return (
    <Fragment>
      <div className="mb-3 mt-2 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <input
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            onChange={handleImport}
          />
        </div>
      </div>

      {sortedSmartLinks.length > 0 ? (
        <DataTable
          columns={columns}
          data={sortedSmartLinks}
          striped={true}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15]}
          paginationComponentOptions={{
            rowsPerPageText: "Items per page:",
            rangeSeparatorText: "of",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "All",
            paginationCenter: true,
          }}
        />
      ) : (
        <EmptyDataTable />
      )}
    </Fragment>
  );
};

export default SmartlinkTable;
