import React from 'react';
import { Card, CardBody, Container, Row, Col, CardHeader , CardTitle } from 'reactstrap';
import Chart from 'react-apexcharts';
import DataTable from 'react-data-table-component';
import { ShazamSpline } from './chartsData';
import { H6 } from '../../../../AbstractElements';
// Dummy data for the table
const data = [{ id: 1, title: 'Conan the Barbarian', year: '1982' }];
const columns = [
  {
    name: 'Title',
    selector: row => row.title,
    sortable: true,
  },
  {
    name: 'Year',
    selector: row => row.year,
    sortable: true,
    right: true,
  },
];

export default function Shazam() {
  return (
    <Container>
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader>
              <CardTitle>
                <h6>Shazam Chart</h6>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div id='basic-apex'>
                <Chart options={ShazamSpline.options} series={ShazamSpline.series} height="350" type="area" />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <CardHeader>
              <CardTitle>
                <h6>Shazam Table</h6>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <DataTable
                columns={columns}
                data={data}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}