import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import BarcodeTable from "./BarcodeTable";
import { grid } from "ldrs";
import { useBarcodeContext } from "../../../../_helper/Barcode/BarcodeProvider";
import GridLoader from "../../../Loaders/GridLoader";
const BarcodeList = () => {
  grid.register();

  const { upcs, loading, importData, fetchUPCs, deleteUPC } =
    useBarcodeContext();
  const [inUseFilter, setInUseFilter] = useState(null);
  const [loadingImport, setLoadingImport] = useState(false);

  useEffect(() => {
    fetchUPCs();
  }, []);

  const handleFilterUsed = () => {
    setInUseFilter(true);
  };

  const handleFilterNotUsed = () => {
    setInUseFilter(false);
  };

  const clearFilters = () => {
    setInUseFilter(null);
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="IPC Barcode"
        title="Barcode"
        mainTitle="barcodes Data"
        buttons={[
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",
              onClick: () => document.getElementById("file-upload").click(),
            }}
          >
            <i className="fa fa-download me-1"></i>Import
          </Btn>,
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",
              onClick: handleFilterUsed,
              // disabled: true,
            }}
          >
            <i className="fa fa-check-circle me-1"></i>Used
          </Btn>,
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",
              onClick: handleFilterNotUsed,
              // disabled: true, //todo: add disabled state after clicked on filters
            }}
          >
            <i className="fa fa-times-circle me-1"></i>Not Used
          </Btn>,
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",
              onClick: clearFilters,
              // disabled: true, //todo: add disabled state after clicked on filters
            }}
          >
            <i className="fa fa-eraser me-1"></i>Clear Filters
          </Btn>,
        ]}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Manage your Barcodes" />
              <CardBody>
                {loading ? (
                  <GridLoader />
                ) : (
                  <BarcodeTable
                    upcs={upcs}
                    importData={importData}
                    fetchUPCs={fetchUPCs}
                    inUseFilter={inUseFilter}
                    setInUseFilter={setInUseFilter}
                    deleteUPC={deleteUPC}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BarcodeList;
