import React from 'react'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { dummytabledata } from '../../../../../Data/Table/Defaultdata';
import { H6 } from '../../../../../AbstractElements';
import { Card, CardBody, CardHeader } from 'reactstrap';
function VideosByTerritoryTable() {
  const tableColumns = [
    {
      name: 'Country Code',
      selector: row => row['country_code'],
      sortable: true,
      center: true,
    },
    {
      name: 'Views',
      selector: row => row['views'],
      sortable: true,
      center: true,
    },
    {
      name: 'Likes',
      selector: row => row['likes'],
      sortable: true,
      center: true,
    },
    {
      name: 'Estimated Revenue',
      selector: row => row['estimated_revenue'],
      sortable: true,
      center: true,
    },
    {
      name: 'Comments',
      selector: row => row['comments'],
      sortable: true,
      center: true,
    },
    {
      name: 'Month',
      selector: row => row['month'],
      sortable: true,
      center: true,
    },
    {
      name: 'Created At',
      selector: row => moment(row['created_at']).format('DD/MM/YYYY'),
      sortable: true,
      center: true,
    },
    {
      name: 'Updated At',
      selector: row => moment(row['updated_at']).format('DD/MM/YYYY'),
      sortable: true,
      center: true,
    },
  ];
  const tableData = [
    {
      id: 1,
      artist_id: 1,
      country_code: 'US',
      views: 1000,
      likes: 100,
      estimated_revenue: 100.00,
      comments: 10,
      month: 'January',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00',
    },
    // more data...
  ];

  return (
    <>
       <Card>
        <CardHeader>
            <H6>{'Table By Territory'}</H6>
        </CardHeader>
        <CardBody>
            <div className="table-responsive support-table">
                <DataTable
                    columns={tableColumns}
                    data={tableData}
                    striped={true}
                    center={true}
                    pagination
                />
            </div>
        </CardBody>
    </Card>
    </>
  )
}

export default VideosByTerritoryTable