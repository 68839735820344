import React, { Fragment, useState, useEffect , useCallback} from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, H5, H6, Btn } from '../../../../AbstractElements';
import AccountingTable from '../AccountingTable';
import AccountingFilter from '../AccountingFilter';
import ArtistFilter from './Artist/ArtistFilters';
import VideosByTerritoryTable from './Artist/VideosByTerritoryTable';
import VideosByViewsTable from './Artist/VideosByViewsTable';
import Widgets1 from '../../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../../Common/CommonWidgets/Widgets2';
import { Widgets2ChartData2Widgets } from '../../../../Data/DefaultDashboard/Chart';
function YoutubeContainer({ userRole }) {

    //? for artist table filtering
    const [selectedTables, setSelectedTables] = useState(['VideosByViewsTable']);
    const [selectedRows, setSelectedRows] = useState([]);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const handleTableSelection = (tableName, event) => {
        if (event.target.checked) {
            setSelectedTables(prevTables => [...prevTables, tableName]);
        } else {
            setSelectedTables(prevTables => prevTables.filter(table => table !== tableName));
        }
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

      const handleDelete = () => {
        // Async Delete the selected rows
        // ...
      
        // Clear the selected rows
        setSelectedRows([]);
        setClearSelectedRows(true);
      };

      


    const WidgetsData = {
        title: 'Purchase',
        gros: 50,
        total: 10_000,
        color: 'secondary',
        icon: 'expense',
    };
    const Widgets2Data2Widgets = {
        title: 'Profit',
        total: '6,90k',
        chart: Widgets2ChartData2Widgets,
    };

    if (userRole === 'artist') {
        return (
            <>
                <div className="feature-products">
                    <ArtistFilter handleTableSelection={handleTableSelection} selectedTables={selectedTables} />
                </div>
                <Row className="widget-grid">
                    <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                        <Widgets1 data={WidgetsData} />
                    </Col>
                    <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                        <Widgets2 chartClass='profit-chart' data={Widgets2Data2Widgets} />
                    </Col>
                </Row>
                <Row>
                    <Col sm='12'>

                        {selectedTables.includes('VideosByViewsTable') && <VideosByViewsTable />}
                        {selectedTables.includes('VideosByTerritoryTable') && <VideosByTerritoryTable />}

                    </Col>
                </Row>
            </>
        );
    } else {
        return (
            <>
                <div className="feature-products">
                    <AccountingFilter />
                </div>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardHeader>
                                <H5>{'Accounting '}</H5>
                                <span>{'Accounting Management Tool'}</span>

                                {(selectedRows.length !== 0) &&
                                    <div className="text-end">
                                        <Btn
                                            attrBtn={{
                                                size: "l",
                                                color: "primary",
                                                onClick: () => {
                                                    // Add your logic here for generating expenses
                                                },
                                            }}
                                        >
                                            <i className="fa fa-file-text-o me-1"></i>
                                            {'Generate Expenses'}
                                        </Btn>
                                    </div>
                                }
                            </CardHeader>
                            <CardBody>
                            <AccountingTable handleRowSelected={handleRowSelected} selectedRows={selectedRows} clearSelectedRows={clearSelectedRows} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default YoutubeContainer;  