import React, { useState, useEffect  , useCallback} from "react";
import DataTable from "react-data-table-component";
import CountryFlag from "react-country-flag";
import { Row, Col, Button } from "reactstrap";

export default function Countries({
  countries,
  selectedCountries,
  setSelectedCountries,
  selectedCountryRows,
  setSelectedCountryRows,
}) {
  const [filteredCountries, setFilteredCountries] = useState(countries);
  // const [selectedRows, setSelectedRows] = useState([]);
//  console.log("countries", selectedCountries);
// console.log("selected",selectedCountryRows)
 
  useEffect(() => {
    setFilteredCountries(countries);
  }, [countries]);


  // todo: Move it to parent and map the selectedCountryRows state to get the Id's only when updating
  const selectedRowObjects = selectedCountries.map((id) =>
    filteredCountries.find((country) => country.id === id)
  );

  const handleChangeCountries = (state) => {
    setSelectedCountryRows(state.selectedRows);
  };

  const handleSelectAll = () => {
    setSelectedCountries(filteredCountries.map((country) => country.id));
  };

  // const rowSelectCritera = row => row.id > 6;
  const rowSelectCriteria = useCallback(row => 
    selectedCountries && selectedCountries.find(country => country && country.id === row.id) !== undefined, 
  [selectedCountries]);

  const handleFilterChange = (region) => {
    let filteredData;
    if (region === "All") {
      filteredData = countries;
    } else {
      filteredData = countries.filter((country) =>
        region ? country.region === region : true
      );
    }
    setFilteredCountries(filteredData);
  };

  const countryColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Flag",
      cell: (row) => (
        <CountryFlag
          countryCode={row.iso2 || row.iso3}
          style={{ fontSize: "2em" }}
          svg
        />
      ),
      sortable: false,
    },
    {
      name: "Region",
      selector: (row) => row.region,
      sortable: true,
    },
  ];

  return (
    <Row>
      <Col className="country-section mt-3">
        <div className="d-flex justify-content-center mb-3 mt-2">
          <div className="btn-showcase">
            {[
              "Americas",
              "Asia",
              "Europe",
              "Oceania",
              "Polar",
              "Africa",
              "All",
            ].map((region) => (
              <Button
                key={region}
                size="xs"
                color="primary"
                outline
                onClick={() => handleFilterChange(region)}
              >
                {region}
              </Button>
            ))}
          </div>
        </div>
        <DataTable
        
          columns={countryColumns}
          data={filteredCountries}
          pagination
          selectableRows
          paginationPerPage={5}
          selectableRowsHighlight
          onSelectedRowsChange={handleChangeCountries}
          selectedRows={selectedRowObjects}
          selectableRowSelected={rowSelectCriteria}
        />

      </Col>
    </Row>
  );
}
