import React, { Fragment, useContext, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row, Label } from 'reactstrap';
import FilterContext from '../../../../../_helper/Filter';
import { H6 } from '../../../../../AbstractElements';

const ArtistFilter = ({ selectedTables, searchKeyword, setSearchKeyword, filterByPaidStatus, filterByDateRange, dataProvider, handleTableSelection }) => {
    const { filterContext, filterSearchBy } = useContext(FilterContext);
    const [sidebaron, setSidebaron] = useState(true);
    const [paidStatus, setPaidStatus] = useState('');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    const onClickFilter = () => {
        if (sidebaron) {
            setSidebaron(false);
            document.getElementById('product-wrapper').classList.add('sidebaron');
        } else {
            setSidebaron(true);
            document.getElementById('product-wrapper').classList.remove('sidebaron');
        }
    };

    const handleSearchKeyword = (event) => {
        setSearchKeyword(event.target.value);
        filterSearchBy(event.target.value);
    };

    // const handlePaidStatusChange = (event) => {
    //     setPaidStatus(event.target.value);
    //     filterByPaidStatus(event.target.value);
    // };

    const handleDateRangeChange = (event) => {
        setDateRange({ ...dateRange, [event.target.name]: event.target.value });
        filterByDateRange(dateRange);
    };

    return (
        <Fragment>
            <Row>
                <Col xl="3" md="6">
                    <div className={`product-sidebar ${filterContext ? 'open' : ''}`}>
                        <div className="filter-section">
                            <Card>
                                <CardHeader>
                                    <H6 attrH6={{ className: 'mb-0 f-w-600' }}>Filters
                                        <span className="pull-right">
                                            <i className="fa fa-chevron-down toggle-data"
                                                onClick={onClickFilter}>
                                            </i>
                                        </span>
                                    </H6>
                                </CardHeader>
                                <div className="left-filter">
                                    <CardBody className="filter-cards-view animate-chk">
                                        <FormGroup>
                                            <label>Date Range</label>
                                            <Input type="date" name="start" value={dateRange.start} onChange={handleDateRangeChange} />
                                            <Input type="date" name="end" value={dateRange.end} onChange={handleDateRangeChange} />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Tables View</label>
                                            <div className="checkbox-animated mt-0">
                                                <Label className="d-block">
                                                    <Input
                                                        className="checkbox_animated"
                                                        type="checkbox"
                                                        name="table"
                                                        value="VideosByTerritoryTable"
                                                        onChange={(e) => handleTableSelection('VideosByTerritoryTable', e)}
                                                        checked={selectedTables.includes('VideosByTerritoryTable')}
                                                    />
                                                    VideosByTerritoryTable
                                                </Label>
                                                <Label className="d-block">
                                                    <Input
                                                        className="checkbox_animated"
                                                        type="checkbox"
                                                        name="table"
                                                        value="VideosByViewsTable"
                                                        onChange={(e) => handleTableSelection('VideosByViewsTable', e)}
                                                        checked={selectedTables.includes('VideosByViewsTable')}
                                                    />
                                                    VideosByViewsTable
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Col>
                <Col xl="9" md="6">
                    <div className="product-search">
                        <Form>
                            <FormGroup className="m-0 form-group search-product">
                                <Input className="form-control" type="text" placeholder="search"
                                    value={searchKeyword}
                                    onChange={(e) => handleSearchKeyword(e)} />
                                <i className="fa fa-search"></i>
                            </FormGroup>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ArtistFilter;