import React, {useEffect} from "react";
import { Controller } from "react-hook-form";
import { FormGroup, Label, Input, Row, Form } from "reactstrap";
export default function Files({
  control,
  errors,
  handleNextSecondStep,
  i,
  setValue,
  clearErrors,
  selectedAsset
}) {

/*   useEffect(() => {
    setValue(`audio_language_${i}`, selectedAsset?.audio_language  || "");
    setValue(`asset_rights_claim_${i}`, selectedAsset?.asset_rights_claim || "");
    setValue(`volume_${i}`, selectedAsset?.volume || "");
    setValue(`preview_text_${i}`, selectedAsset?.preview_text|| "");
    setValue(`preview_start_${i}`, selectedAsset?.preview_start || "");
    setValue(`lyrics_${i}`, selectedAsset?.lyrics || "");
    setValue(`preview_length_${i}`, selectedAsset?.preview_length || "");
    setValue(`metadata_language_${i}`, selectedAsset?.metadata_language || "");
  }, [selectedAsset, setValue, i]); */
  // console.log("Files -> selectedAsset", selectedAsset);
  return (
    <Form
      className="theme-form mt-3"
      id="step2"
      onSubmit={handleNextSecondStep}
    >
      <Row>
        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Audio Language</Label>
          <Controller
            name={`audio_language_${i}`}
            control={control}
            defaultValue={selectedAsset?.audio_language || ""}
            /* rules={{ required: "Audio Language is required" }} */
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder="Audio Language"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`audio_language_${i}`);
                }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`audio_language_${i}`] &&
              errors[`audio_language_${i}`].message}
          </span> */}
        </FormGroup>
        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Volume</Label>
          <Controller
            name={`volume_${i}`}
            control={control}
            defaultValue={selectedAsset?.volume || ""}
            /* rules={{ required: "Volume is required" }} */
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="number"
                placeholder="Volume"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`volume_${i}`);
                }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`volume_${i}`] && errors[`volume_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Metadata language</Label>
          <Controller
            name={`metadata_language_${i}`}
            control={control}
            defaultValue={selectedAsset?.metadata_language || ""}
            /* rules={{ required: "MetaData language is required" }} */
            render={({ field }) => (
              <select 
              {...field} 
              className="form-control"
              onChange={(e) => {
                field.onChange(e);
                clearErrors(`metadata_language_${i}`);
              }}
              >
                <option value="">Select Language</option>
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="it">Italian</option>
                <option value="zh">Chinese</option>
                <option value="ja">Japanese</option>
                <option value="ko">Korean</option>
                <option value="ar">Arabic</option>
                <option value="ru">Russian</option>
                
              </select>
            )}
          />


          {/* <span style={{ color: "red" }}>
            {errors[`metadata_language_${i}`] && errors[`metadata_language_${i}`].message}
          </span> */}
        </FormGroup>

        {/* <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Asset Rights Claim</Label>
          <Controller
            name={`asset_rights_claim_${i}`}
            control={control}
            defaultValue={selectedAsset?.asset_rights_claim || ""}
            rules={{ required: "Asset Rights Claim is required" }}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                placeholder="Asset Rights Claim"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`audio_language_${i}`);
                }}
              />
            )}
          />
          <span style={{ color: "red" }}>
            {errors[`asset_rights_claim_${i}`] &&
              errors[`asset_rights_claim_${i}`].message}
          </span>
        </FormGroup> */}

       

        {/* <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Explicit Content</Label>
          <Controller
            name={`explicit_content_${i}`}
            control={control}
            defaultValue={selectedAsset?.explicit_content || ""}
             /* rules={{ required: "Explicit Content is required" }} 
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                placeholder="Explicit Content "
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`explicit_content_${i}`);
                }}
              >
                <option value="">Select...</option>
                <option value="Explicit">Explicit</option>
                <option value="No Explicit">No Explicit</option>
              </Input>
            )}
          />
          /*
          <span style={{ color: "red" }}>
            {errors[`explicit_content_${i}`] &&
              errors[`explicit_content_${i}`].message}
          </span> 
          
        </FormGroup> 
        */}

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Preview Text</Label>
          <Controller
            name={`preview_text_${i}`}
            control={control}
            defaultValue={selectedAsset?.preview_text || ""}
            /* rules={{ required: "Preview Text is required" }} */
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="text"
                placeholder="Preview Text"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`preview_text_${i}`);
                }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`previewtext${i}`] && errors[`previewtext${i}`].message}
          </span> */}
        </FormGroup>
      </Row>
      <Row>
       

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Preview Length</Label>
          <Controller
            name={`preview_length_${i}`}
            control={control}
            defaultValue={selectedAsset?.preview_length || ""}
            /* rules={{ required: "Preview Length is required" }} */
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="time"
                placeholder="--__--"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`preview_length_${i}`);
                }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`previewLength_${i}`] &&
              errors[`previewLength_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Preview Start</Label>
          <Controller
            name={`preview_start_${i}`}
            control={control}
            defaultValue={selectedAsset?.preview_start || ""}
            /* rules={{ required: "Preview Start is required" }} */
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="time"
                placeholder="--__--"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`preview_start_${i}`);
                }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`previewStart_${i}`] && errors[`previewStart_${i}`].message}
          </span> */}
        </FormGroup>

        
      </Row>

      <Row>
        <FormGroup className="mt-2">
          <Label className="col-form-label">{"Lyrics"}</Label>
          <Controller
            name={`lyrics_${i}`}
            control={control}
            defaultValue={selectedAsset?.lyrics || ""}
            /* rules={{ required: "Lyrics are required" }} */
            render={({ field }) => (
              <textarea
                {...field}
                className="form-control "
                placeholder="Lyrics ..."
                rows="3"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`lyrics_${i}`);
                }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`lyrics_${i}`] && errors[`lyrics_${i}`].message}
          </span> */}
        </FormGroup>
      </Row>
      <FormGroup className="col-sm-6 mb-3">
        <Label className="col-form-label">Audio file</Label>
        <Controller
          name={`audio_file_${i}`}
          control={control}
          defaultValue=""
          /* rules={{ required: "Audio file is required" }} */
          render={({ field }) => (
            <div className="input-group">
              <input
                type="file"
                accept="audio/*"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e.target.files[0]);
                  const file = e.target.files[0];
                  
                  setValue(`audio_file_${i}`, file);
                  clearErrors(`audio_file_${i}`);
                }}
              />
            </div>
          )}
        />
        {/* {errors[`audio_file_${i}`] && (
          <span style={{ color: "red" }}>
            {errors[`audio_file_${i}`].message}
          </span>
        )} */}
      </FormGroup>
    </Form>
  );
}
