import React, { useState, useEffect } from 'react';
import Context from './index';
//import {AllUserApi} from '../../api';
import UserService from '../../Services/users/UserService';
// import useBankDetailsStore from './bankDetailsStore';
const UserProvider = (props) => {
    const [users, setUsers] = useState([]);
    const [allData, setAllData] = useState([]);
    const [userBank, setUserBank] = useState({});
    // const { bankDetails, setBankDetails } = useBankDetailsStore();
    const [paginationData, setPaginationData] = useState(null);
    //todo: to use to store error message
    const [error , setError] = useState(null);
    //const token = process.env.TOKEN;

    useEffect(() => {
      //fetchUserBankDetails();
    }, []);

    // const fetchUserBankDetails = async () => {
    //   const bankDetails = await getUserBankDetails();
    //   //setBankDetails(bankDetails);
    // };


    const getAllUsersData = async (page = 1, role = '') => {
      try {
        const response = await UserService.getAllUsers(page, role);
        if (response.success) {
          setAllData(response.data);
          setPaginationData(response.meta);
          setError(null); // Reset the error state if the request was successful
        } else {
          console.error('Failed to fetch users', response.error);
          setError(response.error); // Set the error state to the error from the response
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setError( error.message || 'An error occurred while fetching users'); // Set the error state to the error message
      }
    };
 

      const addNewUser = async (userData, imageFile) => {
        try {
          console.log('userData:', userData);
          const response = await UserService.register(userData, imageFile); // Call UserService.register
          if (response.success) {
            const userTemp = {
              username: userData.username,
              fullname:userData.fullname,
              name: userData.name,
              email: userData.email,
              password: userData.password,
              role: userData.role,
              permissions:userData.permissions,
              image:userData.image,
              progress: 50
            };
            setAllData(prevData => [...prevData, userTemp]);
            return { success: true, data: userTemp };
          } else {
            throw new Error(response.error.message || 'Failed to add new user');
          }
        } catch (error) {
          console.error('Failed to add new user', error);
          throw error; // re-throw the error so it can be caught in AddUser
        }
      };



      const updatePersonalDetails = async (userDetails) => {
        const response = await UserService.updatePersonalDetails(userDetails);
        if (response.success) {
          setAllData(prevData => prevData.map(user => user.username === userDetails.username ? { ...user, ...userDetails } : user));
        } else {
          console.error('Failed to update personal details', response.error);
        }
      };

      const uploadImage = async (imageFile) => {
        const response = await UserService.uploadImage(imageFile);
        if (response && response.success) {
          setAllData(prevData => prevData.map(user => user.image === imageFile ? { ...user, image: response.url } : user));
          return response;
        } else {
          console.error('Failed to update personal details', response ? response.error : 'No response from UserService.uploadImage');
          return { success: false, error: response ? response.error : 'No response from UserService.uploadImage' };
        }
      };


      const updateBankDetails = async (bankDetails) => {
        try {
          const response = await UserService.updateBankDetails(bankDetails);
          setUserBank(response);
          // fetchUserBankDetails();
          return { success: true, response };
        } catch (error) {
          console.error('An error occurred while updating bank details', error);
          return { success: false, error: 'An error occurred while updating bank details' };
        }
      };
      

      const getUserBankDetails = async () => {
        /* if (!localStorage.getItem('authToken')) {
          return;
        } */
        const response = await UserService.getBankDetails();
        if (response.success) {
          setUserBank(response.data);
          // setBankDetails(response.data);
          return response.data;  
        } else {
          console.error('Failed to fetch bank details', response.error);
          return null;  
        }
      };


      const updatePersonalDetailsByadmin = async (userDetails) => {
        const response = await UserService.updateUserPersonalDetails(userDetails);
        if (response.success) {
          setAllData((prevData) =>
            prevData.map((user) => (user.username === userDetails.username ? { ...user, ...userDetails } : user))
          );
        } else {
          console.error('Failed to update personal details', response.error);
        }
      };
    

    return (
        <Context.Provider
            value={{
                ...props,
                addNewUser: addNewUser,
                user: users,
                allData,
                getAllUsersData,
                paginationData,
                updatePersonalDetails,
                updateBankDetails,
                updatePersonalDetailsByadmin,
                //getUserBankDetails,
                uploadImage,
                error,
                userBank,
                // bankDetails
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default UserProvider;