import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col, CardHeader , CardTitle } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H4, Alerts, P } from '../../../../AbstractElements';
import { SocialRadialBarChart } from './chartsData';
import { DspRadialBarChart } from './chartsData';
import Chart from 'react-apexcharts';
import DataLoop from './DataLoop';
import TiktokFeed from './TiktokFeed';
import YoutubeFeed from './YoutubeFeed';
import AnalyticsService from '../../../../Services/analytics/AnalyticsService';
import GridLoader from '../../../Loaders/GridLoader';



const columns = [
  {
    name: 'Social Media Platform',
    selector: row => row.title,
  },
  {
    name: 'Followers',
    selector: row => row.followers,
  },
  {
    name: 'Percentage',
    selector: row => row.percentage,
    cell: row => (
      <div>
        {row.percentage}
        {parseFloat(row.percentage) > 0 ? (
          <span style={{ color: 'green', marginLeft: '5px' }}>&uarr;</span>
        ) : (
          <span style={{ color: 'red', marginLeft: '5px' }}>&darr;</span>
        )}
      </div>
    ),
  },
];


export default function Social({selectedTimeRange}) {
  const [fanbaseData, setFanbaseData] = useState(null);
  const [fanbaseSocial, setfanbaseSocial] = useState([]);
  const [dspSocial, setdspSocial] = useState([]);
  const [fanbaseDataTable, setFanbaseDataTable] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  
  const fetchFanbaseData = async () => {
    setIsLoading(true);
    try {
      const response = await AnalyticsService.getFanbaseData(selectedTimeRange);
      console.log("response", response)
      if (response && response.fanbase) {
        setfanbaseSocial([
          response.fanbase.tiktok_followercount.folowers,
          response.fanbase.facebook_fan_count.folowers,
          response.fanbase.youtube_subscriberscount.folowers,
          response.fanbase.instagram_follower_count.folowers, 
        ]);
        setdspSocial([
          response.fanbase.soundcloud_followers_count.folowers,
          response.fanbase.anghami_followers.folowers,
          response.fanbase.spotify_followers.folowers,
          response.fanbase.deezerar_fans.folowers,
        ]);
        setFanbaseDataTable([
          {
            id: 'soundcloud',
            title: 'Soundcloud',
            followers: response.fanbase.soundcloud_followers_count.folowers,
            percentage: response.fanbase.soundcloud_followers_count.percentage,
          },
          {
            id: 'anghami',
            title: 'Anghami',
            followers: response.fanbase.anghami_followers.folowers,
            percentage: response.fanbase.anghami_followers.percentage,
          },
          {
            id: 'spotify',
            title: 'Spotify',
            followers: response.fanbase.spotify_followers.folowers,
            percentage: response.fanbase.spotify_followers.percentage,
          },
          {
            id: 'deezer',
            title: 'Deezer',
            followers: response.fanbase.deezerar_fans.folowers,
            percentage: response.fanbase.deezerar_fans.percentage,
          },
          {
            id: 'tiktok',
            title: 'TikTok',
            followers: response.fanbase.tiktok_followercount.folowers,
            percentage: response.fanbase.tiktok_followercount.percentage,
          },
          {
            id: 'facebook',
            title: 'Facebook',
            followers: response.fanbase.facebook_fan_count.folowers,
            percentage: response.fanbase.facebook_fan_count.percentage,
          },
          {
            id: 'youtube',
            title: 'YouTube',
            followers: response.fanbase.youtube_subscriberscount.folowers,
            percentage: response.fanbase.youtube_subscriberscount.percentage,
          },
          {
            id: 'instagram',
            title: 'Instagram',
            followers: response.fanbase.instagram_follower_count.folowers,
            percentage: response.fanbase.instagram_follower_count.percentage,
          },
        ]);
        
        setFanbaseData(response);
        setIsLoading(false);
        setError(null);
      } else {
        setError('Incomplete data received from the server.');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching fanbase data:', error);
      setError('Error fetching data. Please try again later.');
      setIsLoading(false);
    }
  };

useEffect(() => {
  fetchFanbaseData(selectedTimeRange); 
}, [selectedTimeRange]);

  // if (isLoading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
  //       <GridLoader />
  //     </div>
  //   )
  // }
  // if (error) {
  //   return (
  //     <div className="text-center">
  //       <Alerts attrAlert={{ color: 'primary ' }} >
  //         <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
  //         <P>An error occurred while fetching data. Please try again later or contact support for assistance.</P>
  //         <hr />
  //         <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
  //       </Alerts>
  //     </div>
  //   )
  // }
  

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <GridLoader />
        </div>
      ) : error ? (
        <div className="text-center">
          <Alerts attrAlert={{ color: 'primary ' }} >
            <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
            <P>An error occurred while fetching data. Please try again later or contact support for assistance.</P>
            <hr />
            <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
          </Alerts>
        </div>
      ) : (!fanbaseSocial.length && !dspSocial.length) ? (
        <div className="text-center">
          <Alerts attrAlert={{ color: 'warning ' }} >
            <H4 attrH4={{ className: 'alert-heading' }} >No Data</H4>
            <P>No data available. Please try again later or contact support for assistance.</P>
            <hr />
            <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
          </Alerts>
        </div>
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h6>Dsp Chart</h6>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div id='circlechart'>
                    <Chart options={DspRadialBarChart.options} series={dspSocial} type="radialBar" height={320} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h6>Social Chart</h6>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div id='circlechart'>
                    <Chart options={SocialRadialBarChart.options} series={fanbaseSocial} type="radialBar" height={320} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h6>Social Table</h6>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {fanbaseDataTable ? (
                    <DataTable
                      columns={columns}
                      data={fanbaseDataTable}
                    />
                  ) : (
                    <P>No records To display </P>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardHeader>
              <CardTitle>
                <H4>Instagram</H4>
              </CardTitle>
            </CardHeader>
            <CardBody >
              <DataLoop instagramData={fanbaseData.instagramfeedvideos}></DataLoop>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>
                <H4>Tik Tok</H4>
              </CardTitle>
            </CardHeader>
            <CardBody >
              <TiktokFeed tiktokFeedVideos={fanbaseData.tiktokfeedvideos}/>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>
                <H4>Youtube</H4>
              </CardTitle>
            </CardHeader>
            <CardBody >
              <YoutubeFeed youtubeFeed={fanbaseData.youtubefeedvideos}/>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
}