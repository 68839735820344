import React, { Fragment, useCallback, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 ,} from '../../../AbstractElements';
import { dummytabledata, tableColumns } from '../../../Data/Table/Defaultdata';
import { Input } from "reactstrap";
import EmptyDataTable from '../../Dashboard/Common/EmptyDataTable';
const LabelsTable = ({ labels }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(labels);
    // console.log("LabelsData:",labels);
    // console.log("Data:",data);
    const [filterText, setFilterText] = useState('');

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handleDelete = () => {
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
            setToggleDelet(!toggleDelet);

            setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
            setSelectedRows('');
        }
    };
    const filteredData = data.filter(item => item.name && item.name.includes(filterText));
    const Columns = [
        {
          name: 'Label Name',
          selector: row => row['label_name'],
          sortable: true,
          center: true,
        },
        {
          name: 'Label Theme',
          selector: row => row['label_theme'],
          sortable: true,
          center: true,
        },
        {
            name: 'Color 1',
            selector: row => row['color1'],
            sortable: true,
            center: true,
            cell: row => <Input className="p-0" type="color" name="Color-Background1" value={row['color1']} disabled style={{ width: '50px', height: '20px' }}/>
          },
          {
            name: 'Color 2',
            selector: row => row['color2'],
            sortable: true,
            center: true,
            cell: row => <Input className="p-0" type="color" name="Color-Background2" value={row['color2']} disabled style={{ width: '50px', height: '20px' }} />
          },
          {
            name: 'Created At',
            selector: row => {
              const date = new Date(row['created_at']);
              return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} at ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
            },
            sortable: true,
            center: true,
          },
          {
            name: 'Last Updated',
            selector: row => {
              const date = new Date(row['updated_at']);
              return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} at ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
            },
            sortable: true,
            center: true,
          },
      ];
      return (
        <Fragment>
          {(selectedRows.length !== 0) &&
            <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
              {/*<H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>*/}
              <Btn attrBtn={{  className:'m-3', color: 'danger', onClick: () => handleDelete() }}>Delete</Btn>
            </div>
          }
      
          {labels.length > 0 ? (
            <DataTable
              data={labels}
              columns={Columns}
              striped={true}
              center={true}
              pagination
              // selectableRows
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleDelet}
              highlightOnHover
            />
          ) : (
            <EmptyDataTable />
          )}
        </Fragment>
      );
}
export default LabelsTable