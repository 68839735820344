import React, { Fragment, useContext, useState } from 'react';
import { Breadcrumbs , Btn} from '../../../../AbstractElements';
//import { Add, Cancel, Loading } from '../../../../Constant';
import { useNavigate } from 'react-router-dom';
import { useForm  } from 'react-hook-form';
import { Container } from 'reactstrap';
import CustomizerContext from '../../../../_helper/Customizer';
import FormLayout from './FormLayout';
import UserContext from '../../../../_helper/User';
import { useAuth } from '../../../../_helper/Auth/AuthProvider';
import { toast } from "react-toastify";

const NewUser = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const { addNewUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const { userData } = useAuth();
  const isAdmin = userData?.role === 'admin';


  const AddUser = async (data) => {
    if (data !== '') {
      setLoading(true);
  
      const fileInput = document.querySelector('input[type="file"]');
      const selectedFile = fileInput ? fileInput.files[0] : null;
  
      try {
        const response = await addNewUser(data, selectedFile);
        //console.log(response);
        if (response.success) {
          toast.success('User created successfully!');
          history(`${process.env.PUBLIC_URL}/app/users/users-list/${layoutURL}`);
        } else {
          toast.error(response.error.message || 'User registration failed.');
        }
      } catch (error) {
        console.error('Error adding new user:', error);
        toast.error('User registration failed.');
      } finally {
        setLoading(false);
      }
    } else {
      errors.showMessages();
    }
  };
  
  
  

  return (
    <Fragment>
      <Breadcrumbs
        parent="Users"
        title="Manage"
        mainTitle="Users"
        buttons={[
          <Btn
            attrBtn={{
              size: "l",
              color: "primary",
              onClick: () => {
                history(`${process.env.PUBLIC_URL}/app/users/users-list/${layoutURL}`);
              },
            }}
          >
            <i className="fa fa-arrow-left me-1"></i>
            {"Back To Manage"}
          </Btn>,
        ]}
      />
      <Container fluid={true}>
    <FormLayout register={register} isAdmin={isAdmin} loading={loading} errors={errors} handleSubmit={handleSubmit(AddUser)} watch={watch} control={control}  />
    </Container>
    </Fragment>
  );
};

export default NewUser;
