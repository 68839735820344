import React, { Fragment } from 'react';
import { H3, H4} from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';

const EmptyProvider = () => {
  return (
    <Fragment>
      <section className='cart-section section-b-space'>
        <Container fluid={true}>
          <Row>
            <Col sm='12'>
              <div className='empty-cart-cls text-center'>
                <H3>
                  <strong className='f-light'> Empty Provider </strong>
                </H3>
                <H4 attrH4={{ className: 'f-light' }}> Please Choose a Provider </H4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};
export default EmptyProvider;