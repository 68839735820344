import axiosAccounting from "../../api/accounting";

const ExpenseService = {
    getInhouseExpenses: async () => {
        try {
          const response = await axiosAccounting.get("/expenses/inhouse");
          if (!response.data.success) {
            throw new Error('Failed to fetch inhouse expenses');
          }
          return { success: true, data: response.data.data };
        } catch (error) {
          console.error("Failed to fetch inhouse expenses", error);
          return { success: false, error };
        }
      },
  getInhouseCategories: async () => {
    try {
      const response = await axiosAccounting.get("/categories/inhouse");
      if (!response.data.success) {
        throw new Error('Failed to fetch inhouse categories');
      }
      return { success: true, data: response.data.data };
    } catch (error) {
      console.error("Failed to fetch inhouse categories", error);
      return { success: false, error };
    }
  },

  getContributorCategories: async () => {
    try {
      const response = await axiosAccounting.get("/categories/contributors");
      if (!response.data.success) {
        throw new Error('Failed to fetch contributor categories');
      }
      return { success: true, data: response.data.data };
    } catch (error) {
      console.error("Failed to fetch contributor categories", error);
      return { success: false, error };
    }
  },

  getContributorsExpenses: async () => {
    try {
      const response = await axiosAccounting.get("/expenses/contributors");
      if (!response.data.success) {
        throw new Error('Failed to fetch inhouse expenses');
      }
      return { success: true, data: response.data.data };
    } catch (error) {
      console.error("Failed to fetch inhouse expenses", error);
      return { success: false, error };
    }
  },

  getExpense: async (user) => {
    try {
      const labelId = user.label.label_id;
      const response = await axiosAccounting.get(`/expenses/${labelId}`);
      if (!response.data.success) {
        throw new Error('Failed to fetch expenses');
      }
      return response.data.expenses;
    } catch (error) {
      console.error("Failed to fetch expenses", error);
      return [];
    }
  },

  createExpense: async (formData, labelId) => {
    try {
      formData.append('label_id', labelId); // Add labelId to formData

      const response = await axiosAccounting.post("/expenses", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data.success) {
        const firstError = Object.values(response.data.errors)[0][0];
        throw new Error(firstError);
      }

      return { success: true, data: response.data };
    } catch (error) {
      const errorMessage = error.response?.data?.errors
          ? Object.values(error.response.data.errors)[0][0]
          : error.message;
      console.error("Failed to create expense", errorMessage);
      return { success: false, error: errorMessage };
    }
  },

  updateExpense: async (id, formData) => {
    try {
      const response = await axiosAccounting.post(`/expenses/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data.success) {
        throw new Error('Failed to update expense');
      }

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to update expense", error);
      return { success: false, error };
    }
  },
  downloadExpenseFile: async (id) => {
    try {
      const response = await axiosAccounting.get(`/expenses/${id}/download`, {
        responseType: 'blob',
      });

      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, '') : 'downloaded_file';
      const mimeType = response.headers['content-type'];

      if (!response.status === 200) {
        throw new Error('Failed to download expense');
      }

      return { data: response.data, fileName, mimeType };
    } catch (error) {
      console.error("Failed to download expense", error);
      throw error;
    }
  },

  getArtistsByLabel: async (user) => {
    try {
      const labelId = user.label.label_id;
      const response = await axiosAccounting.get(`/labels/${labelId}/artists`);
      if (!response.data.artists) {
        throw new Error('Failed to fetch artists');
      }
      return response.data.artists;
    } catch (error) {
      console.error("Failed to fetch artists", error);
      return [];
    }
  },

  deleteExpense: async (expenseId) => {
    try {
      const response = await axiosAccounting.delete(`/expenses/${expenseId}`); 
      if (!response.data.success) {
        throw new Error('Failed to delete expense');
      }
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to delete expense", error);
      return { success: false, error };
    }
  },
};

export default ExpenseService;