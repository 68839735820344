import React, { Fragment, useContext, useState } from 'react';
import { Breadcrumbs } from '../../../../AbstractElements';
//import { Add, Cancel, Loading } from '../../../../Constant';
import { useNavigate } from 'react-router-dom';
import { useForm ,Controller } from 'react-hook-form';
import { Container } from 'reactstrap';
import CustomizerContext from '../../../../_helper/Customizer';
import FormLayout from './FormLayout';
import UserContext from '../../../../_helper/User';
import { toast } from "react-toastify";

const NewStaff = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const { addNewUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      role: 'staff',
    },
  });


  const AddStaff = (data) => {
    if (data !== '') {
      console.log("data",data); 
      setLoading(true); 

      addNewUser(data)
        .then(() => {
          toast.success('Staff created successfully!');
          history(`${process.env.PUBLIC_URL}/app/artist/artist-list/${layoutURL}`);
        })
        .catch(() => {
          toast.error('Staff registration failed.');
        })
        .finally(() => {
          setLoading(false); 
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent='Team' title='Manage Staff' mainTitle='Add Staff' />
      <Container fluid={true}>
    <FormLayout register={register} loading={loading} errors={errors} handleSubmit={handleSubmit(AddStaff)} watch={watch} control={control}  />
    </Container>
    </Fragment>
  );
};

export default NewStaff;
