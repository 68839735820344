import React, { useState, useEffect } from 'react';
import { H6, Alerts, H4, P } from '../../../../AbstractElements';
import CountUp from 'react-countup';
import { Card, CardBody, Container, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import DataTable from 'react-data-table-component';
import AnalyticsService from '../../../../Services/analytics/AnalyticsService';
import { Users, Video, ThumbsUp } from "react-feather";
import GridLoader from '../../../Loaders/GridLoader';
const colorWidget = [
  { color: "primary" },
  { color: "secondary" },
  { color: "success" },
  { color: "warning" },
  { color: "secondary" }, 
];

export default function SoundCloud( { selectedTimeRange }) {
  const [totalsData, setTotalsData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  
  const assetColumns = [
    { name: 'Title', selector: row => row.source },
    { name: 'Plays', selector: row => row.play_count },
    { name: 'Likes', selector: row => row.like_count },
  ];




  //? removed the if (data && data.totals_tiktok && data.territory_tiktok && data.asset_performance_tiktok) {
const fetchSoundCloudData = async () => {
  setIsLoading(true);
  try {
    const data = await AnalyticsService.getSoundcloudData(selectedTimeRange);
    // console.log("data", data)
    setTotalsData(data.totals_soundcloud || []);
    setTerritoryData(data.territory_soundcloud || []);
    setAssetData(data.source_for_Soundcloud || []);
    setIsLoading(false);
    setError(null);
  } catch (error) {
    console.error('Error fetching SoundCloud data:', error);
    setError('Error fetching data. Please try again later.');
    setIsLoading(false);
  }
};

useEffect(() => {
  fetchSoundCloudData(selectedTimeRange);
}, [selectedTimeRange]);

  // console.log("totalsData", totalsData)
  // if (!totalsData) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
  //       <GridLoader />
  //     </div>
  //   )
  // }

  // if (error) {
  //   return (
  //     <div className="text-center">
  //       <Alerts attrAlert={{ color: 'primary ' }} >
  //         <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
  //         <P>An error occurred while fetching data. Please try again later or contact support for assistance.</P>
  //         <hr />
  //         <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
  //       </Alerts>
  //     </div>
  //   )
  // }
  

  const formatValue = (value) => {
    value = Number(value); // Ensure the value is a number
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      return value.toString();
    }
  };

  const renderTotalsWidgets = ({ totalsData }) => {
    return (
      <Row>
        {totalsData.map((data, index) => {
          const colorClass = colorWidget[index % colorWidget.length].color;
          const formattedValue = formatValue(data.value);
          const numericValue = Number(data.value);
          return (
            <Col key={index}>
              <Card className={`small-widget ${colorClass}`}>
                <CardBody>
                  <span className="f-light">{data.source}</span>
                  <div className="d-flex align-items-end gap-2">
                    <H4>
                      <CountUp
                        prefix=""
                        duration={0}
                        separator=","
                        end={numericValue}
                        formattingFn={() => formattedValue}
                      />
                    </H4>
                    <span className={`font-${data.percentage < 0 ? 'danger' : 'success'} f-12 f-w-500`}>
                      <i className={`icon-arrow-${data.percentage < 0 ? 'down' : 'up'}`} />
                      <span>{data.percentage < 0 ? '-' : '+'}{Math.abs(data.percentage)}%</span>
                    </span>
                  </div>
                  <div className="bg-gradient">
                    {data.source === 'Total Followers' && <Users className="stroke-icon svg-fill" />}
                    {data.source === 'Total Play Count' && <Video className="stroke-icon svg-fill" />}
                    {data.source === 'Total Like Count' && <ThumbsUp className="stroke-icon svg-fill" />}
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    isLoading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <GridLoader />
      </div>
    ) : error ? (
      <div className="text-center">
        <Alerts attrAlert={{ color: 'primary ' }} >
          <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
          <P>An error occurred while fetching data. Please try again later or contact support for assistance.</P>
          <hr />
          <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
        </Alerts>
      </div>
    ) : (!totalsData.length && !territoryData.length && !assetData.length) ? (
      <div className="text-center">
        <Alerts attrAlert={{ color: 'warning ' }} >
          <H4 attrH4={{ className: 'alert-heading' }} >No Data</H4>
          <P>No data available. Please try again later or contact support for assistance.</P>
          <hr />
          <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
        </Alerts>
      </div>
    ) : (
      <Container>
        <Row>
          <Col>{renderTotalsWidgets({ totalsData })}</Col>
        </Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <H6>SoundCloud Asset Table</H6>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <DataTable columns={assetColumns} data={assetData} pagination={true} paginationPerPage={10} />
            </CardBody>
          </Card>
        </Col>
      </Container>
    )
  );
}
