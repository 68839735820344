import React, { Fragment, useContext, useState } from "react";
import { Input, Label } from "reactstrap";
import { H6 } from "../../../../AbstractElements";

import FilterContext from "../../../../_helper/Filter";
const FiltersCard = () => {
  // const { productItem } = useContext(ProductContext);
  const {
    filter,
    filterColor,
    filterCategory,
    filterBrand,
    filterPrice,
    explicit,
    status,
    regions,
    clearFilter,
    filterStatus,
    filterExplicit,
    
  } = useContext(FilterContext);
  const [values, setValues] = useState([150, 650]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedExplicit, setSelectedExplicit] = useState([]);

  const colorHandle = (event, color) => {
    var elems = document.querySelectorAll(".color-selector ul li");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    filterColor(color);
  };
  // const brands = getBrands(productItem);
  // const gender = getGender(productItem);
  // console.log("selectedStatus: ", selectedStatus);
  const clickBrandHendle = (event, brands) => {
    var index = brands.indexOf(event.target.value);
    if (event.target.checked === true) brands.push(event.target.value);
    else brands.splice(index, 1);
    filterBrand(brands);
  };

  const ClickCategory = (result, event) => {
    var elems = document.querySelectorAll("div.checkbox-animated");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    filterCategory(result);
  };
  const handleClearFilter = () => {
    clearFilter();
    setSelectedGenres([]);
    setSelectedStatus([]);
    setSelectedExplicit([]);
  };

/*   const ClickGenre = (genre, event) => {
    if (event.target.checked) {
      setSelectedGenres((prev) => {
        const updatedGenres = [...prev, genre];
        filterGenre(updatedGenres);
        return updatedGenres;
      });
    } else {
      setSelectedGenres((prev) => {
        const updatedGenres = prev.filter((g) => g !== genre);
        filterGenre(updatedGenres);
        return updatedGenres;
      });
    }
  }; */

  const ClickStatus = (status, event) => {
    if (event.target.checked) {
      setSelectedStatus((prev) => {
        const updatedStatus = [...prev, status];
        filterStatus(updatedStatus);
        return updatedStatus;
      });
    } else {
      setSelectedStatus((prev) => {
        const updatedStatus = prev.filter((s) => s !== status);
        filterStatus(updatedStatus);
        return updatedStatus;
      });
    }
  };
  const ClickExplicit = (explicit, event) => {
    if (event.target.checked) {
      setSelectedExplicit((prev) => {
        const updatedExplicit = [...prev, explicit];
        filterExplicit(updatedExplicit);
        return updatedExplicit;
      });
    } else {
      setSelectedExplicit((prev) => {
        const updatedExplicit = prev.filter((e) => e !== explicit);
        filterExplicit(updatedExplicit);
        return updatedExplicit;
      });
    }
  };

  const priceHandle = (value) => {
    setValues(value);
    filterPrice(value);
  };
  return (
    <Fragment>
      <div className="product-filter">
        <span className="pull-right">
          {/* <Badges attrBadge={{ color: 'danger', href: '#javascript' }}><X /></Badges> */}
          <i
            className="fa fa-times toggle-data"
            onClick={handleClearFilter}
          ></i>
        </span>

{/*         <H6 className="f-w-600">Genre</H6>
        <div className="checkbox-animated mt-0">
          {genre &&
            genre.map((item, i) => {
              return (
                <Label className="d-block" key={i}>
                  <Input
                    className="checkbox_animated"
                    type="checkbox"
                    name="name"
                    value={item}
                    onChange={(e) => ClickGenre(item, e)} // Make sure to pass the event object here
                    checked={selectedGenres.includes(item)}
                  />
                  {item}
                </Label>
              );
            })}
        </div> */}

        <H6 className="f-w-600">Status</H6>
        <div className="checkbox-animated mt-0">
          {status.map((status, i) => {
            return (
              <Label className="d-block" key={i}>
                <Input
                  className="checkbox_animated"
                  type="checkbox"
                  name="status"
                  value={status}
                  onChange={(e) => ClickStatus(status, e)}
                  checked={selectedStatus.includes(status)}
                />
                {status}
              </Label>
            );
          })}
        </div>
        <H6 className="f-w-600">Explicit</H6>
        <div className="checkbox-animated mt-0">
          {explicit.map((explicit, i) => {
            return (
              <Label className="d-block" key={i}>
                <Input
                  className="checkbox_animated"
                  type="checkbox"
                  name="explicit"
                  value={explicit}
                  onChange={(e) => ClickExplicit(explicit, e)}
                />
                {explicit}
              </Label>
            );
          })}
        </div>

        {/* defaultChecked={filter.brand.includes(region) ? true : false} */}
      </div>
      {/* <div className="product-filter">
        <H6 attrH6={{ className: "f-w-600" }}>Regions</H6>
        <div className="checkbox-animated mt-0">
          {regions.map((region, index) => {
            return (
              <Label className="d-block" key={index}>
                <Input
                  className="checkbox_animated"
                  // onClick={(e) => clickBrandHendle(e, filter.region)}
                  value={region}
                  id={region}
                  type="checkbox"
                  data-original-title=""
                  title=""
                />
                {region}
              </Label>
            );
          })}
        </div>
      </div> */}

      {/* <div className="product-filter slider-product">
                <H6 attrH6={{ className: 'f-w-600' }}>Colors</H6>
                <div className="color-selector">
                    <UL attrUL={{ className: 'simple-list' }}>
                        {colors.map((color, i) => {
                            return (
                                <Fragment key={i}><li className={color} title={color} onClick={(e) => colorHandle(e, color)}></li>
                                </Fragment>
                            );
                        })}
                    </UL>
                </div >
            </div > */}
      {/* <div className="product-filter pb-0">
                <H6 attrH6={{ className: 'f-w-600' }}>{Price}</H6>
                <Range
                    values={values}
                    step={step}
                    min={min}
                    max={max}
                    onChange={values => {
                        priceHandle(values);
                    }}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: "36px",
                                display: "flex",
                                width: "100%"
                            }}
                        >
                            <output style={{ marginTop: "30px" }}>
                                {values[0]}
                            </output>
                            <div
                                ref={props.ref}
                                style={{
                                    height: "5px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                        values,
                                        colors: ["#ccc", "#7366ff", "#ccc"],
                                        min: min,
                                        max: max
                                    }),
                                    alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                            <output style={{ marginTop: "30px" }}>
                                {values[1]}
                            </output>
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "16px",
                                width: "16px",
                                borderRadius: "60px",
                                backgroundColor: "#7366ff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 2px 6px #AAA"
                            }}
                        >
                        </div>

                    )}
                />
            </div> */}
    </Fragment>
  );
};

export default FiltersCard;
