import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs, P } from "../../../AbstractElements";
import { Data, SampleCard } from "../../../Constant";
import HeaderCard from "../../Common/Component/HeaderCard";
import Select from "react-select";
import Spotify from "./Spotify";
import SoundCloud from "./Soundcloud";
import Tiktok from "./Tiktok";
import Shazam from "./Shazam";
import Anghami from "./Anghami";
import Deezer from "./Deezer";
import AppleMusic from "./AppleMusic";
import makeAnimated from "react-select/animated";
import Social from "./Social";
import StreamByAsset from "./Stream/Asset";
import StreamByDsp from "./Stream/Dsp";
import StreamByTerritory from "./Stream/Territory";
import DownloadByAsset from "./Downloads/Asset";
import DownloadByTerritory from "./Downloads/Territory";
import DownloadByDsp from "./Downloads/Dsp";
import Amazon from "./Amazon";

const Analytics = () => {
  const animatedComponents = makeAnimated();
  const [dataSource, setDataSource] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState(null);

  const dataSources = [
    { value: "SoundCloud", label: "SoundCloud" },
    { value: "Spotify", label: "Spotify" },
    { value: "Amazon", label: "Amazon" },
    { value: "Tiktok", label: "Tiktok" },
    { value: "Shazam", label: "Shazam" },
    { value: "Anghami", label: "Anghami" },
    { value: "Deezer", label: "Deezer" },
    { value: "Social", label: "Social" },
    { value: "AppleMusic", label: "Apple Music" },
  ];

  const options2 = [
    { value: "StreamByTerritory", label: "Stream By Territory" },
    { value: "StreamByAsset", label: "Stream By Asset" },
    { value: "StreamByDsp", label: "Stream By Dsp" },
    { value: "DownloadsByTerritory", label: "Downloads By Territory" },
    { value: "DownloadsByAsset", label: "Downloads By Asset" },
    { value: "DownloadsByDsp", label: "Downloads By Dsp" },
  ];

  const defaultOption2 = options2.find(
    (option) => option.value === "DownloadsByAsset"
  );
  const [selectedSelect, setSelectedSelect] = useState(
    defaultOption2 ? "option2" : null
  );
  const [selectedOption2, setSelectedOption2] = useState(defaultOption2);

  const handleDataSource = (selectedOption) => {
    setDataSource(selectedOption ? selectedOption.value : null);
    setSelectedSelect(selectedOption ? "dataSource" : null);
  };

  const handleSelectChange2 = (selectedOption) => {
    setSelectedOption2(selectedOption ? selectedOption.value : null);
    setSelectedSelect(selectedOption ? "option2" : null);
  };

  useEffect(() => {
    setSelectedOption2(defaultOption2.value);
    console.log(selectedOption2);
  }, []);

  const handleTimeRangeChange = (selectedOption) => {
    setSelectedTimeRange(selectedOption ? selectedOption.value : null);
  };

  const timeRangeOptions = [
    { value: "last3weeks", label: "3 Weeks" },
    { value: "lastmonth", label: "1 Month" },
    { value: "last3months", label: "3 Months" },
    { value: "1_year", label: "1 Year" },
  ];

  

  return (
    <Fragment>
      <Breadcrumbs
        parent="Analytics"
        title="Analytics"
        mainTitle="Analytics"
        buttons={[
          <Select
            onChange={handleTimeRangeChange}
            options={timeRangeOptions}
            placeholder="Filter by ..."
            isClearable
            components={animatedComponents}
            closeMenuOnSelect={true}
            className="mt-2"
            styles={{
              control: (styles, { isFocused, menuIsOpen }) => ({
                ...styles,
                borderColor: isFocused ? "#F9AFAF" : "#ced4da",
                "&:hover": {
                  borderColor: "#F9AFAF",
                },
                ...(menuIsOpen && { borderColor: "#F9AFAF" }),
                width: "150px",
                fontSize: "15px",
              }),
              menu: (styles) => ({
                ...styles,
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected
                  ? "#F9AFAF"
                  : isFocused
                  ? "#FF5E6C"
                  : null,
                color: "#7F3333",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#7F3333",
              }),
            }}
          />,
          <Select
            onChange={handleDataSource}
            options={dataSources}
            isDisabled={selectedSelect === "option2"}
            isLoading={false}
            isClearable
            components={animatedComponents}
            closeMenuOnSelect={false}
            className="mt-2"
            styles={{
              control: (styles, { isFocused, menuIsOpen }) => ({
                ...styles,
                borderColor: isFocused ? "#F9AFAF" : "#ced4da",
                "&:hover": {
                  borderColor: "#F9AFAF",
                },
                ...(menuIsOpen && { borderColor: "#F9AFAF" }),
                width: "350px",
                fontSize: "15px",
              }),
              menu: (styles) => ({
                ...styles,
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected
                  ? "#F9AFAF"
                  : isFocused
                  ? "#FF5E6C"
                  : null,
                color: "#7F3333",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#7F3333",
              }),
            }}
          >
          </Select>,
          <Select
            onChange={handleSelectChange2}
            options={options2}
            defaultValue={defaultOption2}
            isLoading={false}
            isClearable
            isDisabled={selectedSelect === "dataSource"}
            placeholder="Stream, Dsp, Territory ..."
            components={animatedComponents}
            closeMenuOnSelect={false}
            className="mt-2"
            styles={{
              control: (styles, { isFocused, menuIsOpen }) => ({
                ...styles,
                borderColor: isFocused ? "#F9AFAF" : "#ced4da",
                "&:hover": {
                  borderColor: "#F9AFAF",
                },
                ...(menuIsOpen && { borderColor: "#F9AFAF" }),
                width: "350px",
                fontSize: "15px",
              }),
              menu: (styles) => ({
                ...styles,
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected
                  ? "#F9AFAF"
                  : isFocused
                  ? "#FF5E6C"
                  : null,
                color: "#7F3333",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#7F3333",
              }),
            }}
          >
          </Select>,
        ]}
      />
      <Container fluid={true}>
        <Col>
          {dataSource === "SoundCloud" && <SoundCloud selectedTimeRange={selectedTimeRange} />}
          {dataSource === "Spotify" && <Spotify selectedTimeRange={selectedTimeRange} />}
          {dataSource === "Amazon" && <Amazon selectedTimeRange={selectedTimeRange} />}
          {dataSource === "Tiktok" && <Tiktok selectedTimeRange={selectedTimeRange} />}
          {dataSource === "Shazam" && <Shazam selectedTimeRange={selectedTimeRange} />}
          {dataSource === "Anghami" && <Anghami selectedTimeRange={selectedTimeRange} />}
          {dataSource === "Deezer" && <Deezer selectedTimeRange={selectedTimeRange} />}
          {dataSource === "Social" && <Social selectedTimeRange={selectedTimeRange} />}
          {dataSource === "AppleMusic" && <AppleMusic selectedTimeRange={selectedTimeRange} />}

          {selectedOption2 === "StreamByTerritory" && <StreamByTerritory selectedTimeRange={selectedTimeRange} />}
          {selectedOption2 === "StreamByAsset" && <StreamByAsset selectedTimeRange={selectedTimeRange} />}
          {selectedOption2 === "StreamByDsp" && <StreamByDsp selectedTimeRange={selectedTimeRange} />}
          {selectedOption2 === "DownloadsByTerritory" && <DownloadByTerritory selectedTimeRange={selectedTimeRange} />}
          {selectedOption2 === "DownloadsByAsset" && <DownloadByAsset selectedTimeRange={selectedTimeRange} />}
          {selectedOption2 === "DownloadsByDsp" && <DownloadByDsp selectedTimeRange={selectedTimeRange} />}
        </Col>
      </Container>
    </Fragment>
  );
};

export default Analytics;
