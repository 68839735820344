import React, { Fragment , useState , useEffect, useContext} from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import LabelsTable from './LabelsTable';
import LabelContext from './../../../_helper/Label/index';
import GridLoader from '../../Loaders/GridLoader';
const ManageLabels = () => {

  const { getLabelsData } = useContext(LabelContext);
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLabels = async () => {
    setLoading(true); 
    const data = await getLabelsData();
    // console.log("data index:",data)
    setLabels(data);
    console.log("ManageLabels:",labels);
    setLoading(false); 
  };

  useEffect(() => {
    fetchLabels();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Labels" title="Labels List" mainTitle="Labels Data" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">

            <Card>
              <HeaderCard title="Manage Different Labels" />
              <CardBody>
              {loading ? (
                    <GridLoader />
                  ) : (
                <LabelsTable  labels={labels}/>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ManageLabels;