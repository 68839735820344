import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import {
  CheckCircle,
  PlusCircle,
  Users,
  UserCheck,
  Briefcase,
  Music,
} from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import {
  ALL,
  CreateNewUser,
  Admin,
  Label as label,
  Staff,
  Previous,
  Next,
} from "../../../../Constant";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import UserContext from "../../../../_helper/User";
import CusClass from "../Common/CussClass";
import CustomizerContext from "../../../../_helper/Customizer";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import { grid } from "ldrs";
const Artist = () => {
  grid.register();
  const { userData } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const [activeTab, setActiveTab] = useState("1");
  const { allData, getAllUsersData, updatePersonalDetailsByadmin } =
    useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  //For User List Loader
  const [loadingFetch, setLoadingFetch] = useState(true);
  const navigate = useNavigate();
  const toggleModal = () => {
    setModal(!modal);
  };

  //? Search Functionality
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = allData.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openEditModal = (user) => {
    setSelectedUser(user);
    toggleModal();
  };

  const updateUser = async () => {
    try {
      setLoading(true);
      const updatedUserData = {
        id: selectedUser.id_user,
        name: document.getElementById("editName").value,
        email: document.getElementById("editEmail").value,
        username: document.getElementById("editUsername").value,
        isSecured: document.getElementById("editStatus").value === "1",
      };
      await updatePersonalDetailsByadmin(updatedUserData);
      toast.success("User updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllUsersData();
        setLoadingFetch(false);
        console.log(loadingFetch);
      } catch (error) {
        console.error(error);
        setLoadingFetch(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Team"
          title="Manage"
          mainTitle="Staff"
          buttons={[
            <Btn
              attrBtn={{
                size: "l",
                className: "btn btn-primary",
                color: "primary",
                onClick: () =>
                  navigate(
                    `${process.env.PUBLIC_URL}/app/artist/new-staff/${layoutURL}`
                  ),
              }}
            >
              {/* <PlusCircle /> */}
              Create New Staff
            </Btn>,
          ]}
        />
        <Container fluid={true}>
          <Row className="justify-content-center">
            {/* <Col md="12" className="project-list">
              <Card>
                <Row>
                  <Col md="6">
                    
                    <Nav tabs className="border-tab">
                      <NavItem>
                        <NavLink
                          className={activeTab === "1" ? "active" : ""}
                          onClick={() => setActiveTab("1")}
                        >
                          <Users />
                          {ALL}
                        </NavLink>
                      </NavItem>
                      {userData.role !== "label" && (
                        // Render the "Admin" tab only if the user is not a label
                        <NavItem>
                          <NavLink
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => setActiveTab("2")}
                          >
                            <UserCheck />
                            {Admin}
                          </NavLink>
                        </NavItem>
                      )}
                      
                      <NavItem>
                        <NavLink
                          className={activeTab === "3" ? "active" : ""}
                          onClick={() => setActiveTab("3")}
                        >
                          <Music />
                          Artist
                        </NavLink>
                      </NavItem>
                      {userData.role !== "label" && (
                      <NavItem>
                        <NavLink
                          className={activeTab === "4" ? "active" : ""}
                          onClick={() => setActiveTab("4")}
                        >
                          <CheckCircle />
                          {label}
                        </NavLink>
                      </NavItem>
                      )}
                      <NavItem>
                        <NavLink
                          className={activeTab === "5" ? "active" : ""}
                          onClick={() => setActiveTab("5")}
                        >
                          <Briefcase />
                          {Staff}
                        </NavLink>
                      </NavItem>
                    </Nav> 
                     
                  </Col>

                  <Col md="6">
                    <div className="text-end">
                      <Link
                        className="btn btn-primary"
                        style={{ color: "white" }}
                        to={`${process.env.PUBLIC_URL}/app/artist/new-staff/${layoutURL}`}
                      >
                        {" "}
                        <PlusCircle />
                       Create New Staff
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col> */}
            <Col md="8">
              <Card>
                <CardBody>
                  <Form className="theme-form">
                    <FormGroup>
                      {/*   <Label className="col-form-label pt-0" >LABEL </Label>*/}
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Search Staffs"
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardBody>
                  {loadingFetch ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <l-grid size="150" speed="1.5" color="red"></l-grid>
                    </div>
                  ) : (
                    <Row>
                      {filteredUsers.some((item) => item.role === "staff") ? (
                        filteredUsers.map((item, i) =>
                          item.role === "staff" ? (
                            <CusClass
                              item={item}
                              key={i}
                              onEdit={openEditModal}
                            />
                          ) : (
                            ""
                          )
                        )
                      ) : (
                        <Col md="12" className="text-center">
                          <p>No staff found</p>
                        </Col>
                      )}
                    </Row>
                  )}
                  <div className="d-flex justify-content-center">
                    <Pagination aria-label="..." className="mt-3">
                      <ul className="pagination pagination-primary">
                        <PaginationItem disabled>
                          <PaginationLink>{Previous}</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink href="#javascript">
                            {"1"}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem active>
                          <PaginationLink href="#javascript">
                            {"2"} <span className="sr-only">{"(current)"}</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink href="#javascript">
                            {"3"}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink href="#javascript">
                            {Next}
                          </PaginationLink>
                        </PaginationItem>
                      </ul>
                    </Pagination>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Col md="12" className="project-list">
          <Modal isOpen={modal} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>Edit User</ModalHeader>
            <ModalBody>
              {/* Create a form to edit the user details */}
              {selectedUser && (
                <Form>
                  <FormGroup>
                    <Label for="editName">Name</Label>
                    <Input
                      type="text"
                      name="editName"
                      id="editName"
                      defaultValue={selectedUser.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="editEmail">Email</Label>
                    <Input
                      type="email"
                      name="editEmail"
                      id="editEmail"
                      defaultValue={selectedUser.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="editUsername">Username</Label>
                    <Input
                      type="text"
                      name="editUsername"
                      id="editUsername"
                      defaultValue={selectedUser.username}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="editStatus">Account Status</Label>
                    <Input
                      type="select"
                      name="editStatus"
                      id="editStatus"
                      defaultValue={selectedUser.status_compte ? "1" : "0"}
                    >
                      <option value="1">Activated</option>
                      <option value="0">Disabled</option>
                    </Input>
                  </FormGroup>
                </Form>
              )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="primary" onClick={updateUser}>
                {loading ? <Spinner size="sm" color="light" /> : "Save"}
              </Button>{" "}
              <Button color="dark" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
        {/* <ToastContainer /> */}
      </Fragment>
    </>
  );
};

export default Artist;
