import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button, Row, Col,
} from "reactstrap";
import TailChase from "../../../Loaders/TailChase";

const UpdateExpensesModal = ({
                               isOpen,
                               toggle,
                               onSave,
                               expense,
                               categories,
                               expenseType,
                             }) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...expense,
    },
  });

  const categoryFieldName =
      expenseType === "inhouse"
          ? "category_inhouse_id"
          : "category_contributors_id";

  const [selectedFile, setSelectedFile] = useState(null);
  const [newFileSelected, setNewFileSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (expense) {
      Object.keys(expense).forEach((key) => {
        if (key !== "file") {
          setValue(key, expense[key]);
        }
      });

      const categoryTitleField =
          expenseType === "inhouse"
              ? "category_inhouse"
              : "category_contributors";

      const category = categories.find(
          (category) => category.title === expense.expense_type
      );

      if (category) {
        setValue(categoryFieldName, category.id);
      }
    }
  }, [expense, setValue, categories, expenseType]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFile(null);
      setNewFileSelected(false);
      reset();
    }
  }, [isOpen, reset]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setSelectedFile(file);
      setNewFileSelected(true);
    } else {
      setSelectedFile(null);
      setNewFileSelected(false);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "file" && key !== "category_contributors" && key !== "category_inhouse") {
        formData.append(key, data[key]);
      }
    });
    if (newFileSelected) {
      formData.append("file", selectedFile);
    }
    onSave(formData).then(() => {
      reset();
      setIsLoading(false);
      setNewFileSelected(false);
      setSelectedFile(null);
    });
  };

  return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Update Expense</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="6">
              <FormGroup>
              <Label for="title">Title</Label>
              <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Title is required" }}
                  render={({ field }) => <Input {...field} />}
              />
              {errors.title && (
                  <div className="text-danger">{errors.title.message}</div>
              )}
            </FormGroup>
              </Col>

            <Col md="6">
            <FormGroup>
              <Label for="description">Description</Label>
              <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Description is required" }}
                  render={({ field }) => <Input {...field} />}
              />
              {errors.description && (
                  <div className="text-danger">{errors.description.message}</div>
              )}
            </FormGroup>
              </Col>
              <Col md="6">
            <FormGroup>
              <Label for={categoryFieldName}>Category</Label>
              <Controller
                  name={categoryFieldName}
                  control={control}
                  rules={{ required: "Category is required" }}
                  render={({ field }) => (
                      <Input
                          {...field}
                          type="select"
                          id={categoryFieldName}
                          invalid={!!errors[categoryFieldName]}
                      >
                        {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.title}
                            </option>
                        ))}
                      </Input>
                  )}
              />
              {errors[categoryFieldName] && (
                  <div className="text-danger">
                    {errors[categoryFieldName].message}
                  </div>
              )}
            </FormGroup>
                </Col>
              <Col md="6">
            <FormGroup>
              <Label for="amount">Amount</Label>
              <Controller
                  name="amount"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Amount is required" }}
                  render={({ field }) => <Input {...field} />}
              />
              {errors.amount && (
                  <div className="text-danger">{errors.amount.message}</div>
              )}
            </FormGroup>
                </Col>

            <Col md="6">
            <FormGroup>
              <Label for="status">Status</Label>
              <Controller
                  name="status"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Status is required" }}
                  render={({ field }) => (
                      <Input {...field} type="select" id="status">
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                      </Input>
                  )}
              />
              {errors.status && (
                  <div className="text-danger">{errors.status.message}</div>
              )}
            </FormGroup>
                </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="currencies">Currency</Label>
                  <Controller
                      name="currencies"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Currency is required" }}
                      render={({ field }) => (
                          <Input
                              {...field}
                              type="text"
                              id="currencies"
                              placeholder="Enter currency"
                              invalid={!!errors.currencies}
                          />
                      )}
                  />
                  {errors.currencies && (
                      <div className="text-danger">{errors.currencies.message}</div>
                  )}
                </FormGroup>
              </Col>
                <Col md="6">
            <FormGroup>
              <Label for="file">File</Label>
              <Input type="file" onChange={handleFileChange} />
            </FormGroup>
                </Col>


            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
              type="submit"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              style={{ margin: "0 auto" }}
          >
            {isLoading ? <TailChase /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
  );
};

export default UpdateExpensesModal;