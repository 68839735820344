import React from "react";

import FilterProvider from "./_helper/Filter/FilterProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import UserProvider from "./_helper/User/UserProvider";
import LabelProvider from "./_helper/Label/LabelProvider";
import { AuthProvider } from "./_helper/Auth/AuthProvider";
import { BarcodeProvider } from "./_helper/Barcode/BarcodeProvider";
import { DspProvider } from "./_helper/DSP/DspProvider";
import { ReleaseProvider } from "./_helper/Release/ReleaseProvider";
import { ValidationProvider } from "./_helper/Deliver/ValidationProvider";
import { AnalyticsProvider } from "./_helper/Analytics/AnalyticsProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const Providers = ({ children }) => (
  <AuthProvider>
 
      <CustomizerProvider>
        <ValidationProvider>
          <FilterProvider>
            <UserProvider>
              <AnalyticsProvider>
                <DspProvider>
                  <BarcodeProvider>
                    <LabelProvider>
                      <AnimationThemeProvider>
                        <ReleaseProvider>
                             <QueryClientProvider client={queryClient}>
                          {children}
                              </QueryClientProvider>
                          </ReleaseProvider>
                      </AnimationThemeProvider>
                    </LabelProvider>
                  </BarcodeProvider>
                </DspProvider>
              </AnalyticsProvider>
            </UserProvider>
          </FilterProvider>
        </ValidationProvider>
      </CustomizerProvider>

  </AuthProvider>
);

export default Providers;
