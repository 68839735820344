import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import {
  Breadcrumbs,
  Btn,
  Alerts,
  H6,
  P,
  H4,
} from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import DspTable from "./DspTable";
import { useDspContext } from "../../../../_helper/DSP/DspProvider";
import GridLoader from "../../../Loaders/GridLoader";
import { toast } from "react-toastify";
import { set } from "date-fns";
const DspList = () => {
  const { dsps, loading, fetchDsp, deleteDsp, error } = useDspContext();
  const [inUseFilter, setInUseFilter] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(!showModal);
  const [initialRowsToShow, setInitialRowsToShow] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchDsp();
      } catch (error) {
        console.error("Error fetching DSP data:", error);
      }
    };
    fetchData();
  }, []);

  const handleDeleteDsp = async (dspId) => {
    const result = await deleteDsp(dspId);
    if (result.success) {
      toast.success("DSP deleted successfully");
    } else {
      toast.error(`Failed to delete DSP: ${result.error}`);
    }
  };

  const handleShowAddDspModal = () => {
    setShowModal(true);
  };

  // const handleCloseModal = () => {
  //   setShowModal(false);
  //   setInitialRowsToShow(5);
  // };

  return (
    <Fragment>
      <Breadcrumbs
        parent="DSP Management"
        title="DSPs"
        mainTitle="DSP Data"
        buttons={[
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",
              // outline: true,
              // disabled: true,
              onClick: handleShowAddDspModal,
            }}
          >
            <i className="fa fa-plus me-1"></i>Add DSP
          </Btn>,
        ]}
      />
      <Container fluid={true}>
        {error ? (
          <div className="text-center">
            <Alerts attrAlert={{ color: "primary " }}>
              <H4 attrH4={{ className: "alert-heading" }}>Error</H4>
              <P>{error}</P>
              <hr />
              <P attrPara={{ className: "mb-0" }}>
                If you need immediate assistance, please contact support at
                support@reddiamond.com.
              </P>
            </Alerts>
          </div>
        ) : (
          <Row>
            <Col sm="12">
              <Card>
                <HeaderCard title="Manage your DSPs" />
                <CardBody>
                  {loading ? (
                    <GridLoader />
                  ) : (
                    <DspTable
                      dsps={dsps}
                      inUseFilter={inUseFilter}
                      setInUseFilter={setInUseFilter}
                      showAddDspModal={showModal}
                      setShowAddDspModal={setShowModal}
                      onDeleteDsp={handleDeleteDsp}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default DspList;
