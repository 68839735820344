import React, { Fragment, useState } from "react";
import { XCircle } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Btn, H5 } from "../../../../AbstractElements";

import Contributors from "./Tabs/Contributors";
import Files from "./Tabs/Files";
import Metadata from "./Tabs/Metadata";
import GridLoader from "../../../Loaders/GridLoader";
const StepTwo = ({
  control,
  errors,
  handleSubmit,
  loading,
  handleBack,
  handleNextSecondStep,
  setValue,
  artists,
  handleCancel,
  genres,
  cards,
  reset,
  resetField,
  register,
  deleteForm,
  clearErrors,
}) => {
  // const getUploadParams = ({ meta }) => {
  //   return {
  //     url: "https://httpbin.org/post",
  //   };
  // };

  const [selectedTabs, setSelectedTabs] = useState(["1-0"]);

  // const [cards, setCards] = useState([0]);

  // const addForm = () => {
  //   setCards((prevCards) => [...prevCards, prevCards.length]);
  // };

  const selectTab = (cardIndex, tabIndex) => {
    setSelectedTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[cardIndex] = tabIndex;
      return newTabs;
    });
  };
  // console.log(selectedTabs, "selectedTabs")
  return (
    <Fragment>
      {cards.map((card, i) => (
        <Col sm="12" key={i}>
          {loading ? (
            <GridLoader />
          ) : (
            <>
              <Card>
                <CardHeader
                  title={`Asset N°${i + 1} `}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <H5 style={{ margin: 0 }}>Asset Creation #{i + 1}</H5>
                  {cards.length > 1 && (
                    <div style={{ marginLeft: "auto" }}>
                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "p-r-30",
                          type: "button",
                          style: {
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            padding: "0",
                          },
                          onClick: (e) => {
                            deleteForm(i);
                          },
                        }}
                      >
                        <XCircle />
                      </Btn>
                    </div>
                  )}
                </CardHeader>

                <CardBody>
                  <Nav className="nav nav-tabs nav-right">
                    <NavItem>
                      <NavLink
                        className={selectedTabs[i] === `1-${i}` ? "active" : ""}
                        onClick={() => selectTab(i, `1-${i}`)}
                      >
                        <i className="icofont icofont-contacts"></i>
                        Metadata
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={selectedTabs[i] === `2-${i}` ? "active" : ""}
                        onClick={() => selectTab(i, `2-${i}`)}
                      >
                        <i className="icofont icofont-upload"></i>
                        Files
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={selectedTabs[i] === `3-${i}` ? "active" : ""}
                        onClick={() => selectTab(i, `3-${i}`)}
                      >
                        <i className="icofont icofont-man-in-glasses"></i>
                        Contributors
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={selectedTabs[i]}>
                    <TabPane className="fade show" tabId={`1-${i}`}>
                      <Metadata
                        i={i}
                        artists={artists}
                        genres={genres}
                        handleNextSecondStep={handleNextSecondStep}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                      />
                    </TabPane>
                    <TabPane className="fade show" tabId={`2-${i}`}>
                      <Files
                        control={control}
                        errors={errors}
                        register={register}
                        handleNextSecondStep={handleNextSecondStep}
                        i={i}
                        setValue={setValue}
                        clearErrors={clearErrors}
                      />
                    </TabPane>
                    <TabPane className="fade show" tabId={`3-${i}`}>
                      <Contributors
                        control={control}
                        errors={errors}
                        // register={register}
                        handleNextSecondStep={handleNextSecondStep}
                        assetIndex={i}
                        reset={reset}
                        resetField={resetField}
                        clearErrors={clearErrors}
                        setValue={setValue}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      ))}
    </Fragment>
  );
};

export default StepTwo;
