import { Image, H4, H6, P } from '../../../../AbstractElements';
import { Col, Media, Row } from 'reactstrap';
import React, { Fragment } from 'react';

const InvoiceDescription = ({ artist }) => {
  return (
      <Fragment>
        <Row className='invo-profile'>
          <Col xl='4'>
            <div className='invo-profile-left'>
              <Media>
                <div className='media-left'>
                  <Image attrImage={{ className: 'media-object rounded-circle img-60', src: `${artist.image || 'default_image_path'}`, alt: '' }} />
                </div>
                <Media body className='m-l-20'>
                  <H4 attrH4={{ className: 'media-heading f-w-600' }}>{artist.full_name}</H4>
                  <P>
                    {artist.address || 'No Adress'}
                    <br />
                    <span className='digits'>{artist.phone_number || 'No Phone number'}</span>
                  </P>
                </Media>
              </Media>
            </div>
          </Col>
          <Col xl='8'>
            <div className='text-md-end'>
              <div id='project'>
                <H6>{"Additional Info"}</H6>
                <P>{artist.additional_info || 'No additional info available'}</P>
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
  );
};

export default InvoiceDescription;