// UserService.js
import axiosInstance from '../../api/axios';

const UserService = {
  
  register: async (userData, imageFile) => {
    try {
      axiosInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();
      for (const key in userData) {
        formData.append(key, userData[key]);
      }
      formData.append('image_label', imageFile);
      const response = await axiosInstance.post('/register', formData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Registration failed', error);
      return { success: false, error };
    }
  },
  





  getAllUsers: async (page = 1, role = '') => {
    try {
      const response = await axiosInstance.get(`/users/all?page=${page}&role=${role}`);
      return { success: true, data: response.data.data, meta: response.data.meta };
    } catch (error) {
      console.error('Failed to fetch users', error);
      return { success: false, error };
    }
  },

  updatePersonalDetails: async (userDetails) => {
    try {
      const response = await axiosInstance.post('/users/update_personal_details', userDetails);
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Failed to update personal details', error);
      return { success: false, error };
    }
  },

  uploadImage: async (imageFile) => {
    try {
      //axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axiosInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();
      formData.append('image', imageFile);
      const response = await axiosInstance.post('/users/update_personal_details', formData);
      const { message, user, url } = response.data;
      console.log('response.data', response.data)
      return { success: true, message, user, url };
    } catch (error) {
      console.error('Failed to upload image', error);
      return { success: false, error };
    }
  },

  updateBankDetails: async (bankDetails) => {
    try {
      const response = await axiosInstance.post('users/update_bank_details', bankDetails);
      return response.data;
    } catch (error) {
      console.error('Error updating bank details', error);
      throw error;
    }
  },

  getBankDetails: async () => {
    try {
      const response = await axiosInstance.get('users/bank_details');
      return { success: true, data: response.data.bankDetails };
    } catch (error) {
      console.error('Error fetching bank details', error);
      return { success: false, error };
    }
  },

  updateUserPersonalDetails: async (userDetails) => {
    try {
      const response = await axiosInstance.post('/users/updateUserByadmin', userDetails);
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Failed to update personal details', error);
      return { success: false, error };
    }
  },
  

};





export default UserService;