import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
import { Trash, Edit } from "react-feather";
import UpdateAsset from "../Modals/UpdateModal";
import { getAudioFile } from "../../../../../../_helper/Images/ImageHelper";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "react-h5-audio-player/lib/styles.less";
import { Breadcrumbs, H6, Spinner } from "../../../../../../AbstractElements";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { useReleaseContext } from "../../../../../../_helper/Release/ReleaseProvider";
const AssetTable = ({
  assets,
  artist,
  newAsset,
  artists,
  genres,
  // loading,
  fetchedProduct,
  i,
  // UpdateAssetRecord,
}) => {
  const { updateAsset, loading, deleteAssetById , setFetchedProductAssets} = useReleaseContext();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [data, setData] = useState(assets);
  const [showModal, setShowModal] = useState(false);
  // const [stateAsset, setstateAsset] = useState(false);

  const [loader, setloader] = useState(false);
  // console.log("data", data)
  const {
    control,
    errors,
    setValue,
    clearErrors,
    resetField,
    getValues,
    trigger,
  } = useFormContext();

  console.log("AssetTable assets:", data);
  //  console.log("AssetTable newAsset:", newAsset);
  // console.log("AssetTable Data:", data);

  // const UpdateAssetRecord = async () => {
  //   const isValid = await trigger();
  //   console.log(isValid);
  //   if (!isValid) {
  //     return;
  //   }
  //   let formData = getValues();
  //   const filteredData = Object.fromEntries(
  //     Object.entries(formData).filter(([_, value]) => value !== undefined)
  //   );
  //   const formDataWithFile = new FormData();
  //   Object.entries(filteredData).forEach(([key, value]) => {
  //     formDataWithFile.append(key, value);
  //   });
  //   console.log(formDataWithFile, formData);
  //   try {
  //     const response = await updateAsset(formDataWithFile);
  //     if (response && response.success === true) {
  //       toast.success(
  //         "Asset is updated successfully ! Please hold on while were preparing ..."
  //       );
  //       // handleAssetUpdate(response.asset);
  //       //setAssets(updatedAssets);

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 5000);
  //     } else {
  //       console.error("Response is undefined or status is not true");
  //     }
  //   } catch (error) {
  //     console.error("Error calling saveStepData:", error);
  //     toast.error(
  //       "An error occurred while Updating your asset if this persists please contact the adminstrator"
  //     );
  //   }
  // };

  const UpdateAssetRecord = async () => {
    const isValid = await trigger();
    console.log(isValid);
    if (!isValid) {
      return;
    }
    let formData = getValues();
    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== undefined)
    );
  
    const formDataWithFile = new FormData();
    Object.entries(filteredData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (key.startsWith("tags")) {
          // Handle tags field
          formDataWithFile.append(key, JSON.stringify(value));
        } else {
          value.forEach((element, index) => {
            if (typeof element === "object") {
              formDataWithFile.append(`${key}_${index}`, JSON.stringify(element));
            } else {
              formDataWithFile.append(`${key}_${index}`, element);
            }
          });
        }
      } else if (typeof value === "object" && key === "others_artists") {
        // Convert others_artists array of objects to array-like format
        value.forEach((obj, index) => {
          const { value: val, label } = obj;
          formDataWithFile.append(
            `others_artists_${index}`,
            JSON.stringify({ value: val, label })
          );
        });
      } else if (typeof value === "object") {
        formDataWithFile.append(key, JSON.stringify(value));
      } else {
        formDataWithFile.append(key, value);
      }
    });
  
    // console.log(formDataWithFile, formData);
    try {
      const response = await updateAsset(formDataWithFile);
  
      if (response && response.success === true) {
        toast.success("Asset is updated successfully !");
        const updatedAsset = response.response.data.asset;
        setSelectedAsset(updatedAsset);
      } else {
        console.error("Response is undefined or status is not true");
      }
    } catch (error) {
      console.error("Error calling saveStepData:", error);
      toast.error(
        "An error occurred while Updating your asset if this persists please contact the adminstrator"
      );
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete this asset?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteResult = await deleteAssetById(id);
          if (deleteResult.success) {
            Swal.fire({
              title: "Success",
              text: "Asset deleted successfully!",
              icon: "success",
            });
            // Update data state to remove the deleted asset
            setData(data.filter(asset => asset.id !== id));
          } else {
            throw new Error(deleteResult.error);
          }
        } catch (error) {
          console.error("Error deleting asset:", error);
          Swal.fire({
            title: "Error",
            text: "There was an error deleting the asset.",
            icon: "error",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (Object.keys(newAsset).length > 0) {
      console.log("AssetTable newAsset:", newAsset);
      setData((prevData) => [...prevData, ...newAsset]);
    } else {
      setData(assets);
    }
  }, [assets, newAsset]);

  const handleCloseModal = () => {
    setSelectedAsset(null);
  };

  /*   useEffect(() => {
    console.log("Selected Asset:", selectedAsset); // Log selectedAsset whenever it changes
  }, [selectedAsset]); */

  /*  */

  //! Need to change the i inside Step2 Form tabs to be able to pass it or not based on the component (tHIS IS A temporary Fix)
  const columns = [
    /*     {
      name: "id",
      selector: (row) => row.id,
      hidden: true,
    }, */
    {
      name: "Title",
      selector: (row) =>
        row.title ? row.title : row.title_0 ? row.title_0 : "No title",
      sortable: true,
    },
    {
      name: "Artist",
      selector: (row) =>
        row.assetartist?.name
          ? row.assetartist.name
          : row.artist_0
          ? row.artist_0
          : "No artist",
      sortable: true,
    },
    {
      name: "audio player",
      cell: (row) => (
        <div style={{ width: "100%" }}>
          {loader ? (
            <div className="loader-box">
              <Spinner
                attrSpinner={{
                  className: "loader-1",
                }}
              />
            </div>
          ) : (
            <AudioPlayer
              autoPlay={false}
              layout="horizontal-reverse"
              showSkipControls={false}
              showJumpControls={false}
              showDownloadProgress={false}
              customVolumeControls={[]}
              src={getAudioFile(row.file)}
              customAdditionalControls={[]}
              preload={"auto"}
              onPlay={(e) => console.log("onPlay")}
            />
          )}
        </div>
      ),
      width: "350px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              handleDelete(row.id);
              // console.log(row.id);
            }}
            title="Delete"
            className="me-2"
          >
            <Trash size={15} />
          </Button>
          <Button
            size="sm"
            color="primary"
            onClick={() => setSelectedAsset(row)}
            title="Update"
            //className="mt-1"
          >
            <Edit size={15} />
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  useEffect(() => {}, [data]);

  return (
    <Fragment>
      <DataTable
        data={data}
        columns={columns}
        striped={true}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15]}
        paginationComponentOptions={{
          rowsPerPageText: "Assets per page:",
          rangeSeparatorText: "of",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "All",
        }}
      />
      <UpdateAsset
        show={selectedAsset !== null}
        handleClose={handleCloseModal}
        selectedAsset={selectedAsset}
        assetIndex={0}
        artists={artists}
        control={control}
        genres={genres}
        UpdateAssetRecord={UpdateAssetRecord}
        loading={loading}
        setValue={setValue}
        fetchedProduct={fetchedProduct}
        clearErrors={clearErrors}
        resetField={resetField}
        i={i}
      />
    </Fragment>
  );
};

export default AssetTable;
