import React, { useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Controller } from "react-hook-form";

const AdditionalRowsModal = ({
  showModal,
  handleCloseModal,
  selectedRows,
  handleUpdate,
  control,
  setValue,
}) => {
  useEffect(() => {
    if (selectedRows) {
      setValue("id", selectedRows.id);
      setValue("name", selectedRows.name);
      setValue("url", selectedRows.url);
      setValue("logo", selectedRows.logo);
      setValue("territories", selectedRows.territories);
      setValue("description", selectedRows.description);
      setValue("lead_time", selectedRows.lead_time);
      setValue("xml_file", selectedRows.xml_file);
    }
  }, [selectedRows, control]);

  if (!selectedRows) {
    return null;
  }

  // console.log("selectedRows from Modal", selectedRows.logo);

  return (
    <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center">Update DSP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form-control">
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={selectedRows.name}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="url">
                <Form.Label>URL</Form.Label>
                <Controller
                  name="url"
                  control={control}
                  defaultValue={selectedRows.url}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="logo">
                <Form.Label>Logo</Form.Label>
                <Controller
                  name="logo"
                  control={control}
                  defaultValue={selectedRows.logo}
                  render={({ field }) => (
                    <Form.Control type="text" {...field} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="territories">
                <Form.Label>Territories</Form.Label>
                <Controller
                  name="territories"
                  control={control}
                  defaultValue={selectedRows.territories}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="description" className="mb-3">
            <Form.Label>Description</Form.Label>
            <Controller
              name="description"
              control={control}
              defaultValue={selectedRows.description}
              render={({ field }) => (
                <Form.Control as="textarea" {...field} rows={5} />
              )}
            />
          </Form.Group>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="lead_time">
                <Form.Label>Lead Time</Form.Label>
                <Controller
                  name="lead_time"
                  control={control}
                  defaultValue={selectedRows.lead_time}
                  render={({ field }) => (
                    <DatePicker
                      className="form-control input-air-primary"
                      placeholderText="mm/dd/yyyy"
                      selected={moment(field.value).toDate()}
                      onChange={(date) => field.onChange(date)}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="xml_file">
                <Form.Label>XML File</Form.Label>
                <Controller
                  name="xml_file"
                  control={control}
                  defaultValue={selectedRows.xml_file}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdditionalRowsModal;
