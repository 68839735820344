import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Button,
  ButtonGroup,
} from "reactstrap";
import { H5, Btn } from "../../../../AbstractElements";
import { grid } from "ldrs";
import CountryFlag from "react-country-flag";
import ProgressBar from "react-bootstrap/ProgressBar";

const StepThree = ({
  loading,
  dsps,
  countries,
  handleStepThreeSubmit,
  selectedDsps, 
  setSelectedDsps,
   selectedCountries, 
   setSelectedCountries 
}) => {
  // const [selectedDsps, setSelectedDsps] = useState([]);
  // const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchDspText, setSearchDspText] = useState("");

  const [processing, setProcessing] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries);

  
  
  const selectedRowObjects = selectedCountries.map(id => filteredCountries.find(country => country.id === id));
  useEffect(() => {
    // Check if both DSPs and countries are selected
    setFormValid(selectedDsps.length > 0 && selectedCountries.length > 0);
  }, [selectedDsps, selectedCountries]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValid) {
      setProcessing(true);
      await handleStepThreeSubmit(selectedDsps, selectedCountries);
      setTimeout(() => {
        setProcessing(false);
      }, 5000);
    } else {
      alert("Please select at least one country and one DSP.");
    }
  };


  const handleSelectAll = () => {
    setSelectedCountries(filteredCountries.map(country => country.id));
  };


  const handleChangeDsps = (state) => {
    setSelectedDsps(state.selectedRows);
  };

  const handleChangeCountries = (state) => {
      setSelectedCountries(state.selectedRows);
    
  };
  

  grid.register();

  // Define columns for DSP DataTable
  const dspColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Logo",
      selector: (row) => row.logo,
      sortable: true,
    },
  ];

  const countryColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,

    },
    {
      name: "Flag",
      cell: (row) => (
        <CountryFlag
          countryCode={row.iso2 || row.iso3}
          style={{ fontSize: "2em" }}
          svg
        />
      ),
      sortable: false,

    },
    {
      name: "Region",
      selector: (row) => row.region,
      sortable: true,
    },
  ];
  const handleFilterChange = (region) => {
    setFilter(region);
    let filteredData;
    if (region === "All") {
      filteredData = countries;
    } else {
      filteredData = countries.filter((country) =>
        region ? country.region === region : true
      );
    }
    setFilteredCountries(filteredData);
  };
  
 
  return (
    <Fragment>
      <Col sm="12">
        {processing ? (
          <div className="d-flex flex-column justify-content-center align-items-center vh-100">
            <p className="text-center mb-3">
              Creating your product. Please wait...
            </p>
            <ProgressBar
              animated
              now={100}
              variant="primary"
              style={{ width: "50%" }}
            />
          </div>
        ) : (
          <Form className="theme-form" id="step3" onSubmit={handleSubmit}>
             {/* <Row className="justify-content-center mb-3">
              <Col xs="auto">
                <ButtonGroup className="btn-group-pill">
                  <Btn
                    attrBtn={{
                      type: "button",
                      size: "lg",
                      color: "primary",
                      outline: true,
                      onClick: handleCancel,
                    }}
                  >
                    Cancel
                  </Btn>
                  <Btn
                    attrBtn={{
                      size: "lg",
                      color: "primary",
                      outline: true,
                      type: "submit",
                    }}
                  >
                    Submit
                  </Btn>
                </ButtonGroup>
              </Col>
             </Row> */}
            <Card>
              <CardHeader>
                  <H5>{" Dsp Selection"}</H5>
                  <span>{"Carefully choose your dsps "}</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="mb-3  p-3 dsp-section">
                    <DataTable
                      columns={dspColumns}
                      data={dsps.filter((dsp) =>
                        dsp.name
                          .toLowerCase()
                          .includes(searchDspText.toLowerCase())
                      )}
                      selectableRows
                      selectableRowsHighlight
                      onSelectedRowsChange={handleChangeDsps}
                    />
                  </Col>
                </Row>
                
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <H5>{" Countries Selection"}</H5>
                <span>{"Carefully choose your countries "}</span>
              </CardHeader>

              <CardBody>
              <Row>
                  <Col className="country-section">
                    <div className="d-flex justify-content-center mb-3">
                      <div className="btn-showcase">
                        <Btn
                          attrBtn={{
                            type: "button",
                            size: "xs",
                            color: "primary",
                            outline: true,
                            onClick: () => handleFilterChange("Americas"),
                          }}
                        >
                          Americas
                        </Btn>
                        <Btn
                          attrBtn={{
                            type: "button",
                            size: "xs",
                            color: "primary",
                            outline: true,
                            onClick: () => handleFilterChange("Asia"),
                          }}
                        >
                          Asia
                        </Btn>
                        <Btn
                          attrBtn={{
                            type: "button",
                            size: "xs",
                            color: "primary",
                            outline: true,
                            onClick: () => handleFilterChange("Europe"),
                          }}
                        >
                          Europe
                        </Btn>
                        <Btn
                          attrBtn={{
                            type: "button",
                            size: "xs",
                            color: "primary",
                            outline: true,
                            onClick: () => handleFilterChange("Oceania"),
                          }}
                        >
                          Oceania
                        </Btn>
                        <Btn
                          attrBtn={{
                            type: "button",
                            size: "xs",
                            color: "primary",
                            outline: true,
                            onClick: () => handleFilterChange("Polar"),
                          }}
                        >
                          Polar
                        </Btn>
                        <Btn
                          attrBtn={{
                            type: "button",
                            size: "xs",
                            color: "primary",
                            outline: true,
                            onClick: () => handleFilterChange("Africa"),
                          }}
                        >
                          Africa
                        </Btn>
                        <Btn
                          attrBtn={{
                            type: "button",
                            size: "xs",
                            color: "primary",
                            outline: true,
                            onClick: () => handleFilterChange("All"),
                          }}
                        >
                          WorldWide
                        </Btn>
                      </div>
                    </div>
                      <DataTable
                        columns={countryColumns}
                        data={filteredCountries}
                        pagination
                        selectableRows
                        selectableRowsHighlight
                        onSelectedRowsChange={handleChangeCountries}
                        selectedRows={selectedRowObjects}
                      />
                  </Col>
                </Row>
              </CardBody>

            </Card>  
          </Form>
        )}
      </Col>
    </Fragment>
  );
};

export default StepThree;