import React, { Fragment, useContext, useState } from 'react';
import { Container } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import FilterContext from '../../../../_helper/Filter';
import ReleaseFeatures from './ReleaseFeatures';
import ReleaseGrid from './ReleaseGrid';
const Catalog = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const { filter, filterContext, searchBy} = useContext(FilterContext);
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Releases" parent="Releases" title="Catalog" />
      <Container fluid={true} className="product-wrapper" id="product-wrapper">
      <div className="product-grid"> 
          <ReleaseFeatures searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword}  />
          <ReleaseGrid filter={filter} filterContext={filterContext} searchBy={searchBy}  />
        </div>
      </Container>
    </Fragment>
  );
};

export default Catalog;