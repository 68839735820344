import axios from "axios";
const AnalyticsService = {
  
  getSpotifyData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/get_spotify_data`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Spotify data:', error);
    }
  },

  getAppleMusicData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/get_applemusic_data`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Apple Music data:', error);
    }
  },

  getAmazonMusicData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/get_amazon_data`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange  
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Amazon Music data:', error);
    }
  },

  getDeezerData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/get_deezer_data`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Deezer data:', error);
    }
  },

  getDownloadsData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/get_downloads_data`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Downloads data:', error);
    }
  },

  getStreamData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/get_streams_data`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Stream data:', error);
    }
  },

  getStreamTerritoryData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/streams_by_territory`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Stream Territory data:', error);
    }
  },

  getFanbaseData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/getfanbase`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Fanbase data:', error);
    }
  },

  getTiktokData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/getTiktokData`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Tiktok data:', error);
    }
  },

  getSoundcloudData: async (selectedTimeRange) => {
    try {
      const response = await axios.get(`https://pulse.centrakey.com/api/get_soundcloud_data`, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD
        },
        params: {
          user_id: 505,
          timeRange: selectedTimeRange
        },
      });
      console.log("response", response.data)
      return response.data;

    } catch (error) {
      console.error('Error fetching Soundcloud data:', error);
    }
  },
};
export default AnalyticsService;
