// PinModalContent.jsx

import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { Btn, P } from '../../AbstractElements';
import customeAxios from '../../api/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiEye, FiEyeOff } from 'react-icons/fi'; 
import { useNavigate } from "react-router-dom";
import TailChase from '../../Components/Loaders/TailChase';
const PinModalContent = ({ toggle, layoutURL }) => {
  const [pin, setPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const pinInputRef = useRef(null); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); 


  useEffect(() => {
    pinInputRef.current.focus();
  }, []); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await customeAxios.post('/verify_pin', { pin });  
      if (response.status === 200) {
        toast.success('PIN verification successful');
        navigate(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
      }
    } catch (error) {
      setError('Error verifying PIN. Please try again.');
      toast.error('Error verifying PIN. Please try again.');
      console.error('Error:', error); // Log the error details
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <Container fluid className='p-0'>
          <Row className='m-0'>
            <Col className='p-0'>
                <p attrPara={{ className: 'mb-3' }}>
                  This is a security layer to prevent unauthorized accees
                </p>
              <Form onSubmit={handleSubmit}>
                <FormGroup className='position-relative mb-3 text-center'>
                  <Label className='col-form-label'>Enter your 6-digit PIN</Label>
                  <div className='position-relative'>
                    <Input
                      innerRef={pinInputRef} 
                      className='form-control'
                      type={showPin ? 'text' : 'password'}
                      name='pin'
                      value={pin}
                      onChange={(e) => setPin(e.target.value.replace(/\D/, '').slice(0, 6))} 
                      pattern="\d*"
                      inputMode="numeric"
                      maxLength={6}
                      minLength={6}
                      required
                      placeholder=''
                      style={{ fontSize: '1.8rem', letterSpacing: '14px', textAlign: 'center' }} 
                    />
                    <div
                      className='position-absolute end-0 top-50  translate-middle-y me-3'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPin(!showPin)}
                    >
                      {showPin ? <FiEye style={{ fontSize: '1rem' }} /> : <FiEyeOff style={{ fontSize: '1.5rem' }} />}
                    </div>
                  </div>
                </FormGroup>
                {error && <P>{error}</P>}
                <Btn
                  attrBtn={{
                    className: 'd-block w-100',
                    color: 'primary',
                    type: 'submit',
                  }}
                >
                 {loading ? <TailChase /> : "Submit"}
                </Btn>
              </Form>
            </Col>
          </Row>
        </Container>
    </>
  );
};

export default PinModalContent;
