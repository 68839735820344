import React, { useState } from 'react';
import { H4, H6 } from '../../../../AbstractElements';
import CountUp from 'react-countup';
import { Card, CardBody, Container, Row, Col, CardHeader , CardTitle } from 'reactstrap';
import { Users, Video, ThumbsUp } from 'react-feather';
import { AnghamiWidgetsData } from './widgetData';
import DataTable from 'react-data-table-component';

export default function Anghami() {
  // Dummy data for the table
  const data = [{ id: 1, title: 'Conan the Barbarian', year: '1982' }];
  const columns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Year',
      selector: row => row.year,
      sortable: true,
      right: true,
    },
  ];

  const [widgetsData, setWidgetsData] = useState(AnghamiWidgetsData);

  const SmallWidgets = ({ data, mainClass }) => {
    const { Icon } = data;
    return (
      <Card className={`small-widget ${mainClass ? mainClass : ''}`}>
        <CardBody className={data.color}>
          <span className='f-light'>{data.title}</span>
          <div className='d-flex align-items-end gap-2'>
            <H4>
              <CountUp suffix={data.suffix ? data.suffix : ''} prefix={data.prefix ? data.prefix : ''} duration={0} separator=',' end={data.total} />
            </H4>
            <span className={`font-${data.color} f-12 f-w-500`}>
              <i className={`icon-arrow-${data.gros < 50 ? 'down' : 'up'}`} />
              <span>
                {data.gros < 50 ? '-' : '+'}
                {data.gros}%
              </span>
            </span>
          </div>
          <div className='bg-gradient'>
            <Icon className='stroke-icon svg-fill' />
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <Container>
      <Row>
        {widgetsData.map((widgetData, index) => (
          <Col key={index}>
            <SmallWidgets data={widgetData} />
          </Col>
        ))}
      </Row>

      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <H6>Anghami Table</H6>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <DataTable
              columns={columns}
              data={data}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  )
}