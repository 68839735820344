import React, { createContext, useContext, useState, useEffect } from "react";
import DspService from "../../Services/dsp/DspService";

const DspContext = createContext();

export const useDspContext = () => {
  return useContext(DspContext);
};

export const DspProvider = ({ children }) => {
  const [dsps, setDsps] = useState([]);
  const [loadingDsp, setLoadingDsp] = useState(false);
  //? experimental implementation to handle errors
  const [error, setError] = useState(null);

  const fetchDsp = async () => {
    setLoadingDsp(true);
    try {
      const response = await DspService.getAllDsps();
      if (response.success) {
        setDsps(response.dsps);
        console.log(response.dsps);
        setError(null);
      } else {
        setError(response.error); 
      }
    } catch (error) {
      console.error("Error fetching DSPs:", error);
      setError(error.message); 
    } finally {
      setLoadingDsp(false);
    }
  };

  const addDsp = async (newDspData) => {
    try {
      // Make the API call to add the DSP data
      const response = await DspService.addDsp(newDspData);
      if (response.success) {
        // If successful, fetch the updated list of DSPs
        fetchDsp();
        // Include the DSP object in the response
        return { success: true, dsp: response.data.dsp };
      } else {
        return { success: false, error: response.error };
      }
    } catch (error) {
      console.error("Error adding DSP:", error);
      return { success: false, error };
    }
  };

  const updateDsp = async (dspId, updatedDspData) => {
    try {
      const response = await DspService.updateDsp(dspId, updatedDspData);
      if (response.success) {
        fetchDsp();
        return { success: true };
      } else {
        return { success: false, error: response.error };
      }
    } catch (error) {
      console.error("Error updating DSP:", error);
      return { success: false, error };
    }
  };

  const deleteDsp = async (dspId) => {
    setLoadingDsp(true);
    try {
      const response = await DspService.deleteDsp(dspId);
      if (response.success) {
        fetchDsp(); //? experimental implementation to refresh the data after deletion
        return { success: true };
      } else {
        return { success: false, error: response.error };
      }
    } catch (error) {
      console.error('Error deleting DSP:', error);
      return { success: false, error };
    } finally {
      setLoadingDsp(false);
    }
  };


  return (
    <DspContext.Provider
      value={{ dsps, loadingDsp, fetchDsp, addDsp, updateDsp, deleteDsp, error }}
    >
      {children}
    </DspContext.Provider>
  );
};
