import React, { useState } from 'react';
import { Alert, Collapse } from 'reactstrap';

const MissingDataAlert = ({ missingData, onDelivery }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Alert color="danger" className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="alert-heading mb-0">Missing Data:</h4>
          <button
            className="btn btn-link text-white"
            type="button"
            onClick={toggleCollapse}
            aria-expanded={isOpen}
            aria-controls="missingDataCollapse"
          >
            {isOpen ? 'Close' : 'Open'}
          </button>
        </div>
        <Collapse isOpen={isOpen} id="missingDataCollapse">
          <p className="mb-3">
            Please complete all the missing data items before delivering the product. Once finished, you can click
            "Deliver" to complete the process. But before make sure to save the changes.
          </p>
          <ul>
            {missingData.map((item, index) => (
              <li key={index}>
                <i className="fa fa-dot-circle-o me-2"></i>
                {item}
              </li>
            ))}
          </ul>
        </Collapse>
      </Alert>
    </div>
  );
};

export default MissingDataAlert;
