import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import SmartlinkTable from './SmartlinkTable'; // Changed import to SmartlinkTable
import Swal from 'sweetalert2';
import { grid } from 'ldrs';
import { useBarcodeContext } from '../../../../_helper/Barcode/BarcodeProvider';

const SmartLinkList = () => {
  grid.register();

  const { loading, generateSmartLink, fetchSmartLinks, smartLinks } = useBarcodeContext();
  const [inUseFilter, setInUseFilter] = useState(null);
  const [loadingImport, setLoadingImport] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(null);


  useEffect(() => {
    //fetchUPCs();
    fetchSmartLinks();
  }, []);

  const handleFilterUsed = () => {
    setInUseFilter(true);
  };

  const handleFilterNotUsed = () => {
    setInUseFilter(false);
  };

  const clearFilters = () => {
    setInUseFilter(null);
  };




  const handleGenerateLink = async (file) => { 
    try{
      const response = await generateSmartLink(file);
      Swal.fire({
        title: 'Smart Link Generated',
        text: `Smart link created succesfully, you will be notified as soon as your file get delivered and your link is ready.`,
        icon: 'success',
      });

    }catch(error){
      Swal.fire({
        title: 'Error',
        text: 'Failed to generate smart link',
        icon: 'error',
      });
    }
    
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Transfers"
        title="smartlinks"
        mainTitle="Transfers"
        buttons={[
          <Btn
            attrBtn={{
              size: 'sm',
              color: 'primary',
              onClick: () => document.getElementById('file-upload').click(),
            }}
          >
            <i className="fa fa-download me-1"></i>Import
          </Btn>
        ]}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Manage your Links" />
              <CardBody>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <l-grid size="150" speed="1.5" color="red"></l-grid>
                  </div>
                ) : (
                  <SmartlinkTable 
                    smartLinks={smartLinks}
                    handleGenerateLink={handleGenerateLink}
                    fetchSmartLinks={fetchSmartLinks}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SmartLinkList;
