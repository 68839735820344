import React, { Fragment, useCallback, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Btn, UL, LI } from "../../../../AbstractElements";
import Swal from "sweetalert2";


const RealeaseTransfetDatable = ({
  inUseFilter,
  setInUseFilter,
  upcs,
  importData,
  fetchUPCs,
  deleteUPC,
  importProducts
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(upcs);
  const [loading, setLoading] = useState(false);

  
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this barcode?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteUPC(id); 
          Swal.fire({
            title: 'Success',
            text: 'Barcode deleted successfully!',
            icon: 'success',
          });
        } catch (error) {
          console.error('Error deleting UPC:', error);
        }
      }
    });
  };

  const handleImportLogic = async (file) => {
    const confirmed = await Swal.fire({
      title: 'Confirm Import',
      text: 'Are you sure you want to import the file?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, import',
      denyButtonText: "No, cancel",
    });
  
    if (confirmed.isConfirmed) {
      setLoading(true);
  
      try {
        const response = await importProducts(file);
        if (response.success === true) {
          Swal.fire({
            title: 'Success',
            text: 'File imported successfully, you will be notified once your assets get saved!',
            icon: 'success',
          });
        }
      } catch (error) {
        console.error('Error during file import:', error.response.data.missing_artists);
        const errorMessage = `${error.response.data.error} ${error.response.data.missing_artists ? error.response.data.missing_artists.join(', ') : ''}`;
        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
        });
      } finally {
        setLoading(false);
        const fileInput = document.getElementById('product-upload');
        if (fileInput) {
          fileInput.value = '';
          fileInput.disabled = false;
        }
      }
    } else {
      // Handle cancel action if needed
      console.log('Import canceled');
    }
  };
  
  const handleImport = (event) => {
    const file = event.target.files[0];
    handleImportLogic(file);
  };
  
  


  return (
    <Fragment>
      
      <div className="mb-3 mt-2 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <input
            id="product-upload"
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleImport}
          />
        </div>
      </div>

    </Fragment>
  );
};

export default RealeaseTransfetDatable;
