import axios from 'axios';

const baseURL = process.env.REACT_APP_ACCOUNTING_BACKEND_URL; 
console.log("baseURL", baseURL);  
const axiosAccounting = axios.create({
  baseURL: baseURL,
});

axiosAccounting.interceptors.request.use(
  (config) => {
    const token = process.env.REACT_APP_ACCOUNTING_API_KEY;
    console.log("token", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



export default axiosAccounting;