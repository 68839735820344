import React, { Fragment, useState, useContext, useEffect } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P, Image } from "../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from "../Constant";
import { useNavigate } from "react-router-dom";
import CustomizerContext from "../_helper/Customizer";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { Link } from "react-router-dom";
import { useAuth } from "../_helper/Auth/AuthProvider";
import logoWhite from "../assets/images/logo/logo_red.svg";
import logoDark from "../assets/images/logo/logo_white.svg";
import MailModalContent from "./OtherWays/MailModalContent";
import SmsModalContent from "./OtherWays/SmsModalContent";
import PinModalContent from "./OtherWays/PinModalContent";
import ForgetPwd from "./RecoverPwd/ForgotPassword";
import TailChase from "../Components/Loaders/TailChase";
import { ArrowLeft } from "react-feather";

const Signin = ({ selected, logoClassMain }) => {
  const [email, setEmail] = useState("admin@gmail.com");
  const [password, setPassword] = useState("password123");
  const { authenticated, isCompleted, accountSecurity, login, userData } =
    useAuth();
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const [selected2FA, setSelected2FA] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [trusted_device, setTrustedDevice] = useState(false);
  const [show2FAOptions, setShow2FAOptions] = useState(true);

  // const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  //const [name, setName] = useState(localStorage.getItem("Name"));

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { success, error, trusted_device } = await login(email, password);
    setLoading(false);

    setTrustedDevice(trusted_device);

    if (trusted_device || authenticated) {
      setIsLoggedIn(true);
      navigate(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
      return;
    }

    if (success) {
      setIsLoggedIn(true);
      // console.log(userData);
      if (selected2FA === "sms") {
        setShowSmsModal(true);
      } else if (selected2FA === "email") {
        setShowEmailModal(true);
      } else if (selected2FA === "pin") {
        setShowPinModal(true);
      }
      toast.success("Your on the right path");
      navigate(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
    } else {
      console.error(error);
      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        if (message === "Account not activated yet.") {
          SweetAlert.fire({
            title: "Account not activated yet!",
            text: "Account not activated yet. Please activate your account.",
            icon: "error",
          });
        } else if (message === "Email not verified") {
          SweetAlert.fire({
            title: "Email not verified",
            text: "Email not verified. We've sent you a verification email. Please check your inbox.",
            icon: "info",
          });
        } else if (
          message ===
          "Account blocked due to too many unsuccessful login attempts."
        ) {
          toast.error(
            "Account blocked due to too many unsuccessful login attempts. Please contact administrators."
          );
        } else if (
          message ===
          "Email verification already sent. Please check your inbox."
        ) {
          toast.error(
            "We 've already sent you a verification email. Please check your inbox."
          );
        } else {
          toast.error(
            "Wrong credentials. Please check your email and password."
          );
        }
      } else {
        toast.error("An error occurred during login. Please try again later.");
      }
    }
  };

  useEffect(() => {
    if (authenticated && trusted_device) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
    }
  }, [authenticated, trusted_device, navigate, layoutURL]);

  return (
    <Fragment>
      <div className="login-card">
        <div>
          <div>
            <Link
              className={`logo ${logoClassMain ? logoClassMain : ""}`}
              to={process.env.PUBLIC_URL}
            >
              <Image
                attrImage={{
                  className: "img-fluid for-light",
                  src: logoWhite,
                  alt: "looginpage",
                }}
              />
              <Image
                attrImage={{
                  className: "img-fluid for-dark",
                  src: logoDark,
                  alt: "looginpage",
                }}
              />
            </Link>
          </div>

          <div className="login-main">
            {authenticated && !accountSecurity && show2FAOptions ? (
              <div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShow2FAOptions(false)}
                >
                  <ArrowLeft />
                </div>

                <H4>{"Proceed Sign In With"}</H4>
                <P>
                  {
                    "Based on the security configuration of your account. Please choose a way to proceed the login"
                  }
                </P>
                <div className="mb-3 2fa-options d-flex justify-content-center align-items-center mt-4">
                  <button
                    className={`btn btn-primary me-1 ${
                      selected2FA === "sms" ? "active" : ""
                    }`}
                    onClick={() => setSelected2FA("sms")}
                  >
                    SMS
                  </button>
                  <button
                    className={`btn btn-primary me-1 ${
                      selected2FA === "email" ? "active" : ""
                    }`}
                    onClick={() => setSelected2FA("email")}
                  >
                    Email
                  </button>
                  {userData.isPinOTP === 1 && (
                    <button
                      className={`btn btn-primary me-1 ${
                        selected2FA === "pin" ? "active" : ""
                      }`}
                      onClick={() => setSelected2FA("pin")}
                    >
                      PIN Code
                    </button>
                  )}
                </div>

                {selected2FA === "email" && (
                  <MailModalContent
                    toggle={() => setShowEmailModal(false)}
                    layoutURL={layoutURL}
                    email={userData.email}
                  />
                )}

                {selected2FA === "sms" && (
                  <SmsModalContent
                    toggle={() => setShowSmsModal(false)}
                    layoutURL={layoutURL}
                    phone={userData.phonenumber}
                  />
                )}

                {selected2FA === "pin" && (
                  <PinModalContent
                    toggle={() => setShowPinModal(false)}
                    layoutURL={layoutURL}
                  />
                )}
              </div>
            ) : (
              <Form className="theme-form">
                <H4>
                  {selected === "simpleLogin"
                    ? ""
                    : "Sign In With Simple Login"}
                </H4>
                <P>{"Enter your email & password to login"}</P>
                <FormGroup>
                  <Label className="col-form-label">{EmailAddress}</Label>
                  <Input
                    className="form-control"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label className="col-form-label">{Password}</Label>
                  <div className="position-relative">
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </div>
                </FormGroup>
                <div className="position-relative form-group mb-0">
                  <div className="checkbox">
                    <Input id="checkbox1" type="checkbox" />
                    <Label className="text-muted" for="checkbox1">
                      {RememberPassword}
                    </Label>
                  </div>
                  <Link
                    to={`${process.env.PUBLIC_URL}/forget-pwd`}
                    
                    className="link"
                  >
                    Forgot Password
                  </Link>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: `d-block w-100 mt-2 ${
                        loading ? "disabled" : ""
                      }`,
                      onClick: (e) => loginAuth(e),
                    }}
                  >
                    {loading ? <TailChase /> : SignIn}
                  </Btn>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
