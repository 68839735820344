import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller } from "react-hook-form";
import { Form, FormGroup, Input, Label, Row } from "reactstrap";

import Select from "react-select";
import makeAnimated from 'react-select/animated';
export default function Metadata({
  i,
  artists,
  genres,
  handleNextSecondStep,
  control,
  errors,
  clearErrors,
  // selectedAsset,
  fetchedProduct,
  setValue,
}) {

useEffect(() => {
  if (fetchedProduct) {
    const selectedFromDB = (fetchedProduct.other_artists || []).map(artist => ({
      value: artist.id,
      label: artist.name,
      selected: artist.Selected === 1 
    }));

    let myselect = [];
    if (Array.isArray(fetchedProduct.others_artists) && fetchedProduct.others_artists.length > 0) {
      myselect = fetchedProduct.others_artists.map(artist => ({
        value: artist.value,
        label: artist.label,
        selected: false
      }));
    }

    const selectedArtists = selectedFromDB.filter(artist => artist.selected);
    const notSelectedArtists = selectedFromDB.filter(artist => !artist.selected);

    const combinedArtists = [...selectedArtists, ...myselect];
    const uniqueArtists = [...selectedArtists, ...notSelectedArtists];

    setSelectedArtists(combinedArtists);
    setOtherArtists(uniqueArtists);

    // // ? Flushsync error
    // setValue("others_artists", combinedArtists.map(({ value, label }) => ({ value, label })));
    // setValue(`assetId_${i}`, selectedAsset?.id || "");
    // setValue(`title_${i}`, selectedAsset?.title || "");

  }
}, [fetchedProduct]);


  // console.log("Metadata -> selectedAsset", selectedAsset);
  // console.log("Metadata -> fetchedProduct", fetchedProduct);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [otherArtists, setOtherArtists] = useState([]);

  // console.log("other",otherArtists);
  const animatedComponents = makeAnimated();
  return (
    <Form
      className="theme-form mt-3"
      onSubmit={handleNextSecondStep}
    >
      <div className="row">
        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Title</Label>

          <Controller
            name={`assetId_${i}`}
            control={control}
            // defaultValue={selectedAsset?.id || ""}
            /* rules={{ required: "Title is required" }} */
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="hidden"
                placeholder="Asset"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`assetId_${i}`);
                }}
              />
            )}
          />
          <Controller
            name={`title_${i}`}
            control={control}
            // defaultValue={selectedAsset?.title || ""}
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="text"
                placeholder="Title"
                // onChange={(e) => {
                //   field.onChange(e);
                //   clearErrors(`title_${i}`);
                // }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`title_${i}`] && errors[`title_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-2">
          <Label className="col-form-label">Artist</Label>
          <Controller
            name={`artist_${i}`}
            control={control}
            // defaultValue={fetchedProduct?.artist || ""}
            /* rules={{ required: true }} */
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                // disabled={fetchedProduct?.status !== "Draft"}
                placeholder="Select Artist"
                className="form-control "
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors("artist");
                }}
              >
                <option key="artist-option" value="">
                  Select Artist...
                </option>
                {artists.map((artist) => (
                  <option key={artist.id_user} value={artist.id_user}>
                    {artist.name}
                  </option>
                ))}
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
                {errors && errors.artist && "Artist is required"}
              </span> */}
        </FormGroup>

        {/*  <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">ISRC</Label>
          <Controller
            name={`ISRC_${i}`}
            control={control}
            defaultValue=""
            rules={{ required: "ISRC is required" }}
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="text"
                placeholder="TN-A0E-24-00001"
              />
            )}
          />
          <span style={{ color: "red" }}>
            {errors[`ISRC_${i}`] && errors[`ISRC_${i}`].message}
          </span>
        </FormGroup> */}

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Copyright Year</Label>
          <Controller
            name={`copyrightYear_${i}`}
            control={control}
            // defaultValue={selectedAsset?.copy_right_year || ""}
            /* rules={{ required: "Copyright Year is required" }} */
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="number"
                placeholder="Copyright Year"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`copyrightYear_${i}`);
                }}
              />
            )}
          />
          {/*  <span style={{ color: "red" }}>
            {errors[`copyrightYear_${i}`] &&
              errors[`copyrightYear_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-3" >
          <Label className="col-form-label" >Class</Label>
          <Controller
            name={`genres_${i}`}
            control={control}
            // defaultValue={fetchedProduct?.genre?.id_genre || ""}
            /* rules={{ required: true }} */
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                // disabled={fetchedProduct?.status !== "Draft"}
                placeholder="Select genres"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors("genres");
                }}
              >
                <option value="">Select...</option>
                {genres.map((genre) => (
                  <option key={genre.id_genre} value={genre.id_genre}>
                    {genre.genre}
                  </option>
                ))}
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
                  {errors && errors.genres && "Genre is required"}
                </span> */}
        </FormGroup>

       
      </div>

      <div className="row">
        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Asset Rights Claim</Label>
          <Controller
            name={`asset_rights_claim_${i}`}
            control={control}
            // defaultValue={selectedAsset?.asset_rights_claim || ""}
            /* rules={{ required: "Asset Rights Claim is required" }} */
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                placeholder="Asset Rights Claim"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`asset_rights_claim_${i}`);
                }}
              />
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`asset_rights_claim_${i}`] &&
              errors[`asset_rights_claim_${i}`].message}
          </span> */}
        </FormGroup>

        {/*         <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Status</Label>
          <Controller
            name={`status_${i}`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                placeholder="Select Status"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`status_${i}`);
                }}
              >
                <option value="">Select...</option>
                <option value="Approved">Approved</option>
                <option value="Delivered">Delivered</option>
                <option value="Draft">Draft</option>
                <option value="Takedown">Takedown</option>
              </Input>
            )}
          />
          <span style={{ color: "red" }}>
            {errors && errors[`status_${i}`] && errors[`status_${i}`].message}
          </span>
        </FormGroup> */}

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Explicit Content</Label>
          <Controller
            name={`explicit_content_${i}`}
            control={control}
            // defaultValue={selectedAsset?.explicit_content || ""}
            /* rules={{ required: "Explicit Content is required" }} */
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                placeholder="Explicit Content "
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`explicit_content_${i}`);
                }}
              >
                <option value="">Select...</option>
                <option value="Explicit">Explicit</option>
                <option value="No Explicit">No Explicit</option>
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`explicit_content_${i}`] &&
              errors[`explicit_content_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Type</Label>
          <Controller
            name={`type_${i}`}
            control={control}
            // defaultValue={selectedAsset?.type || ""}
            /* rules={{ required: "Type is required" }} */
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                placeholder="Select Type"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`type_${i}`);
                }}
              >
                <option value="None">Select...</option>
                <option value="Album">Album</option>
                <option value="Single">Single</option>
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`type_${i}`] && errors[`type_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-2">
          <Label className="col-form-label">Tags</Label>
          <Controller
            name={`tags_${i}`}
            control={control}
            /* rules={{ required: "Tags are required" }} */
            render={({ field }) => (
              <Typeahead
                {...field}
                id={`tags_${i}`}
                allowNew
                multiple
                // selected={selectedAsset?.tags || []}
                newSelectionPrefix="Add a new item: "
                options={[]}
                onChange={(selected) => {
                  field.onChange(selected);
                  clearErrors(`tags_${i}`);
                }}
                placeholder="Type a Tag .."
              />
            )}
          />
          {/*  <span style={{ color: "red" }}>
            {errors[`tags_${i}`] && errors[`tags_${i}`].message}
          </span> */}
        </FormGroup>
      </div>

      <Row>
      <FormGroup className="col-sm-6 mb-3">
            <Label>Other Artists</Label>
            {fetchedProduct?.status !== "Takedown" ? (
               <Controller
               name={`others_artists_${i}`}
               control={control} 
               defaultValue={[]} 
               /* rules={{ required: true }}  */
               render={({ field }) => (
              <Select 
                {...field}
                isMulti={true}
                className="basic-multi-select from-control"
                classNamePrefix="select"
                isLoading={false}
                // isDisabled={fetchedProduct?.status !== "Draft"}
                components={animatedComponents}
                closeMenuOnSelect={false}
                options={[
                  // Map artists to label-value pairs
                  ...artists.map(artist => ({
                    value: artist.id_user,
                    label: artist.name
                  })),
                  // Map otherArtists to label-value pairs
                  ...otherArtists.map(artist => ({
                    value: artist.value,
                    label: artist.label
                  }))
                ]}
                value={selectedArtists}
                onChange={selectedOptions => {
                  // Map selected options to their values
                  const selectedArtistsIds = selectedOptions.map(({ value, label }) => ({ value, label }));
                  setSelectedArtists(selectedOptions);
                  setValue("others_artists", selectedArtistsIds);
                  // Update otherArtists state
                  setOtherArtists(selectedOptions.filter(option => !artists.some(artist => artist.id_user === option.value)));
                }}
                styles={{
                  multiValue: (styles, { data }) => ({
                    ...styles,
                    backgroundColor: '#F9AFAF', 
                    color: '#7F3333',
                  }),
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: '#7F3333', 
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: '#7F3333',
                    ':hover': {
                      backgroundColor: '#FF5E6C', 
                      color: 'white',
                    },
                  }),
                   control: (styles, { isFocused, menuIsOpen }) => ({
                    ...styles,
                    borderColor: isFocused ? '#F9AFAF' : '#ced4da', 
                    '&:hover': {
                      borderColor: '#F9AFAF', 
                    },
                    ...(menuIsOpen && { borderColor: '#F9AFAF' }), 
                  }), 
                  menu: (styles) => ({
                    ...styles,
                    backgroundColor: '#F9AFAF', 
                  }),
                   option: (styles, { isFocused, isSelected }) => ({
                    ...styles,
                    backgroundColor: isSelected ? '#F9AFAF' : isFocused ? '#FF5E6C' : null,
                    color: '#7F3333', 
                  }), 
                  singleValue: (styles) => ({
                    ...styles,
                    color: '#7F3333', 
                  }),
                }}
              />
              )}
            />
            ) : (
              <div>
                {selectedArtists.map((artist, index) => (
                  <span key={index} className="badge bg-primary me-1 mb-1">{artist.label}</span>
                ))}
                {selectedArtists.length === 0 && <p>No artists selected</p>}
              </div>
            )}
            {/* <span style={{ color: "red" }}>
              {errors && errors.other_artists && "Other Artists are required"}
            </span> */}
          </FormGroup>


      </Row>

      <FormGroup className="mb-3">
        <Label className="col-form-label">Copyright Text :</Label>
        <Controller
          name={`copyrightLine_${i}`}
          control={control}
          // defaultValue={selectedAsset?.copy_right_line || ""}
          /* rules={{ required: "Copyright Line is required" }} */
          render={({ field }) => (
            <Input
              {...field}
              className="form-control"
              placeholder="Copyright Line"
              onChange={(e) => {
                field.onChange(e);
                clearErrors(`copyrightLine_${i}`);
              }}
            />
          )}
        />
        {/* <span style={{ color: "red" }}>
          {errors[`copyrightLine_${i}`] && errors[`copyrightLine_${i}`].message}
        </span> */}
      </FormGroup>

      {/* <Label className="col-form-label">Other Artists</Label>
                       <Typeahead
                         id="multiple-typeahead"
                         clearButton
                         defaultSelected={options.slice(0, 5)}
                         labelKey={"name"}
                         options={options}
                         placeholder="Choose an artist..."
                   /> */}
      <div className="mb-2"></div>
    </Form>
  );
}
