import React, { createContext, useContext, useState } from 'react';
import ReleaseService from '../../Services/release/ReleaseService'; 

const ValidationContext = createContext();

export const useValidation = () => useContext(ValidationContext);

export const ValidationProvider = ({ children }) => {
  const [validationRequired, setValidationRequired] = useState(false);

  const setValidationFlag = async (value) => {
    if (value) {
      const isValid = await ReleaseService.validateProduct(); 
      setValidationRequired(isValid);
    } else {
      setValidationRequired(false);
    }
  };

  return (
    <ValidationContext.Provider value={{ validationRequired, setValidationFlag }}>
      {children}
    </ValidationContext.Provider>
  );
};
