import React, { useState } from "react";
import { Container, Form, FormGroup, Input, Label, Row, Col } from "reactstrap";
import { Btn, P } from "../../AbstractElements";
import customeAxios from "../../api/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../_helper/Auth/AuthProvider";
import TailChase from "../../Components/Loaders/TailChase";
const MailModalContent = ({ layoutURL, email }) => {
  const [codeSent, setCodeSent] = useState(false);
  const [emailValue, setEmailValue] = useState(email);
  const [enteredCode, setEnteredCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setAuthenticated, setAccountSecurity } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!codeSent) {
        const response = await customeAxios.post("/send-otp", {
          email: emailValue,
        });
        if (response.status === 200) {
          setCodeSent(true);
          toast.success("OTP sent successfully, Please check your email");
        } else {
          toast.error(response.data.message || "Something went wrong");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("User not found in our records");
      } else {
        toast.error("Error sending or verifying code. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const verificationResponse = await customeAxios.post("/verifyOTP", {
        email: emailValue,
        code: enteredCode,
      });

      if (verificationResponse.status === 200) {
        const { token } = verificationResponse.data;
        //localStorage.setItem("authToken", token);
        setAuthenticated(true);
        setAccountSecurity(true);
        toast.success("Code verification successful");
        navigate(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
      } else {
        toast.error(verificationResponse.data.message || "Invalid code");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("Code submitted is incorrect");
      } else {
        toast.error("Error sending or verifying code. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col className="p-0">
            <P>
              {codeSent
                ? "Please enter the code you received in your email account."
                : "This is a security layer to prevent unauthorized accees."}
            </P>
            {codeSent ? (
              <Form onSubmit={handleVerifyOTP}>
                <FormGroup className="position-relative mb-3">
                  <Label className="col-form-label">Enter the Code</Label>
                  <div className="position-relative">
                    <Input
                      className="form-control"
                      type="text"
                      name="code"
                      value={enteredCode}
                      onChange={(e) => setEnteredCode(e.target.value)}
                      required
                      placeholder="Code"
                    />
                  </div>
                </FormGroup>
                <Btn
                  attrBtn={{
                    className: "d-block w-100",
                    color: "primary",
                    type: "submit",
                  }}
                >
                  {loading ? <TailChase /> : "Submit"}
                </Btn>
              </Form>
            ) : (
              <Form className="theme-form login-form" onSubmit={handleSubmit}>
                <FormGroup className="position-relative mb-3">
                  <Label className="col-form-label">
                    {"You will receive the code at"}
                  </Label>
                  <div className="position-relative">
                    <Input
                      className="form-control"
                      type="text"
                      name="email"
                      value={emailValue}
                      readOnly
                      required
                      placeholder={"Email"}
                    />
                  </div>
                </FormGroup>
                <Btn
                  attrBtn={{
                    className: "d-block w-100",
                    color: "primary",
                    type: "submit",
                  }}
                >
                  {loading ? <TailChase /> : "Send Code"}
                </Btn>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MailModalContent;
