import React from 'react'
import DataTable from 'react-data-table-component';
import { dummytabledata } from '../../../../../Data/Table/Defaultdata';
import { H6, H5 } from '../../../../../AbstractElements';
import { Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';
function VideosByViewsTable() {
  const tableColumns = [
    // {
    //   name: 'ID',
    //   selector: row => row['id'],
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Artist ID',
    //   selector: row => row['artist_id'],
    //   sortable: true,
    //   center: true,
    // },
    {
      name: 'Title',
      selector: row => row['title'],
      sortable: true,
      center: true,
    //     style: {
    //   maxWidth: '150px', // adjust as needed
    //   overflow: 'auto',
    //   whiteSpace: 'nowrap',
    //   textOverflow: 'ellipsis',
    // },
    },
    {
      name: 'Views',
      selector: row => row['views'],
      sortable: true,
      center: true,
    },
    {
      name: 'Likes',
      selector: row => row['likes'],
      sortable: true,
      center: true,
    },
    {
      name: 'Thumbnail',
      selector: row => row['thumbnail'],
      sortable: true,
      center: true,
    },
    {
      name: 'Estimated Revenue',
      selector: row => row['estimated_revenue'],
      sortable: true,
      center: true,
    },
    {
      name: 'Comments',
      selector: row => row['comments'],
      sortable: true,
      center: true,
    },
    {
      name: 'Visibility',
      selector: row => row['visibility'],
      sortable: true,
      center: true,
    },
    {
      name: 'Month',
      selector: row => row['month'],
      sortable: true,
      center: true,
    },
    {
      name: 'Video Youtube ID',
      selector: row => row['video_youtube_id'],
      sortable: true,
      center: true,
    },
    {
      name: 'Created At',
      selector: row => moment(row['created_at']).format('DD/MM/YYYY'),
      sortable: true,
      center: true,
    },
    {
      name: 'Updated At',
      selector: row => moment(row['updated_at']).format('DD/MM/YYYY'),
      sortable: true,
      center: true,
    },
  ];
    // {
    //     name: "Actions",
    //     cell: (row) => (
    //       <div className="todo">
    //         <div className="todo-list-wrapper">
    //           <div className="todo-list-container todo-list-footer">
    //             <ul className="simple-list">
    //               <li className="task border-0">
    //                 <div className="task-container">
    //                   <span className="task-action-btn">
    //                     <span
    //                       className="action-box large delete-btn"
    //                       title="View"
    //                     //   onClick={() => handleShowMore(row)}
    //                     >
    //                       <i className="icon">
    //                         <i className="icon-eye"></i>
    //                       </i>
    //                     </span>
    //                     <span
    //                       className="action-box large delete-btn"
    //                       title="Delete"
    //                     //   onClick={() => handleDelete(row.id)}
    //                     >
    //                       <i className="icon">
    //                         <i className="icon-trash"></i>
    //                       </i>
    //                     </span>
    //                   </span>
    //                 </div>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     ),
    //     center: true,
    //   },

    const tableData = [
      {
        id: 1,
        artist_id: 1,
        title: 'Video 1',
        views: 1000,
        likes: 100,
        thumbnail: 'thumbnail1.jpg',
        estimated_revenue: 100.00,
        comments: 10,
        visibility: 1,
        month: 'January',
        video_youtube_id: 'video1',
        created_at: '2022-01-01 00:00:00',
        updated_at: '2022-01-01 00:00:00',
      },
      // more data...
    ];

  return (
    <>
   <Card>
        <CardHeader>
            <H6>{'Table By Views '}</H6>
        </CardHeader>
        <CardBody>
          {/* //TODO: Scroll not working properly */ }
            <div className="table-responsive support-table">
                <DataTable
                    columns={tableColumns}
                    data={tableData}
                    striped={true}
                    center={true}
                    pagination
                />
            </div>
        </CardBody>
    </Card>

    </>
  )
}

export default VideosByViewsTable