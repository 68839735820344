import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';
import DataTable from 'react-data-table-component';
import Chart from 'react-apexcharts';
import { H6 , Alerts , P , H4} from '../../../../AbstractElements';
import AnalyticsService from '../../../../Services/analytics/AnalyticsService';
import GridLoader from '../../../Loaders/GridLoader';
import { generateDeviceData, generateGenderData, generateAgeGroupData, generateSourceChartData } from './chartsData';
import { formatSourceData } from '../../../../_helper/Analytics/utils';



const sourceColumns = [
  { name: 'Source', selector: row => row.source },
  { name: 'Plays', selector: row => row.plays },
  { name: '%', selector: row => row.percentage },
];



const territoryColumns = [
  { name: 'Territory', selector: row => row.title },
  { name: 'Streams', selector: row => row.streams },
  { name: 'Streams Change', 
  selector: row => 
    <div>
      {row.streams_change}{' '}
      {row.changeDirection === 'up' ? (
        <span className="text-success" style={{ fontSize: '20px' }}>&#8593;</span>
      ) : row.changeDirection === 'down' ? (
        <span className="text-danger" style={{ fontSize: '20px' }}>&#8595;</span>
      ) : null}
    </div>, 
},
];


const AppleMusic = ({selectedTimeRange}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [ageGroup, setAgeGroupData] = useState([]);
  const [gender, setGenderData] = useState([]);
  const [device, setDeviceData] = useState([]);
  const [sourcechart, setSourceChart] = useState([]);
  const [streambyterritory, setStreamByTerritory] = useState([]);
  const [error, setError] = useState(null);



 const getAppleMusic = async () => {
  setIsLoading(true);
  try {
    setTimeout(async () => {
      const response = await AnalyticsService.getAppleMusicData(selectedTimeRange);
      if (response && response.age_group_applemusic && response.streaming_device_applemusic && response.source_for_applemusic && response.streams_by_territory_applemusic) {
        setAgeGroupData(response.age_group_applemusic);
        setDeviceData(response.streaming_device_applemusic);
        const formattedSourceChart = formatSourceData(response.source_for_applemusic);
        setSourceChart(formattedSourceChart);
        const formattedStreamByTerritory = formatSourceData(response.streams_by_territory_applemusic);
        setStreamByTerritory(formattedStreamByTerritory);
        setError(null);
      } else {
      setError('Incomplete data received from the server.');
      }
      setIsLoading(false);

    }, 2000);
  } catch (error) {
    console.error('Error fetching Apple Music data:', error);
    setError('Error fetching data. Please try again later.');
    setIsLoading(false);
  }
};
  


  useEffect(() => {
    getAppleMusic(selectedTimeRange);
  }, [selectedTimeRange]); 




  const ageGroupData = generateAgeGroupData(ageGroup);
  const genderData = generateGenderData(gender);
  const deviceData = generateDeviceData(device);
  const sourceChartData = generateSourceChartData(sourcechart);


  const paginationOptions = {
    rowsPerPage: 10, // Display 10 rows per page
    rowsPerPageText: 'Rows per page:', // Text for the rows per page dropdown
    noRowsPerPage: false, // Show/hide rows per page dropdown
    noRowsPerPageOptions: false, // Hide rows per page dropdown options
    paginationRowsPerPageOptions: [10, 20, 30], // Custom rows per page options
    paginationText: '1-{totalRows} of {totalRows}', // Text for pagination info
  };

  return (
    <Container>

{isLoading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
    <GridLoader />
  </div>
) : error ? (
  <div className="text-center">
    <Alerts attrAlert={{ color: 'primary ' }} >
      <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
      <P>An error occurred while fetching data. Please try again later or contact support for assistance.</P>
      <hr />
      <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
    </Alerts>
  </div>
) : (!ageGroup.length && !gender.length && !device.length && !sourcechart.length && !streambyterritory.length) ? (
  <div className="text-center">
    <Alerts attrAlert={{ color: 'warning ' }} >
      <H4 attrH4={{ className: 'alert-heading' }} >No Data</H4>
      <P>No data available at the moment. Please try again later.</P>
    </Alerts>
  </div>
) : (
    <>
     <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <h6>Source For Apple Music</h6>
              </CardTitle>
            </CardHeader>
            <CardBody className="d-flex">
            {sourcechart.length > 0 ? (
              <>
                <div className="col-md-7">
                  <DataTable columns={sourceColumns} data={sourcechart} />
                </div>
                <div className='col-md-5 mt-5 overflow-auto'>
                  <Chart options={sourceChartData.options} series={sourceChartData.series} type="pie" width={500} />
                </div>
              </>
            ) : (
              <div className="text-center">Not Available</div>
            )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle>
                <H6>Apple Music Streams by Territory</H6>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <DataTable columns={territoryColumns} data={streambyterritory} pagination={true} paginationPerPage={10} {...paginationOptions}/>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        {['AGEGROUP', 'GENDER', 'DEVICE'].map((title, index) => (
          <Col md="4" key={index}>
            <Card>
              <CardTitle className="border-bottom p-3 mb-0">
                <i className="mdi mdi-chart-pie me-2"></i>
                <H6>{title === 'FREE_PREMIUM' ? 'Free / Premium' : title}</H6>
              </CardTitle>
              <CardBody>
                <div id={`piechart-${title}`}>
                  {/* Display Pie Chart for each category with respective data */}
                  {title === 'AGEGROUP' && ageGroupData.series.length ? (
                    <Chart options={ageGroupData.options} series={ageGroupData.series} type="pie" />
                  )  : title === 'GENDER' && genderData.series.length ? (
                    <Chart options={genderData.options} series={genderData.series} type="pie" />
                  ) : title === 'DEVICE' && deviceData.series.length ? (
                    <Chart options={deviceData.options} series={deviceData.series} type="pie" />
                  ) : (
                    <div className="text-center">Not available yet</div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>


    </>
  )}
    </Container>
  );
};

export default AppleMusic;
