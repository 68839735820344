import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  ButtonGroup,
} from "reactstrap";
import { H5, Btn, Badges } from "../../../../../AbstractElements";

import { grid } from "ldrs";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import GridLoader from "../../../../Loaders/GridLoader";
import { Controller } from "react-hook-form";

export default function MetadataInput({
  control,
  errors,
  loading,
  artists,
  genres,
  setValue,
  clearErrors,
  fetchedProduct,
  onUpdateMetada,
}) {
  const animatedComponents = makeAnimated();
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [otherArtists, setOtherArtists] = useState([]);

  useEffect(() => {
    if (fetchedProduct) {
      const selectedFromDB = (fetchedProduct.other_artists || []).map(
        (artist) => ({
          value: artist.id,
          label: artist.name,
          selected: artist.Selected === 1,
        })
      );

      let myselect = [];
      if (
        Array.isArray(fetchedProduct.others_artists) &&
        fetchedProduct.others_artists.length > 0
      ) {
        myselect = fetchedProduct.others_artists.map((artist) => ({
          value: artist.value,
          label: artist.label,
          selected: false,
        }));
      }

      const selectedArtists = selectedFromDB.filter(
        (artist) => artist.selected
      );
      const notSelectedArtists = selectedFromDB.filter(
        (artist) => !artist.selected
      );

      const combinedArtists = [...selectedArtists, ...myselect];
      const uniqueArtists = [...selectedArtists, ...notSelectedArtists];

      setSelectedArtists(combinedArtists);
      setOtherArtists(uniqueArtists);
    }
  }, [fetchedProduct]);

  // console.log("fetchedProduct", fetchedProduct);

  const getStatusColorClass = (status) => {
    switch (status) {
      case "Delivered":
        return "ribbon-info";
      case "Approved":
        return "ribbon-success";
      case "Draft":
        return "ribbon-warning";
      case "Takedown":
        return "ribbon-primary";
      case "Processing":
        return "ribbon-info";
      default:
        return "";
    }
  };

  return (
    <>
      {loading && <GridLoader size="150" speed="1.5" color="red" />}
      <Card>
        <CardHeader className="d-flex justify-content-between align-items-center">
          <H5>{" MetaData"}</H5>
          <span
            className={`ribbon ${getStatusColorClass(
              fetchedProduct.status
            )} text-white ribbon-right`}
          >
            {fetchedProduct.status}
          </span>
        </CardHeader>
        <CardBody>
          <Form className="theme-form" onSubmit={onUpdateMetada}>
            <div className="row">
              {
                <FormGroup className="col-sm-3 mb-2">
                  <Label className="col-form-label">Title</Label>
                  <Controller
                    name="title"
                    defaultValue={fetchedProduct?.title || ""}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={fetchedProduct.status !== "Draft"}
                        className="form-control "
                        type="text"
                        placeholder="Title"
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("title");
                        }}
                      />
                    )}
                  />
                  {/* <span style={{ color: "red" }}>
              {errors && errors.title && "Title is required"}
              </span> */}
                </FormGroup>
              }

              <FormGroup className="col-sm-3 mb-2">
                <Label className="col-form-label">Artist :</Label>
                <Controller
                  name="artist"
                  control={control}
                  defaultValue={fetchedProduct?.artist || ""}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="select"
                      disabled={fetchedProduct?.status !== "Draft"}
                      placeholder="Select Artist"
                      className="form-control "
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("artist");
                      }}
                    >
                      <option key="artist-option" value="">
                        Select Artist...
                      </option>
                      {artists.map((artist) => (
                        <option key={artist.id_user} value={artist.id_user}>
                          {artist.name}
                        </option>
                      ))}
                    </Input>
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.artist && "Artist is required"}
              </span> */}
              </FormGroup>

              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Copyright Year : </Label>
                <Controller
                  name="copyrightYear"
                  control={control}
                  defaultValue={fetchedProduct?.copyright_year || ""}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <input
                      {...field}
                      disabled={fetchedProduct?.status !== "Draft"}
                      className="form-control "
                      type="number"
                      placeholder="Copyright Year"
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("copyrightYear");
                      }}
                    />
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.copyrightYear && "Copyright Year is required"}
              </span> */}
              </FormGroup>

              <FormGroup className="col-sm-3 mb-2">
                <Label className="col-form-label">Catalog Number :</Label>
                <Controller
                  name="catalogNumber"
                  control={control}
                  defaultValue={fetchedProduct?.catalog_number || ""}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <input
                      {...field}
                      disabled={fetchedProduct?.status !== "Draft"}
                      className="form-control "
                      type="text"
                      placeholder="Catalog Number"
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("catalogNumber");
                      }}
                    />
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.catalogNumber && "Catalog Number is required"}
              </span> */}
              </FormGroup>
            </div>
            <Row>
              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Barcode :</Label>
                <Controller
                  name="barcode"
                  control={control}
                  defaultValue={fetchedProduct?.barcode || ""}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <input
                      {...field}
                      className="form-control"
                      disabled={fetchedProduct?.status !== "Draft"}
                      type="text"
                      placeholder="Barcode"
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("barcode");
                      }}
                    />
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.barcode && "Barcode is required"}
              </span> */}
              </FormGroup>

              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Type</Label>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={fetchedProduct?.type ?? 0}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="select"
                      disabled={fetchedProduct?.status !== "Draft"}
                      placeholder="Select Type"
                      className="form-control"
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("type");
                      }}
                    >
                      <option value="">Select...</option>
                      <option value={0}>Album</option>
                      <option value={1}>Single</option>
                    </Input>
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.type && "Type is required"}
              </span> */}
              </FormGroup>

              {/* <FormGroup className="col-sm-3 mb-3">
              <Label className="col-form-label">Status</Label>
              <Controller
                name="status"
                control={control}
                defaultValue={fetchedProduct?.status || ''} 
                
                render={({ field }) => (
                  <Input
                    {...field}
                    disabled={fetchedProduct.status != "Draft"}
                    type="select"
                    placeholder="Select Status"
                    className="form-control "
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors("status");
                    }}
                  >
                    <option value="">Select...</option>
                    <option value="Approved">Approved</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Draft">Draft</option>
                    <option value="Takedown">Takedown</option>
                  </Input>
                )}
              />
              <span style={{ color: "red" }}>
                {errors && errors.status && "Status is required"}
              </span>
            </FormGroup> */}

              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Explicit Content</Label>
                <Controller
                  name="explicitContent"
                  control={control}
                  defaultValue={fetchedProduct?.explicit_content || ""}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="select"
                      disabled={fetchedProduct?.status !== "Draft"}
                      placeholder="Select explicit content"
                      className="form-control "
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("explicitContent");
                      }}
                    >
                      <option value="">Select...</option>
                      <option value="Explicit">Explicit</option>
                      <option value="No Explicit">No Explicit</option>
                    </Input>
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.explicitContent && "Explicit Content is required"}
              </span> */}
              </FormGroup>

              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Class</Label>
                <Controller
                  name="genres"
                  control={control}
                  defaultValue={fetchedProduct?.genre?.id_genre || ""}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="select"
                      disabled={fetchedProduct?.status !== "Draft"}
                      placeholder="Select genres"
                      className="form-control"
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("genres");
                      }}
                    >
                      <option value="">Select...</option>
                      {genres.map((genre) => (
                        <option key={genre.id_genre} value={genre.id_genre}>
                          {genre.genre}
                        </option>
                      ))}
                    </Input>
                  )}
                />
                {/* <span style={{ color: "red" }}>
                  {errors && errors.genres && "Genre is required"}
                </span> */}
              </FormGroup>
            </Row>
            <div className="mb-2" style={{ display: "flex" }}>
              {/* <FormGroup className="col-sm-3 mb-3 me-1">
              <Label>Release Date</Label>
              <div className="input-group">
                <Controller
                  name="releaseDate"
                  control={control}
                  rules={{ required: true }} 
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      className="form-control "
                      disabled={fetchedProduct.status != "Draft"}
                      placeholderText="mm/dd/yyyy"
                      //selected={fetchedProduct.release_date ? new Date(fetchedProduct.release_date) : null}
                      onChange={(date) => {
                        field.onChange(date);
                        
                      }}
                    />
                  )}
                />
              </div>
              <span style={{ color: "red" }}>
                {errors && errors.releaseDate && "Release date is required"}
              </span>
            </FormGroup> */}
            </div>
            <Row>
              <FormGroup className="col-sm-12 mb-3">
                <Label>Other Artists</Label>
                {fetchedProduct?.status !== "Takedown" ? (
                  <Controller
                    name="other_artists"
                    control={control}
                    defaultValue={[]}
                    /* rules={{ required: true }}  */
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti={true}
                        className="basic-multi-select from-control"
                        classNamePrefix="select"
                        isLoading={false}
                        isDisabled={fetchedProduct?.status !== "Draft"}
                        components={animatedComponents}
                        closeMenuOnSelect={false}
                        options={[
                          ...artists.map((artist) => ({
                            value: artist.id_user,
                            label: artist.name,
                          })),
                          // Map otherArtists to label-value pairs
                          ...otherArtists.map((artist) => ({
                            value: artist.value,
                            label: artist.label,
                          })),
                        ]}
                        value={selectedArtists}
                        onChange={(selectedOptions) => {
                          // Map selected options to their values
                          const selectedArtistsIds = selectedOptions.map(
                            ({ value, label }) => ({ value, label })
                          );
                          setSelectedArtists(selectedOptions);
                          console.log(selectedArtistsIds);
                          setValue("other_artists", selectedArtistsIds);
                          //clearErrors("other_artists");
                        }}
                        styles={{
                          multiValue: (styles, { data }) => ({
                            ...styles,
                            backgroundColor: "#F9AFAF",
                            color: "#7F3333",
                          }),
                          multiValueLabel: (styles, { data }) => ({
                            ...styles,
                            color: "#7F3333",
                          }),
                          multiValueRemove: (styles, { data }) => ({
                            ...styles,
                            color: "#7F3333",
                            ":hover": {
                              backgroundColor: "#FF5E6C",
                              color: "white",
                            },
                          }),
                          control: (styles, { isFocused, menuIsOpen }) => ({
                            ...styles,
                            borderColor: isFocused ? "#F9AFAF" : "#ced4da",
                            "&:hover": {
                              borderColor: "#F9AFAF",
                            },
                            ...(menuIsOpen && { borderColor: "#F9AFAF" }),
                          }),
                          menu: (styles) => ({
                            ...styles,
                            backgroundColor: "#F9AFAF",
                          }),
                          option: (styles, { isFocused, isSelected }) => ({
                            ...styles,
                            backgroundColor: isSelected
                              ? "#F9AFAF"
                              : isFocused
                              ? "#FF5E6C"
                              : null,
                            color: "#7F3333",
                          }),
                          singleValue: (styles) => ({
                            ...styles,
                            color: "#7F3333",
                          }),
                        }}
                      />
                    )}
                  />
                ) : (
                  <div>
                    {selectedArtists.map((artist, index) => (
                      <span key={index} className="badge bg-primary me-1 mb-1">
                        {artist.label}
                      </span>
                    ))}
                    {selectedArtists.length === 0 && <p>No artists selected</p>}
                  </div>
                )}
                {/* <span style={{ color: "red" }}>
              {errors && errors.other_artists && "Other Artists are required"}
            </span> */}
              </FormGroup>

              <FormGroup className="mt-2">
                <Label className="col-form-label">Copyright Text :</Label>
                <Controller
                  name="copyrightText"
                  control={control}
                  defaultValue={fetchedProduct?.copyright_text || ""}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <textarea
                      {...field}
                      disabled={fetchedProduct?.status !== "Draft"}
                      className="form-control "
                      placeholder="Copyright Text"
                      rows="1"
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("copyrightText");
                      }}
                    />
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.copyrightText && "Copyright Text is required"}
              </span> */}
              </FormGroup>

              <FormGroup className="col-sm-12 mr-1 mb-3">
                <Label className="col-form-label">Tags</Label>
                <Controller
                  name="tags"
                  defaultValue={fetchedProduct?.tags || ["love"]}
                  control={control}
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <Typeahead
                      {...field}
                      id="custom-typeahead"
                      allowNew
                      disabled={fetchedProduct?.status !== "Draft"}
                      multiple
                      placeholder="Tags..."
                      newSelectionPrefix="Add a new item: "
                      options={["HIPHOP", "RAP", "JAZZ", "POP"].map(
                        (label) => ({ label })
                      )} // Ensure each option has a label property
                      onChange={(selected) => {
                        // Extracting labels from selected options
                        const selectedLabels = selected.map(
                          (option) => option.label
                        );
                        // Updating form value
                        setValue("tags", selectedLabels);
                        // Clearing errors
                        clearErrors("tags");
                      }}
                      selected={field.value.map((label) => ({ label }))} // Ensure each selected option has a label property
                    />
                  )}
                />
                {/* <span style={{ color: "red" }}>
    {errors && errors.tags && "Tags are required"}
  </span> */}
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="mt-2">
                <Label className="col-form-label">Notes</Label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  /* rules={{ required: true }} */
                  render={({ field }) => (
                    <textarea
                      {...field}
                      className="form-control "
                      placeholder="Some Details ..."
                      disabled={fetchedProduct?.status !== "Draft"}
                      rows="3"
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("description");
                      }}
                    />
                  )}
                />
                {/* <span style={{ color: "red" }}>
                {errors && errors.description && "Some Details is required"}
              </span> */}
              </FormGroup>
            </Row>

            {/* <div className="mb-2">
          <FormGroup>
            <Label className="col-form-label">Other Artists</Label>
            <Controller
              name="otherArtists"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={Selectoptions}
                  className="js-example-basic-single col-sm-12"
                  placeholder="Featuring"
                  isMulti
                />
              )}
            />
          </FormGroup>
              </div> */}
          </Form>
        </CardBody>
        {/*  <CardFooter className="d-flex justify-content-center">
      <ButtonGroup>
        <Btn
          attrBtn={{
            size: "l",
            color: "primary",
            onClick: onSubmit,
          }}
        >
          Update
        </Btn>
      </ButtonGroup>

      </CardFooter> */}
      </Card>
    </>
  );
}
