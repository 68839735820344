import axios from "../../api/axios";

const SmartLinkService = {


    getAllSmartLinks: async () => {
        try {
        const response = await axios.get("/smart/getAllLinks");
        const  smartLinks  = response.data;
        return { success: true, smartLinks: smartLinks.geniusLinks };
        } catch (error) {
        console.error("Failed to fetch Smart Links", error);
        return { success: false, error };
        }
    },

    getProductByName: async (productName) => {
        try {
          const response = await axios.get(`/smart/getGeniusLink/${productName}`);
          const productData = response.data;
          return { success: true, productData };
        } catch (error) {
          console.error(`Failed to fetch product data for ${productName}`, error);
          return { success: false, error };
        }
      },

     fetchSmartLink: async (param) => {
        try {
          const response = await axios.get(`/smartlink/${param}`);
          if (response.ok) {
            const data = await response.json();
            return { success: true, smartLink: data.smartLink };
            // setSmartLink(data.smartLink);
          }
        } catch (error) {
          console.error('Error fetching smartlink data:', error);
          return { success: false, error };
        }
      },


}
export default SmartLinkService;