export const generateDownloadByDsp = (edata) => {
  const titles = edata.map((item) => item.title); 
    const labels = Object.keys(edata[0]?.weeks || {}); 
    const seriesData = [];

    console.log(titles);
    titles.forEach((title) => {
      const data = labels.map((label) => parseInt(edata.find((item) => item.title === title)?.weeks?.[label] || 0));
      seriesData.push({ name: title, data });
    });
    const predefinedColors = ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#ffa500', '#00ff00', '#0000ff', '#800080', '#ff1493', '#00ffff'];

    const colors = titles.map((title, index) => predefinedColors[index % predefinedColors.length]);

    const options = {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: colors,
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: labels,
      },
      tooltip: {
        x: {
          format: 'dd/MM',
        },
      },
    };

  return {
    series: seriesData,
    options: options,
  };
}

export const generateStreamByTerritory = (edata) => {
const titles = edata.map((item) => item.title); 
const labels = Object.keys(edata[0]?.weeks || {}); 
const seriesData = [];

// console.log(titles, labels);
titles.forEach((title) => {
  const data = labels.map((label) => parseInt(edata.find((item) => item.title === title)?.weeks?.[label] || 0));
  seriesData.push({ name: title, data });
});
const predefinedColors = ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#ffa500', '#00ff00', '#0000ff', '#800080', '#ff1493', '#00ffff'];

const colors = titles.map((title, index) => predefinedColors[index % predefinedColors.length]);

const options = {
  chart: {
    height: 350,
    type: 'line',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: colors,
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    categories: labels,
  },
  tooltip: {
    x: {
      format: 'dd/MM',
    },
  },
};

return {
series: seriesData,
options: options,
};
}

export const generateStreamByAsset = (edata) => {
const titles = edata.map((item) => item.title); 
const labels = Object.keys(edata[0]?.weeks || {}); 
const seriesData = [];

// console.log(titles, labels);
titles.forEach((title) => {
  const data = labels.map((label) => parseInt(edata.find((item) => item.title === title)?.weeks?.[label] || 0));
  seriesData.push({ name: title, data });
});
const predefinedColors = ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#ffa500', '#00ff00', '#0000ff', '#800080', '#ff1493', '#00ffff'];

const colors = titles.map((title, index) => predefinedColors[index % predefinedColors.length]);

const options = {
  chart: {
    height: 350,
    type: 'line',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: colors,
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    categories: labels,
  },
  tooltip: {
    x: {
      format: 'dd/MM',
    },
  },
};

return {
series: seriesData,
options: options,
};
}
