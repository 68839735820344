import React, { Fragment, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { useForm } from "react-hook-form";
import { Stepper, Step } from "react-form-stepper";
import StepThree from "./StepThree";
import { useReleaseContext } from "../../../../_helper/Release/ReleaseProvider";
import moment from "moment";
import { toast } from "react-toastify";
import { useDspContext } from "../../../../_helper/DSP/DspProvider";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const NewRelease = () => {
  // const [loading, setLoading] = useState(false);
  // const [formGroups, setFormGroups] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [productId, setProductId] = useState(null);
  const [selectedDsps, setSelectedDsps] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const { dsps, fetchDsp } = useDspContext();
  const {
    artists,
    saveThirdStepData,
    saveStepData,
    genres,
    fetchArtists,
    countries,
    fetchGenres,
    saveSecondStepData,
    getAllCountries,
    loading: StepsLoading,
  } = useReleaseContext();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    resetField,
    unregister,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [cards, setCards] = useState([0]);
  const addForm = () => {
    setCards((prevCards) => [...prevCards, prevCards.length]);
  };
  //TODO: Reset only Formgroup deleted
  const deleteForm = (index) => {
    setCards((prevCards) => prevCards.filter((card) => card !== index));
      // List all field names in your form
      resetField(`title_${index}`);
      resetField(`assetId_${index}`);
      resetField(`artist_${index}`);
      resetField(`copyrightYear_${index}`);
      resetField(`genres_${index}`);
      resetField(`asset_rights_claim_${index}`);
      resetField(`explicit_content_${index}`);
      resetField(`type_${index}`);
      resetField(`tags_${index}`);
      resetField(`others_artists_${index}`);
      resetField(`copyrightLine_${index}`);
      resetField(`audio_language_${index}`);
      resetField(`asset_rights_claim_${index}`);
      resetField(`volume_${index}`);
      resetField(`preview_text_${index}`);
      resetField(`preview_length_${index}`);
      resetField(`preview_start_${index}`);
      resetField(`metadata_language_${index}`);
      resetField(`lyrics_${index}`);
      resetField(`audio_file_${index}`);


      // reset();
  };

  const handleNext = async () => {
    const isValid = await trigger();
    // If the form is not valid, don't proceed
    if (!isValid) {
      return;
    }
    const formData = getValues();
    const fileInput = document.querySelector('input[type="file"]');
    const selectedFile = fileInput ? fileInput.files[0] : null;
    const releaseDate = moment(formData.releaseDate).format();
    const formDataWithFile = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key.startsWith("other_artists") && Array.isArray(value)) {
        const other_artists = value.map((item) => item.name);
        formDataWithFile.append(key, JSON.stringify(other_artists));
      } else if (key.startsWith("tags") && Array.isArray(value)) {
        const tags = value.map((item) => item.label);
        formDataWithFile.append(key, JSON.stringify(tags));
      } else {
        formDataWithFile.append(key, value);
      }
    });
    formDataWithFile.append("releaseDate", releaseDate);
    try {
      const response = await saveStepData(activeStep, formDataWithFile);
      console.log("Response:", response);
      if (response && response.status === true) {
        // setLoading(false);
        toast.success("Release step 1/3 successfully saved!");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        reset();
        setProductId(response.product);
      } else {
        console.error("Response is undefined or status is not true");
        toast.error("An error occurred while creating your product In Step 1.");
      }
    } catch (error) {
      console.error("Error calling saveStepData:", error);
      toast.error("An error occurred while creating your product In Step 1.");
    }
  };

  const handleNextSecondStep = async () => {
    const isValid = await trigger();

    if (!isValid) {
      return;
    }
    const formData = getValues();
    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== undefined)
    );
    const formDataWithFile = new FormData();
    Object.entries(filteredData).forEach(([key, value]) => {
      const [fieldName,index] = key.split("_");
      if (fieldName === "audio_file") {
        // console.log("value", value);
        formDataWithFile.append(key, value);
      } else {
        if (fieldName.startsWith("tags")) {
          if (Array.isArray(value)) {
            const tags = value.map((item) => item.label);
            formDataWithFile.append(`tags_${index}`, JSON.stringify(tags));
          }
        } else {
          formDataWithFile.append(key, value);
        }
      }
    });
    formDataWithFile.append("productId", productId);

    console.log("formDataWithFile", formData);

    try {
      const response = await saveSecondStepData(formDataWithFile);
      // console.log("Response:", response);

      if (response && response.status === true) {
        // console.log("response", response)
        // setLoading(false);
        toast.success("Release step 2/3 successfully saved!");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        reset();
      } else {
        console.error("Response is undefined or status is not true");
      }
    } catch (error) {
      console.error("Error calling saveStepData:", error);
      toast.error("An error occurred while creating your product In Step 2.");
    }
  };

  const handleStepThreeSubmit = async () => {
    const data = {
      productId: productId,
      selectedDsps: selectedDsps,
      selectedCountries: selectedCountries,
    };
    console.log("StepThree Data", data);
    try {
      const response = await saveThirdStepData(data);
      toast.success("Your product has been created succesfully!");
      navigate(`${process.env.PUBLIC_URL}/app/releases/catalog/:layout`);
      console.log("response", response);
    } catch (error) {
      toast.error("An error occurred while creating your product In Step 3.");
      console.error("Error calling Step3:", error);
    }
  };

  const handleCancel = async () => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "This will delete the product and all associated data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Make an API call to delete the product
      // After successful deletion, refresh the page
      // Example: deleteProduct(productId);
      window.location.reload(); // Refresh the page
    }
  };

  useEffect(() => {
    fetchArtists();
    fetchGenres();
    fetchDsp();
    getAllCountries();
  }, []);

  const handleBack = (event) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    event.preventDefault();
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Releases"
        parent="Releases"
        title="Build Release"
        buttons={
          activeStep === 0
            ? [
                // Buttons for step 1
                <Btn
                className="btn btn-primary"
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    onClick: handleNext,
                  }}
                >
                  <i className="fa fa-arrow-circle-right me-1"></i>NextStep
                </Btn>,
              ]
            : activeStep === 1
            ? [
                // Buttons for step 2 TODO: add Button Group
                <Btn
                className="btn btn-primary"
                  attrBtn={{
                    type: "button",
                    size: "l",
                    color: "primary",
                    // outline: true,
                    onClick: handleCancel,
                  }}
                >
                  <i className="fa fa-stop-circle-o me-1"></i>Cancel
                </Btn>,
                <Btn
                className="btn btn-primary"
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    // outline: true,
                    onClick: handleNextSecondStep,
                  }}
                >
                  <i className="fa fa-arrow-circle-right me-1"></i>NextStep
                </Btn>,
                <Btn
                className="btn btn-primary"
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    // outline: true,
                    onClick: (e) => {
                      addForm();
                    },
                  }}
                >
                  <i className="fa fa-plus-square me-1"></i>Add Asset
                </Btn>,
              ]
            : [
                <Btn
                className="btn btn-primary"
                  attrBtn={{
                    type: "button",
                    size: "l",
                    color: "primary",
                    // outline: true,
                    onClick: handleCancel,
                  }}
                >
                  <i className="fa fa-stop-circle-o me-1"></i>Cancel
                </Btn>,
                <Btn
                className="btn btn-primary"
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    outline: true,
                    type: "submit",
                    //TODO: pass the formStep as props form to refactor: "step-three-form",
                    onClick: handleStepThreeSubmit,
                  }}
                >
                  <i className="fa fa-check-circle-o me-2"></i>Submit
                </Btn>,
              ]
        }
      />
      {/* <Row className="justify-content-center mb-3">
                        <Col xs="auto">
                          <Btn
                            attrBtn={{
                              size: "lg",
                              color: "primary",
                              outline: true,
                              onClick: handleNext,
                            }}
                          >
                            <i className="fa fa-arrow-circle-right me-1"></i>NextStep
                          </Btn>
                        </Col>
                      </Row> */}

      <Container fluid={true}>
        <Stepper activeStep={activeStep} style={{ display: "none" }}>
          <Step label="Step One" />
          <Step label="Step Two" />
          <Step label="Step Three" />
        </Stepper>

        {activeStep === 0 && (
          <StepOne
            register={register}
            loading={StepsLoading}
            errors={errors}
            control={control}
            activeStep={activeStep}
            handleNext={handleSubmit(handleNext)}
            artists={artists}
            genres={genres}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            handleCancel={handleCancel}
          />
        )}
        {activeStep === 1 && (
          <StepTwo
            register={register}
            loading={StepsLoading}
            errors={errors}
            control={control}
            cards={cards}
            deleteForm={deleteForm}
            handleBack={handleBack}
            handleNextSecondStep={handleSubmit(handleNextSecondStep)}
            setValue={setValue}
            getValues={getValues}
            artists={artists}
            reset={reset}
            resetField={resetField}
            unregister={unregister}
            genres={genres}
            handleCancel={handleCancel}
            clearErrors={clearErrors}
          />
        )}
        {activeStep === 2 && (
          <StepThree
            loading={StepsLoading}
            setValue={setValue}
            getValues={getValues}
            dsps={dsps}
            countries={countries}
            handleStepThreeSubmit={handleStepThreeSubmit}
            handleCancel={handleCancel}
            selectedDsps={selectedDsps}
            setSelectedDsps={setSelectedDsps}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default NewRelease;
