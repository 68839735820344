import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, Btn, Badges } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import SmartlinkTable from './SmartlinkTable'; // Changed import to SmartlinkTable
import Swal from 'sweetalert2';
import  useSmartLinkStore  from '../../../../_helper/SmartLink/useSmartLinkStore';
import GridLoader from '../../../Loaders/GridLoader';
const SmartLinkList = () => {


  const { loading, smartLinks, getAllSmartLinks } = useSmartLinkStore();
  const [inUseFilter, setInUseFilter] = useState(null);
  const [loadingImport, setLoadingImport] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(null);


  useEffect(() => {
    getAllSmartLinks();
    console.log(smartLinks); // Call getAllSmartLinks once when component mounts
  }, []); // Empty dependency array to run the effect only once


  const handleFilterUsed = () => {
    setInUseFilter(true);
  };

  const handleFilterNotUsed = () => {
    setInUseFilter(false);
  };

  const clearFilters = () => {
    setInUseFilter(null);
  };




/*   const handleGenerateLink = async (file) => { 
    try{
      const response = await generateSmartLink(file);
      Swal.fire({
        title: 'Smart Link Generated',
        text: `Smart link created succesfully, you will be notified as soon as your file get delivered and your link is ready.`,
        icon: 'success',
      });

    }catch(error){
      Swal.fire({
        title: 'Error',
        text: 'Failed to generate smart link',
        icon: 'error',
      });
    }
    
  }; */

  return (
    <Fragment>
      <Breadcrumbs
        parent="Smart Link"
        title="smartlinks"
        mainTitle="Smart Link"
        buttons={[
           <Btn
            attrBtn={{
              size: 'sm',
              color: 'primary',
              onClick: () => document.getElementById('file-upload').click(),
            }}
          >
            <i className="fa fa-download me-1"></i>Import
          </Btn> 
        ]}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Visualize your ready smartlinks" />
              <CardBody>
                {loading ? (
                <GridLoader />
                ) : (
                  <SmartlinkTable 
                    smartLinks={smartLinks}
                    //handleGenerateLink={handleGenerateLink}
                    //fetchSmartLinks={fetchSmartLinks}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SmartLinkList;
