import React, { useEffect, useState } from 'react';
import { Container, Image, Spinner } from 'react-bootstrap';
import useSmartLinkStore from '../../../../_helper/SmartLink/useSmartLinkStore';
import { useParams } from 'react-router-dom';
import Error500 from '../../../Pages/ErrorPages/ErrorPage500';
import { getProfileImageUrl, getProductCoverUrl } from "../../../../_helper/Images/ImageHelper";

const MiniMusicPlayer = () => {

    const [coverImage, setCoverImage] = useState('');
    const styles = {
        body: {
            position: 'relative',
            margin: 0,
            padding: 0,
            fontFamily: 'Bitter, serif',
        },
        bodyBefore: {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            backgroundImage: `url(${coverImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(20px)',
        },
        wrapper: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            padding: '50px 0', 
            backgroundSize: 'cover',
        },
        player: {
            width: '410px',
            minHeight: '480px',
            boxShadow: 'rgba(50, 88, 130, 0.32) 0px 15px 35px -5px',
            background: 'rgb(238, 243, 247)',
            borderRadius: '15px',
            padding: '30px',
        },
        albumInfo: {
            textAlign: 'center',
        },
        albumName: {
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '12px',
            lineHeight: '1.3em',
        },
        albumTrack: {
            fontWeight: 400,
            fontSize: '20px',
            textAlign: 'center',
            opacity: 0.7,
            lineHeight: '1.3em',
            minHeight: '52px',
        },
        playerCover: {
            borderRadius: '15px',
            textAlign: 'center',
            marginBottom: '20px',
        },
        playerCoverItem: {
            borderRadius: '15px',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
        },
        imageButtonPair: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '14px'
        },
        ctaButton: {
            alignItems: 'center',
            backgroundColor: 'transparent',
            color: '#000',
            cursor: 'pointer',
            display: 'flex',
            fontFamily: 'ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            fontSize: '1rem',
            fontWeight: '700',
            lineHeight: '1.5',
            textDecoration: 'none',
            textTransform: 'uppercase',
            outline: 0,
            border: 0,
            padding: '1rem',
            transition: 'background-color 0.3s ease-in-out',
            borderRadius: '50%',
            width: '60px', 
            height: '60px',
            justifyContent: 'center',
            position: 'relative', 
            overflow: 'hidden', 
            marginRight: '20px', 
        },
        ctaButtonIcon: {
            marginLeft: '10px', 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
        },
        ctaButtonHover: {
            backgroundColor: '#bbb', 
        },
        Footer: {
            textAlign: 'center',
            padding: '5px 0',
        },
        footerImg: {
            width: '120px',
            height: 'auto',
        },
        button: {
            'display': 'flex',
            'justify-content': 'center',
            'color': '#fff',
            'width': '50px',
            'height': '50px',
            'border-radius': '100%',
            'background': 'linear-gradient(30deg, rgb(26 13 9) 20%, rgb(255 0 0) 80%)',
            'transition': 'all 0.3s ease-in-out 0s',
            'box-shadow': 'rgba(193, 244, 246, 0.698) 0px 0px 0px 0px',
            'animation': '1.2s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running pulse',
            'align-items': 'center',
            'border': '0',
          },
        '@keyframes pulse': {
            '100%':  {
              'box-shadow': '0 0 0 45px rgba(193,244,246,0)',
            }
          }
    };

  const { productName } = useParams();
  const { productData, loading: dataLoading, error, getProductByName } = useSmartLinkStore();
  const [loading, setLoading] = useState(true);
 

  useEffect(() => {
    setLoading(true);
    if (productName) {
      getProductByName(productName).then(() => setLoading(false));
    }
  }, [productName, getProductByName]);

  useEffect(() => {
    if (productData && productData.productCover) {
      setCoverImage(getProductCoverUrl(productData.productCover));
    }
  }, [productData]);

  const renderSocialMediaLinks = (musicData) => {
        const socialMediaLinks = [];
        console.log(Object.entries(musicData))
        for (const [key, value] of Object.entries(musicData)) {
            if (value !== null) {
                
                socialMediaLinks.push(
                    <div className="image-button-container">
                        <div key={key} style={styles.imageButtonPair}>
                            <a href="#">
                                <Image src={require(`../../../../assets/images/1.png`)} width={150} />
                            </a>
                            <button>
                                <a href={value} target="_blank" rel="noopener noreferrer">{key}</a>
                            </button>
                        </div>
                    </div>
                );
            }
        }
        return socialMediaLinks;
    };

  if (loading || dataLoading) {
    return (
      <Container fluid style={{ ...styles.body, display: 'flex', justifyContent: 'center', alignItems: 'center' }} id="app">
        <div style={styles.bodyBefore} />
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error || !productData || !productData.musicData) {
    return <Error500 />;
  }

  return (
    <Container fluid style={styles.body} id="app">
      <div style={styles.bodyBefore} />
      <div style={styles.wrapper}>
        <div style={styles.player} className="player">
          <div className="player__top">
            <div style={styles.playerCover}>
              <Image
                style={styles.playerCoverItem}
                src={getProfileImageUrl(productData.artistPicture)}
                alt="Red Diamond"
                width={300}
                height={300}
              />
            </div>
          </div>
          <div className="box">
            <div style={styles.albumInfo}>
              <p>
                <a style={styles.albumName}>{productData.artistName}</a> -{' '}
                <a style={styles.albumTrack}>{productData.productTitle}</a>
              </p>
            </div>
            {renderSocialMediaLinks(productData.musicData)}
            <div className="image-button-container">
              {[1, 2, 3, 1, 1, 1, 1].map((imageNumber) => (
                <div style={styles.imageButtonPair} key={imageNumber}>
                  <a href="#">
                    <Image src={require(`../../../../assets/images/1.png`)} width={150} />
                  </a>
                  <button>
                    PLAY
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={styles.Footer} className="Footer">
        <Image src="https://redlink.tn/uploads/img/logo2.png" alt="Red Diamond" style={styles.footerImg} />
      </div>
    </Container>
  );
};

export default MiniMusicPlayer;
