import { Breadcrumbs, H5 } from '../../../AbstractElements';

import AccountingTable from './AccountingTable';
import AccountingFilter from './AccountingFilter';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment, useState, useEffect , useMemo } from 'react';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import YoutubeContainer from './Youtube/YoutubeContainer';
import StreamingContainer from './Streaming/StreamingContainer';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import EmptyProvider from './NoProvider';
const AccountingContainer = () => {
  //? this component will re-render when the state changes reflecting changes to AccountingTable
  const [youtubeData, setYoutubeData] = useState([]);
  const [streamingData, setStreamingData] = useState([]);
  const { userData } = useAuth();
  const userRole = userData?.role; 

  const animatedComponents = makeAnimated();

  const providerOptions = [
    { value: 'youtube', label: 'YouTube' },
    { value: 'streaming', label: 'Streaming' },
  ];
  const [dataProvider, setDataProvider] = useState(providerOptions.find(option => option.value === 'youtube'));

  const handleProviderChange = (selectedOption) => {
    setDataProvider(selectedOption);
  };
  const YoutubeContainerMemo = useMemo(() => <YoutubeContainer userRole={userRole} artistPermitted={true} />, [userRole]);
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle='Accounting'
        parent='Accounting'
        title='Accounting Management'
        buttons={[
          <Select
            onChange={handleProviderChange}
            options={providerOptions}
            defaultValue={dataProvider}
            placeholder="Select provider..."
            isClearable
            components={animatedComponents}
            closeMenuOnSelect={true}
            className="mt-2"
            styles={{
              control: (styles, { isFocused, menuIsOpen }) => ({
                ...styles,
                borderColor: isFocused ? "#F9AFAF" : "#ced4da",
                "&:hover": {
                  borderColor: "#F9AFAF",
                },
                ...(menuIsOpen && { borderColor: "#F9AFAF" }),
                width: "300px",
                fontSize: "15px",
              }),
              menu: (styles) => ({
                ...styles,
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected
                  ? "#F9AFAF"
                  : isFocused
                    ? "#FF5E6C"
                    : null,
                color: "#7F3333",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#7F3333",
              }),
            }}
          />,
        ]}
      />
      <Container fluid={true} className="product-wrapper" id="product-wrapper">

            {/* <Card>
              <CardHeader>
                <H5>{'Accounting '}</H5>
                <span>{'Accounting Management Tool'}</span>
              </CardHeader>
              <CardBody>
                <AccountingTable />
              </CardBody>
            </Card> */}
            {!dataProvider && <EmptyProvider />}
          {/* {dataProvider?.value === 'youtube' && YoutubeContainerMemo} */}
          {/* {dataProvider?.value === 'streaming' && <StreamingContainer />} */ }
      </Container>
    </Fragment>
  );
};
export default AccountingContainer;
