import React, { Fragment, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import CustomizerContext from '../../../../_helper/Customizer';

const AddDspModal = ({ show, handleClose, handleAddDsp }) => {

  const { mix_background_layout} = React.useContext(CustomizerContext);
  const inputStyle = { backgroundColor: mix_background_layout === 'light-only' ? '#ffff' : '#262932' };
  const [startDate, setstartDate] = useState(new Date());
  const [newDspData, setNewDspData] = useState({
    name: '',
    url: '',
    logo: '',
    status: 'active',
    description: '',
    territories: '',
    lead_time: '',
    xml_file: '',
  });

  const handleChange = (e, date) => {
    if (e) {
      const { name, value } = e.target;
      setNewDspData({ ...newDspData, [name]: value });
    }
    if (date) {
        const utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        setNewDspData({ ...newDspData, lead_time: utcDate });
    }
  };
  



const handleSubmit = () => {

  const requiredFields = ['name', 'url', 'logo', 'territories', 'description', 'lead_time'];
  const hasEmptyField = requiredFields.some((field) => !newDspData[field]);

  if (hasEmptyField) {
    toast.error('Please fill in all fields.');
    return; 
  }
  handleAddDsp(newDspData)
  handleClose();
};


  return (
    <Fragment>
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add New DSP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='theme-form'>
          <Row className="mb-3">
            <Col>
            <div className="row">
              <Form.Group className="form-group row"  controlId="name">
                <Form.Label>Name</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  name="name"
                
                  value={newDspData.name}
                  onChange={handleChange}
                  required 
                  //  style={inputStyle}
                />
              </Form.Group>
              </div>
            </Col>
            <Col>
              <Form.Group className="form-group row"  controlId="url">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter URL"
                  name="url"
                  className="form-control"
                  value={newDspData.url}
                  onChange={handleChange}
                  required 
                  // style={inputStyle}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group className="form-group row"  controlId="logo">
                <Form.Label>Logo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter logo URL"
                  name="logo"
                  className="form-control"
                  value={newDspData.logo}
                  onChange={handleChange}
                  required 
                  // style={inputStyle}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group  className="form-group" controlId="territories">
                <Form.Label>Territories</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter territories"
                  name="territories"
                  className="form-control"
                  value={newDspData.territories}
                  onChange={handleChange}
                  required 
                  // style={inputStyle}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* className="mb-3" */}
          <Form.Group className="form-group row" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Enter description"
              name="description"
              className="form-control"
              value={newDspData.description}
              onChange={handleChange}
              required 
              // style={inputStyle}
            />
          </Form.Group>
          <Row className="mb-3">
            <Col>
              <Form.Group className="form-group row" controlId="lead_time">
                <Form.Label>Lead Time</Form.Label>
                <DatePicker
                    className="form-control "
                    placeholderText="mm/dd/yyyy"
                    selected={newDspData.lead_time}
                    onChange={(date) => handleChange(null, date)}
                    />
              </Form.Group>
              {/* <Form.Group className="form-group row">
                          <Form.Label className="col-sm-3 col-form-label text-end">Dault</Form.Label>
                          <Col xl='5' sm='9'>
                            <div className="input-group">
                              <DatePicker className="form-control digits" selected={startDate} onChange={handleChange} />
                            </div>
                          </Col>
                          </Form.Group> */}
            </Col>
            <Col>
              <Form.Group  className="form-group row"  controlId="xml_file">
                <Form.Label>XML File</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter XML file URL"
                  name="xml_file"
                  className="form-control"
                  value={newDspData.xml_file}
                  onChange={handleChange}
                  // style={inputStyle}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save DSP
        </Button>
      </Modal.Footer>
    </Modal>
    </Fragment>
  );
};

export default AddDspModal;
