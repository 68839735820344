
import { supportColumns,supportData } from '../../../Data/SupportTicket';
import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { dummytabledata } from '../../../Data/Table/Defaultdata';
import { Input, Label, Media } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';





const AccountingTable = ({selectedRows, handleRowSelected, clearSelectedRows}) => {
  const { control } = useForm();


  const tableColumns = [
    {
      name: 'Artist ID',
      selector: row => row['artist_id'],
      sortable: true,
      center: false,
    },
    {
      name: 'Channel ID',
      selector: row => row['channel_id'],
      sortable: true,
      center: true,
    },
    {
      name: 'Start Date',
      selector: row => `${row.start_date}`,
      sortable: true,
      center: true,
    },
    {
      name: 'End Date',
      selector: row => `${row.end_date}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Month',
      selector: row => row['month'],
      sortable: true,
      center: true,
    },
    {
      name: 'Estimated Revenue',
      selector: row => row['estimated_revenue'],
      sortable: true,
      center: true,
    },
    {
      name: 'Created At',
      selector: row => `${row.created_at}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Updated At',
      selector: row => `${row.updated_at}`,
      sortable: true,
      center: true,
    },
  ];


  const tableData = [
    {
      id: 1,
      artist_id: '1',
      channel_id: 'Channel 1',
      start_date: '2022-01-01',
      end_date: '2022-01-31',
      month: 'January',
      estimated_revenue: '1000.00',
      created_at: '2022-01-01 00:00:00',
      updated_at: '2022-01-01 00:00:00',
    },
    {
      id: 2,
      artist_id: '2',
      channel_id: 'Channel 2',
      start_date: '2022-02-01',
      end_date: '2022-02-28',
      month: 'February',
      estimated_revenue: '2000.00',
      created_at: '2022-02-01 00:00:00',
      updated_at: '2022-02-01 00:00:00',
    },
    {
      id: 3,
      artist_id: '3',
      channel_id: 'Channel 3',
      start_date: '2022-03-01',
      end_date: '2022-03-31',
      month: 'March',
      estimated_revenue: '3000.00',
      created_at: '2022-03-01 00:00:00',
      updated_at: '2022-03-01 00:00:00',
    },
  ];


    return (
        <Fragment>
            <div className="table-responsive support-table">
            <DataTable
          columns={tableColumns}
          data={tableData}
          striped={true}
          center={true}
          pagination
          selectableRows
          onSelectedRowsChange={handleRowSelected}
          // clearSelectedRows={clearSelectedRows}
        />
            </div>
        </Fragment>
    );
};
export default AccountingTable;