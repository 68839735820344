import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { Badges } from "../../../../AbstractElements";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import EmptyDataTable from "../../../Dashboard/Common/EmptyDataTable";
const SmartlinkTable = ({ smartLinks, fetchSmartLinks }) => {
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleImport = async (event) => {
    const file = event.target.files[0];
    const confirmed = await Swal.fire({
      title: 'Confirm Import',
      text: 'Are you sure you want to import the file?',
      icon: 'question',
      showCancelButton: true,
    });

    if (confirmed.isConfirmed) {
      setLoading(true);
      try {
        // Handle file import logic here
        fetchSmartLinks(); // Refresh smart links after import
      } catch (error) {
        console.error('Error during file import:', error); 
        Swal.fire({
          title: 'Error',
          text: 'Error importing the file. Please try again.',
          icon: 'error',
        });
      }

      setLoading(false);
    }
  };





  const columns = [
/*     {
      name: 'Owner',
      cell: (row) => (
        <span>{row.owner ? row.owner.name : 'Not Available'}</span>
      ),
    }, */
    {
      name: 'Product Title',
      cell: (row) => (
        <span>{row.product ? row.product.title : 'Not Available'}</span>
      ),
    },
    {
      name: 'Product Link',
      cell: (row) => {
        const sanitizedTitle = row.product ? row.product.title.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase() : 'not-available';
        return (
          <Link to={`/product/${encodeURIComponent(sanitizedTitle)}`} target="_blank" rel="noopener noreferrer">
            {row.product ? row.product.title : 'Not Available'}
          </Link>
        );
      },
    },  
    {
      name: 'Created At',
      selector: (row) => moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
      sortable: true,
    },
   /*  {
      name: 'Actions',
      cell: (row) => (
        <button className="btn btn-primary" onClick={() => handleUpdateAccess(row)}>Update</button>
      ),
    }, */
  ];

  return (
    <Fragment>
      <div className="mb-3 mt-2 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <input
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            onChange={handleImport}
          />
        </div>
      </div>
  
      {smartLinks.length > 0 ? (
        <DataTable
          columns={columns}
          data={smartLinks}
          striped={true}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15]}
          paginationComponentOptions={{
            rowsPerPageText: 'Items per page:',
            rangeSeparatorText: 'of',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
            paginationCenter: true,
          }}
        />
      ) : (
        <>
        
        <EmptyDataTable />
        </>
      )}
  
      {/* Add custom CSS styles */}
      <style>{`
        .user-list {
          display: flex;
          flex-wrap: wrap;
          max-height: 100px; 
          overflow-y: auto;
        }
  
        .user-item {
          padding: 2px 5px;
          background-color: #f0f0f0;
          border-radius: 4px;
          font-size: 12px;
        }
      `}</style>
    </Fragment>
  );
};

export default SmartlinkTable;
