import React, { useState, useEffect } from 'react';
import { H4, H6, Alerts , P } from '../../../../AbstractElements';
import CountUp from 'react-countup';
import { Card, CardBody, Container, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import DataTable from 'react-data-table-component';
import AnalyticsService from '../../../../Services/analytics/AnalyticsService';
import { Users, Video, ThumbsUp, Share2, Star } from "react-feather";
import GridLoader from '../../../Loaders/GridLoader';

const colorWidget = [
  { color: "primary" },
  { color: "secondary" },
  { color: "success" },
  { color: "warning" },
  { color: "secondary" }, 
];

export default function Tiktok({selectedTimeRange}) {
  const [totalsData, setTotalsData] = useState(null);
  const [territoryData, setTerritoryData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { name: 'Title', selector: row => row.source },
    { name: 'Views', selector: row => row.views },
    { name: 'Creations', selector: row => row.creations },
    { name: 'Likes', selector: row => row.likes },
    { name: 'Shares', selector: row => row.shares },
    { name: 'Favorites', selector: row => row.favorites },
  ];

  const assetColumns = [
    { name: 'Title', selector: row => row.source },
    { name: 'Views', selector: row => row.views },
    { name: 'Creations', selector: row => row.creations },
    { name: 'Likes', selector: row => row.likes },
    { name: 'Shares', selector: row => row.shares },
    { name: 'Favorites', selector: row => row.favorites },
  ];

  const fetchTiktokData = async () => {
    setIsLoading(true);
    try {
      setTimeout(async () => {
        const data = await AnalyticsService.getTiktokData(selectedTimeRange);
        if (data && data.totals_tiktok && data.territory_tiktok && data.asset_performance_tiktok) {
          setTotalsData(data.totals_tiktok);
          setTerritoryData(data.territory_tiktok);
          setAssetData(data.asset_performance_tiktok);
          setError(null);
        } else {
          setError('Incomplete data received from the server.');
        }
        setIsLoading(false); // Move this line here
      }, 2000);
    } catch (error) {
      console.error('Error fetching TikTok data:', error);
      setError('Error fetching data. Please try again later.');
      setIsLoading(false); // Keep this line here in case of an error
    }
  };


  useEffect(() => {
    fetchTiktokData(selectedTimeRange);
  }, [selectedTimeRange]);

  


  const formatValue = (value) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) ;
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1);
    } else {
      return 0;
    }
  };
  
  
  // if (isLoading) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
  //       <GridLoader />
  //     </div>
  //   );
  // }
  
  // // Check if there's an error
  // if (error) {
  //   return (
  //     <div className="text-center">
  //       <Alerts attrAlert={{ color: 'primary ' }} >
  //         <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
  //         <P>An error occurred while fetching data. Please try again later or contact support for assistance.</P>
  //         <hr />
  //         <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
  //       </Alerts>
  //     </div>
  //   );
  // }
  
  // // Check if data is empty
  // if (!totalsData || Object.keys(totalsData).length === 0) {
  //   return (
  //     <div className="text-center">
  //       <Alerts attrAlert={{ color: 'warning ' }} >
  //         <H4 attrH4={{ className: 'alert-heading' }} >No Data</H4>
  //         <P>No data available. Please try again later or contact support for assistance.</P>
  //         <hr />
  //         <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
  //       </Alerts>
  //     </div>
  //   );
  // }

    const renderTotalsWidgets = ({ totalsData }) => {
      return (
        <Row>
          {Object.keys(totalsData).map((key, index) => {
            const colorClass = colorWidget[index % colorWidget.length].color;
            return (
              <Col key={index}>
                <Card className={`small-widget ${colorClass}`}>
                  <CardBody>
                    <span className="f-light">{totalsData[key].source}</span>
                    <div className="d-flex align-items-end gap-2">
                      <H4>
                      <CountUp
                        prefix=""
                        duration={0}
                        separator=","
                        end={parseFloat(formatValue(totalsData[key].value))}
                      />
                      {totalsData[key].value >= 1000000 ? 'M' : totalsData[key].value >= 1000 ? 'K' : ''}

                      </H4>
                      <span className={`font-${totalsData[key].percentage < 0 ? 'danger' : 'success'} f-12 f-w-500`}>
                        <i className={`icon-arrow-${totalsData[key].percentage < 0 ? 'down' : 'up'}`} />
                        <span>{totalsData[key].percentage < 0 ? '-' : '+'}{Math.abs(totalsData[key].percentage)}%</span>
                      </span>
                    </div>
                    <div className="bg-gradient">
                      {totalsData[key].source === 'Total Views' && <Users className="stroke-icon svg-fill" />}
                      {totalsData[key].source === 'Total Creations' && <Video className="stroke-icon svg-fill" />}
                      {totalsData[key].source === 'Total Likes' && <ThumbsUp className="stroke-icon svg-fill" />}
                      {totalsData[key].source === 'Total Shares' && <Share2 className="stroke-icon svg-fill" />}
                      {totalsData[key].source === 'Total Favorites' && <Star className="stroke-icon svg-fill" />}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      );
    };

    return (
      <Container>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <GridLoader />
          </div>
        ) : error ? (
          <div className="text-center">
            <Alerts attrAlert={{ color: 'primary ' }} >
              <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
              <P>An error occurred while fetching data. Please try again later or contact support for assistance.</P>
              <hr />
              <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
            </Alerts>
          </div>
       ) : ((!totalsData || Object.keys(totalsData).length === 0) && !territoryData.length && !assetData.length) ? (
          <div className="text-center">
            <Alerts attrAlert={{ color: 'warning ' }} >
              <H4 attrH4={{ className: 'alert-heading' }} >No Data</H4>
              <P>No data available. Please try again later or contact support for assistance.</P>
              <hr />
              <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
            </Alerts>
          </div>
        ) : (
          <>
            <Row>
              <Col>{renderTotalsWidgets({ totalsData: totalsData })}</Col>
            </Row>
    
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <H6>Tiktok Territory Table For The Last Week</H6>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <DataTable columns={columns} data={territoryData} pagination={true} paginationPerPage={10} />
                </CardBody>
              </Card>
            </Col>
    
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <H6>Tiktok Asset Table For The Last Week</H6>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <DataTable columns={assetColumns} data={assetData} pagination={true} paginationPerPage={10} />
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Container>
    );
}
