import React, { Fragment } from 'react';
import { H3, H4, P, Image } from '../../../../AbstractElements';
import { Col, Media, Row } from 'reactstrap';
import RedIcon from '../../../../assets/images/logo/logo_red.svg';

const InvoiceHeader = ({ invoice }) => {
  return (
      <Fragment>
        <Row className='invo-header'>
          <Col sm='6'>
            <Media>
              <div className='media-left'>
                <Image attrImage={{ className: 'media-object img-100', src: 'https://picsum.photos/200/300', alt: '' }} />
              </div>
              <Media body className='m-l-20 text-right'>
                <H4 attrH4={{ className: 'media-heading f-w-600' }}>{invoice.label.label_name || 'No Label'}</H4>
                <P>
                  {invoice.label.email || 'No email'}
                  <br/>
                  <span className='digits'>{invoice.label.phone_number || 'No phone number'}</span>
                  <br/>
                  <span className='digits'>{invoice.label.address || 'No adress'}</span>
                </P>
              </Media>
            </Media>
          </Col>
          <Col sm='6'>
            <div className='text-md-end text-xs-center'>
              <H3>
                {invoice.reference}
              </H3>
              <P>
                Issued: <span className='digits'>{invoice.date}</span>
                <br />
                For month: <span className='digits'>{invoice.month_year || 'N/A'}</span>
              </P>
            </div>
          </Col>
        </Row>
        <hr />
      </Fragment>
  );
};

export default InvoiceHeader;