import React, { Fragment, useState } from "react";
import { CardBody, Col, Container, Row } from "reactstrap";
import { Btn, Image } from '../../../../../AbstractElements';
import Files from 'react-files';
import { getProductCoverUrl } from '../../../../../_helper/Images/ImageHelper';

const ImageSlider = ({ fetchedProduct, imageSize, updateProductPicture }) => {
    const productCoverUrl = getProductCoverUrl(fetchedProduct.artwork);
    const [files, setFiles] = useState([]);
    const [productImage, setProductCoverUrl] = useState(productCoverUrl);
    const [isLoading, setIsLoading] = useState(false);

    const deleteFile = () => {
        setFiles([]);
    };

    const onFilesChange = (files) => {
        setFiles(files);
    };

    const handleImageUpdate = async () => {
      if (files.length === 0) {
          alert('The picture field is required.');
          return; 
      }
  
      setIsLoading(true);
  
      try {
          const { success, error, product } = await updateProductPicture(fetchedProduct.id, files[0]);
          if (success) {
              setProductCoverUrl(getProductCoverUrl(product.artwork));
          } else {
              console.error('Failed to update image:', error);
          }
      } catch (error) {
          console.error('Error updating image:', error);
      } finally {
          setIsLoading(false);
          setFiles([]);
      }
  };
  
  

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <CardBody className="fileUploader">
                            <Image
                                attrImage={{
                                    src: productImage,
                                    alt: fetchedProduct.name,
                                    className: `img-fluid w-100 ${imageSize}`,
                                }}
                            />
                            <Files
                                className='files-dropzone fileContainer'
                                onChange={onFilesChange}
                                accepts={['image/*']}
                                multiple={false}
                                maxFileSize={10000000}
                                minFileSize={0}
                                clickable
                            >
                                {
                                    files.length > 0
                                        ? <div className='files-gallery'>
                                            {files.map((file, index) =>
                                                <div key={index}>
                                                    <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}`, style: { width: imageSize, height: imageSize } }} />
                                                </div>
                                            )}
                                        </div>
                                        : <div className="d-flex justify-content-center">
                                            <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Upload Image</Btn>
                                        </div>
                                }
                            </Files>
                            <div className="d-flex justify-content-center mt-2">
                                {(isLoading || files.length > 0) && (
                                    <Fragment>
                                        <Btn
                                            attrBtn={{
                                                className: "me-2",
                                                color: 'primary',
                                                type: "button",
                                                onClick: handleImageUpdate
                                            }}
                                        >
                                            {isLoading ? "Updating..." : "Update Image"}
                                        </Btn>
                                        <Btn
                                            attrBtn={{
                                                color: 'danger',
                                                type: "button",
                                                onClick: deleteFile
                                            }}
                                        >
                                            Delete
                                        </Btn>
                                    </Fragment>
                                )}
                            </div>
                        </CardBody>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ImageSlider;
