import React, { useState, useEffect , useCallback } from "react";
import DataTable from "react-data-table-component";
import { Row, Col, Button } from "reactstrap";
import moment from "moment";

export default function Dsp({ dsps, selectedDsps, setSelectedDsps , setSelectedDspRows, selectedDspRows}) {
  const [filteredDsps, setFilteredDsps] = useState(dsps);

  useEffect(() => {
    setFilteredDsps(dsps);
  }, [dsps]);

  const selectedRowObjects = selectedDsps.map((id) =>
    filteredDsps.find((dsp) => dsp.id === id)
  );

  const handleChangeDsps = (state) => {
    setSelectedDspRows(state.selectedRows);
  };

  const rowSelectCriteria = useCallback(row => 
    selectedDsps && selectedDsps.find(dsp => dsp && dsp.id === row.id) !== undefined, 
  [selectedDsps]);

  const handleSelectAll = () => {
    setSelectedDsps(filteredDsps.map((dsp) => dsp.id));
  };

  // const handleShowMore = (row) => {
  //   // Implement your logic here
  // };

  // const handleDelete = (row) => {
  //   // Implement your logic here
  // };


  const columns = [
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
    },
    {
      name: "URL",
      selector: (row) => row["url"],
      sortable: true,
    },
    {
      name: "Logo",
      selector: (row) => row["logo"],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
    },
  ];

  return (
    <Row>
      <Col className="dsp-section">
        <DataTable
          columns={columns}
          data={filteredDsps}
          pagination
          selectableRows
          selectableRowsHighlight
          onSelectedRowsChange={handleChangeDsps}
          selectedRows={selectedRowObjects}
          selectableRowSelected={rowSelectCriteria}
        />
       
      </Col>
    </Row>
  );
}