
export const generateSourceChartData = (sourcechart) => {
  const labels = sourcechart.map(item => item.source);
  const data = sourcechart.map(item => {
    const percentage = item.percentage;
    if (typeof percentage === 'string') {
      return parseFloat(percentage.replace('%', ''));
    } else {
      return percentage;
    }
  });

  console.log(data);
  return {
    series: data,
    options: {
      chart: {
        width: 380,
        type: 'pie',
        toolbar: {
          show: false,
        },
      },
      labels: labels,
      colors: ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#ffff00'], // Customize colors as needed
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + '%';
        },
        style: {
          fontSize: '12px',
        },
      },
    },
  };
};
export const generateFreePremiumData = (freepremieumGroup) => {
  const data = freepremieumGroup?.map(item => parseFloat(item.value));
  const labels = freepremieumGroup?.map(item => item.attribute);
  return {
    series: data,
    options: {
      chart: {
        width: 380,
        type: 'pie',
        toolbar: {
          show: false,
        },
      },
      labels: labels,
      colors: ['#51bb25', '#544fff'], // Colors for Free and Premium categories
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
        style: {
          fontSize: '12px',
        },
      },
    },
  };
};

export const generateGenderData = (gender) => {
  const data = gender?.map(item => parseFloat(item.percentage));
  const labels = gender?.map(item => item.gender);
  return {
    series: data,
    options: {
      chart: {
        width: 380,
        type: 'pie',
        toolbar: {
          show: false,
        },
      },
      labels: labels,
      colors: ['#51bb25', '#544fff', '#fb740d'], // Colors for each category
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
        style: {
          fontSize: '12px',
        },
      },
    },
  };
};

export const generateDeviceData = (device) => {
  const data = device?.map(item => parseFloat(item.percentage));
  const labels = device?.map(item => item.device);
  return {
    series: data,
    options: {
      chart: {
        width: 380,
        type: 'pie',
        toolbar: {
          show: false,
        },
      },
      labels: labels,
      colors: ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#ffff00', '#ff7f00'], // Colors for each category
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
        style: {
          fontSize: '12px',
        },
      },
    },
  };
};

export const generateAgeGroupData = (ageGroup) => {
  const data = ageGroup?.map(item => parseFloat(item.percentage));
  const labels = ageGroup?.map(item => item.age_group);
  return {
    series: data,
    options: {
      chart: {
        width: 380,
        type: 'pie',
        toolbar: {
          show: false,
        },
      },
      labels: labels,
      colors: ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#ffff00'], 
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
        style: {
          fontSize: '12px',
        },
      },
    },
  };
};
