import React, { Fragment } from "react";

import ReleaseFilters from "./ReleaseFilters";
import ReleaseTotal from "./ReleaseTotal";
const ReleaseFeatures = ({ searchKeyword, setSearchKeyword }) => {
  return (
    <Fragment>
      <div className="feature-products">
        <ReleaseTotal />
        <ReleaseFilters searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      </div>
    </Fragment>
  );
};
export default ReleaseFeatures;
