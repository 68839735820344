import React, { Fragment, useContext, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row , Label } from 'reactstrap';
import FilterContext from '../../../_helper/Filter';
import { H6 } from '../../../AbstractElements';

const AccountingFilter = ({ searchKeyword, setSearchKeyword, filterByPaidStatus, filterByDateRange, dataProvider }) => {
    const { filterContext, filterSearchBy } = useContext(FilterContext);
    const [sidebaron, setSidebaron] = useState(true);
    const [paidStatus, setPaidStatus] = useState('');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    const [selectedMonth, setSelectedMonth] = useState("");

 
    const onClickFilter = () => {
        if (sidebaron) {
            setSidebaron(false);
            document.getElementById('product-wrapper').classList.add('sidebaron');
        } else {
            setSidebaron(true);
            document.getElementById('product-wrapper').classList.remove('sidebaron');
        }
    };

    const handleSearchKeyword = (event) => {
        setSearchKeyword(event.target.value);
        filterSearchBy(event.target.value);
    };

    // const handlePaidStatusChange = (event) => {
    //     setPaidStatus(event.target.value);
    //     filterByPaidStatus(event.target.value);
    // };

    // const handleDateRangeChange = (event) => {
    //     setDateRange({ ...dateRange, [event.target.name]: event.target.value });
    //     filterByDateRange(dateRange);
    // };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
      };

    return (
        <Fragment>
            <Row>
                <Col xl="3" md="6">
                    <div className={`product-sidebar ${filterContext ? 'open' : ''}`}>
                        <div className="filter-section">
                            <Card>
                                <CardHeader>
                                    <H6 attrH6={{ className: 'mb-0 f-w-600' }}>Filters
                                        <span className="pull-right">
                                            <i className="fa fa-chevron-down toggle-data"
                                                onClick={onClickFilter}>
                                            </i>
                                        </span>
                                    </H6>
                                </CardHeader>
                                <div className="left-filter">
                                    <CardBody className="filter-cards-view animate-chk">
                                        {/* <FormGroup>
                                            <label>Date Range</label>
                                            <Input type="date" name="start" value={dateRange.start} onChange={handleDateRangeChange} />
                                            <Input type="date" name="end" value={dateRange.end} onChange={handleDateRangeChange} />
                                        </FormGroup> */}

                                        <FormGroup>
                                            <Label className="col-form-label">Date</Label>
                                            <Input
                                                type="select"
                                                name="month"
                                                value={selectedMonth}
                                                onChange={handleMonthChange}
                                            >
                                                <option value="">Filter By Month ...</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                            </Input>
                                        </FormGroup>

                                    </CardBody>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Col>
                <Col xl="9" md="6">
                    <div className="product-search">
                        <Form>
                            <FormGroup className="m-0 form-group search-product">
                                <Input className="form-control" type="text" placeholder="search"
                                    value={searchKeyword}
                                    onChange={(e) => handleSearchKeyword(e)} />
                                <i className="fa fa-search"></i>
                            </FormGroup>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default AccountingFilter;