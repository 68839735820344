import React, { Fragment, useCallback, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Btn, UL, LI } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import EmptyDataTable from "../../../Dashboard/Common/EmptyDataTable";
const BarcodeTable = ({
  inUseFilter,
  setInUseFilter,
  upcs,
  importData,
  fetchUPCs,
  deleteUPC,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(upcs);
  const [loading, setLoading] = useState(false);

  
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this barcode?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteUPC(id); 
          Swal.fire({
            title: 'Success',
            text: 'Barcode deleted successfully!',
            icon: 'success',
          });
        } catch (error) {
          console.error('Error deleting UPC:', error);
        }
      }
    });
  };

  const handleImport = async (event) => {
    const file = event.target.files[0];
    const confirmed = await Swal.fire({
      title: 'Confirm Import',
      text: 'Are you sure you want to import the file?',
      icon: 'question',
      showCancelButton: true,
    });

    if (confirmed.isConfirmed) {
      setLoading(true);

      try {
        const response = await importData(file);
        if (response.success === true) {
          fetchUPCs();
          Swal.fire({
            title: 'Success',
            text: 'File imported successfully!',
            icon: 'success',
          });
        } else {
          console.error('Error during file import:', response.error);
          Swal.fire({
            title: 'Error',
            text: response.error || 'Error importing the file. Please try again.',
            icon: 'error',
          });
        }
      } catch (error) {
        console.error('Error during file import:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error importing the file. Please try again.',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      name: "UPC",
      selector: (row) => row.upc,
      sortable: true,
      center: false,
    },
    {
      name: 'Product Name',
      selector: (row) => (row.product ? row.product.title : 'No product'),
      sortable: true,
      center: true,
      cell: (row) => (
        <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          {row.product ? row.product.title : 'No product'}
        </div>
      ),
    },
    
    {
      name: "In Use",
      selector: (row) => (row.in_use ? "Yes" : "No"),
      sortable: true,
      center: true,
      cell: (row) => (row.in_use ? "Yes" : "No"),
      filterValue: (rows, filter) => {
        if (filter === "all") return rows;
        const targetValue = filter === "true";
        return rows.filter((row) => row.in_use === (targetValue ? 1 : 0));
      },
      filter: (
        <select
          className="form-control"
          value={inUseFilter === null ? "all" : inUseFilter.toString()}
          onChange={(e) =>
            setInUseFilter(
              e.target.value === "true"
                ? true
                : e.target.value === "false"
                ? false
                : null
            )
          }
        >
          <option value="all">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className='todo'>
        <div className='todo-list-wrapper'>
          <div className='todo-list-container todo-list-footer'>
            <ul className="simple-list">
              <li className="task border-0">
                <div className="task-container">
                  <span className="task-action-btn">
                    <span className="action-box large delete-btn" title="Delete Task" onClick={() => handleDelete(row.id)}>
                      <i className="icon">
                        <i className="icon-trash"></i>
                      </i>
                    </span>
                   {/*  {<span className="action-box large complete-btn" title="Mark Complete" onClick={() => handleEditUPC(row)}>
                      <i data-feather="edit"></i>
                    </span>} */}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      ),
      center: true,
    },
  ];

  // const handleEditUPC = (row) => {
  //   console.log("Edit UPC:", row.id);
  // };

  const filteredData = data.filter((item) => {
    if (inUseFilter === null) {
      return true;
    } else {
      return item.in_use === (inUseFilter ? 1 : 0);
    }
  });

  return (
    <Fragment>
      <div className="mb-3 mt-2 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <input
            id="file-upload"
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleImport}
          />
        </div>
      </div>
  
      {filteredData.length > 0 ? (
        <DataTable
          data={filteredData}
          columns={columns}
          striped={true}
          pagination
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleDelete}
          paginationPerPage={5} 
          paginationRowsPerPageOptions={[5, 10, 15]} 
          paginationComponentOptions={{
            rowsPerPageText: 'Barcodes per page:',
            rangeSeparatorText: 'of',
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
            paginationCenter: true, 
          }}
        />
      ) : (
        <EmptyDataTable />
      )}
    </Fragment>
  );
};

export default BarcodeTable;
