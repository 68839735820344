import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import { Btn } from "../../../../../../AbstractElements";
import Contributors from "../../../NewRelease/Tabs/Contributors";
import Files from "../../../NewRelease/Tabs/Files";
import Metadata from "../../../NewRelease/Tabs/Metadata";
import GridLoader from "../../../../../Loaders/GridLoader";
import { useFormContext } from "react-hook-form";
import { useReleaseContext } from "../../../../../../_helper/Release/ReleaseProvider";
import { toast } from "react-toastify";
import { set } from "date-fns";
export default function NewAsset({
  show,
  handleClose,
  genres,
  artists,
  i,
  productId,
  onNewAsset,
}) {
  
  //? using FormContext
  const { control, errors, setValue, clearErrors, resetField, getValues, trigger, reset } = useFormContext();
  const { saveSecondStepData, loading } = useReleaseContext();
  const [activeTab, setActiveTab] = useState("1");
  // const [addedAsset, setaddedAsset] = useState(null);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  

  const handleAddAsset = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    let formData = getValues();
  
    formData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (value === undefined) {
          const [fieldName, index] = key.split("_");
          return [`${fieldName}_${index || 0}`, 0];
        }
        return [key, value];
      })
    );
  
    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== undefined)
    );
  
    const formDataWithFile = new FormData();
    Object.entries(filteredData).forEach(([key, value]) => {
      const [fieldName] = key.split("_");
      if (fieldName === "audio_file") {
        formDataWithFile.append(key, value);
      } else {
        if (fieldName.startsWith("tags")) {
          if (Array.isArray(value)) {
            const tags = value.map((item) => item.label);
            formDataWithFile.append(key, JSON.stringify(tags));
          }
        } else if (fieldName === "others_artists" && typeof value === 'object') { // Added condition to check if field is 'others_artists' and its value is an object
          formDataWithFile.append(key, JSON.stringify(value)); // Stringify the value before appending to formDataWithFile
        } else {
          formDataWithFile.append(key, value);
        }
      }
    });
  
    formDataWithFile.append("productId", productId);
    console.log("added asset Data",formDataWithFile);
  
    try {
      const response = await saveSecondStepData(formDataWithFile);
      console.log("Response:", response.assets);
      if (response && response.status === true) {
        // onNewAsset(response?.assets);
        // setNewAsset(response?.assets);
        toast.success("New Asset is Added !");
        reset();
      } else {
        console.error("Response is undefined or status is not true");
      }
    } catch (error) {
      console.error("Error calling saveStepData:", error);
      toast.error("An error occurred while creating your product asset");
    }
  };






  return (
    <Modal isOpen={show} toggle={handleClose} size="lg">
      <ModalHeader toggle={handleClose}>Add New Asset</ModalHeader>
      <ModalBody>
      {loading ? (
      <GridLoader />
    ) : (
      <>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  toggle("1");
                }}
              >
                Metadata
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  toggle("2");
                }}
              >
                Files
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => {
                  toggle("3");
                }}
              >
                Contributors
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {/* //todo: name={i !== undefined ? `others_artists_${i}` : 'others_artists'} example to be able to not pass a i */}
              <Metadata
                i={0}
                artists={artists}
                genres={genres}
                control={control}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                handleNextSecondStep={handleAddAsset}
              />
            </TabPane>
            <TabPane tabId="2">
              <Files
                i={0}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                setValue={setValue}
                handleNextSecondStep={handleAddAsset}
              /> 
            </TabPane>
            <TabPane tabId="3">
               <Contributors
                      control={control}
                      errors={errors}
                      resetField={resetField}
                      clearErrors={clearErrors}
                      assetIndex={0}
                      handleNextSecondStep={handleAddAsset}
               /> 
            </TabPane>
          </TabContent>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="primary" onClick={handleClose}>
              Close
            </Button>
            <Btn
              attrBtn={{
                size: "l",
                color: "primary",
                /* outline: true, */
                onClick: handleAddAsset, 
              }}
              type="button" 
            >
              {/* <i className="fa fa-add me-1"></i> */}
              Add Asset
            </Btn>
          </ModalFooter>

          </>
    )}
      </ModalBody>
    </Modal>
  );
}
