import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { H6, P } from '../../../../AbstractElements';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Error404 from '../../../Pages/ErrorPages/ErrorPage404'; 
import CustomizerContext from '../../../../_helper/Customizer';
import SmartLinkService from '../../../../Services/smartlink/SmartLinkService';
import GridLoader from '../../../Loaders/GridLoader';
const FileDisplayPage = ({ onDownload }) => {
  const { generateLink } = useParams();
  const [smartLink, setSmartLink] = useState(null);
  const [loading, setLoading] = useState(true);
  // const baseURL = process.env.REACT_APP_BACKEND_URL;
  // const { layoutURL } = useContext(CustomizerContext);


  const fetchSmartLink = async () => {
    try {
      const response = await SmartLinkService.fetchSmartLink(generateLink);
      if (response.success) {
        setSmartLink(response.smartLink);
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching smartlink data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSmartLink();
  }, [generateLink]);

  const handleDownload = () => {
    if (smartLink) {
      const anchor = document.createElement('a');
      anchor.href = smartLink.file_link;
      anchor.download = smartLink.filename;

      document.body.appendChild(anchor);
      anchor.click();

      document.body.removeChild(anchor);
    }
  };

  return (
    <Container className='mt-5'>
      <Row className='justify-content-center'>
        <Col md='8'>
          {loading ? (
            < GridLoader />
          ) : smartLink ? (
            <div className='file-box p-4 rounded shadow bg-white'>
              <div className='d-flex align-items-center'>
                <div>
                  <H6>{smartLink.filename}</H6>
                  <P className='text-muted'>{moment(smartLink.created_at).format('YYYY-MM-DD HH:mm:ss')}</P>
                </div>
              </div>
              <div className='text-center'>
                <Button color='primary' onClick={handleDownload}>
                  Download File
                </Button>
              </div>
            </div>
          ) : (
            <Error404 /> 
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FileDisplayPage;
