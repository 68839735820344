// ReleaseService.js
import axios from "../../api/axios";

const ReleaseService = {
    getAllArtists: async () => {
        try {
          const response = await axios.get("/users/artists");
          const { artists } = response.data;
          return { success: true, artists };
        } catch (error) {
          console.error("Failed to fetch artists", error);
          return { success: false, error };
        }
      },


    saveStepData: async (step, data) => {
        try {
            const response = await axios.post(`/releases/step-one`, data);
            //console.log('Save step data response:', response);
            return { response };
        } catch (error) {
            console.error(`Failed to save step data`, error);
            return { success: false, error };
        }
    },

    saveSecondStepData: async (data) => {
      try {
          const response = await axios.post(`/releases/step-two`, data);
          // console.log('Save step data response:', response);
          return { response };
      } catch (error) {
          console.error(`Failed to save step data`, error);
          return { success: false, error };
      }
  },



    getGenres: async () => {
      try {
        const response = await axios.get("/releases/genres");
        const { genres } = response.data;
        return { success: true, genres };
      } catch (error) {
        console.error("Failed to fetch genres", error);
        return { success: false, error };
      }
    },

    getAllCountries: async () => {
      try {
          const response = await axios.get(`/countries/list`);
          const { listCountries, code, message } = response.data;
          return { listCountries, code, message };
      } catch (error) {
          console.error(`Failed to fetch All countries`, error);
          return { error };
      }
  },
  getProductById: async (id) => {
    try {
      const response = await axios.get(`/releases/getProduct/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  deleteProductById: async (id) => {
    try {
        const response = await axios.delete(`/releases/deleteProduct/${id}`);
        return { success: true, message: response.data.success };
    } catch (error) {
        console.error(`Failed to delete product`, error);
        return { success: false, error };
    }
},
deleteAssetById: async (id) => {
  try {
    const response = await axios.delete(`/releases/deleteAsset/${id}`);
    return { success: true, message: response.data.success };
  } catch (error) {
    console.error(`Failed to delete asset`, error);
    return { success: false, error };
  }
},

  saveThirdStepData: async (data) => {
      try {
          const response = await axios.post(`/releases/step-three`, data);
          //console.log('Save step data response:', response);
          return { response };
      } catch (error) {
          console.error(`Failed to save step data`, error);
          return { success: false, error };
      }
  },

  getAllProducts: async () => {
      try {
          const response = await axios.get("/releases/getAllProducts");
          const { products } = response.data;
          return { success: true, products };
      } catch (error) {
          console.error("Failed to fetch products", error);
          return { success: false, error };
      }
  },
  
  updateProductMetadata: async (productId, data) => {
    try {
      const response = await axios.post(`/releases/updateProductMetadata/${productId}`, data);
      const { product } = response.data;
      return { success: true, product };
    } catch (error) {
      console.error(`Failed to update product metadata`, error);
      return { success: false, error };
    }
  },
  
  updateProductAddInfos: async (productId, data) => {
    try {
        const response = await axios.post(`/releases/updateProductAddInfos/${productId}`, data);
        const { message, status } = response.data;
        return { success: status, message };
    } catch (error) {
        console.error(`Failed to update product additional information`, error);
        return { success: false, error };
    }
},

  updateAsset: async (data) => {
      try {
          const response = await axios.post(`/releases/updateAsset`, 
              data, 
          );
          // console.log('Update asset response in the service :', response.data.asset);
          return { response: response, success: true, message: 'Asset updated successfully' };
      } catch (error) {
          console.error('Failed to update asset', error);
          return { success: false, error };
      }
  },

  updateProductPicture: async (productId, imageData) => {
    try {
        const formData = new FormData();
        formData.append('file', imageData); 
        
        const response = await axios.post(`/releases/updateProductPicture/${productId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const { product } = response.data;
        return { success: true, product };
    } catch (error) {
        console.error(`Failed to update product picture`, error);
        return { success: false, error };
    }



},

validateProduct: async (productId) => {
  try {
    const response = await axios.post(`/releases/validateDelivery/${productId}`);
    return response.data; // Return the entire response data
  } catch (error) {
    console.error(`Failed to validate product`, error);
    return null; // Return null in case of error
  }
},


    


};
export default ReleaseService;
