import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Badge,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Chart from "react-apexcharts";
import { H6, Alerts, H4, P } from "../../../../AbstractElements";
import AnalyticsService from "../../../../Services/analytics/AnalyticsService";
import GridLoader from "../../../Loaders/GridLoader";
import {
  generateDeviceData,
  generateFreePremiumData,
  generateGenderData,
  generateAgeGroupData,
  generateSourceChartData,
} from "./chartsData";
import { formatSourceData } from "../../../../_helper/Analytics/utils";

const sourceColumns = [
  { name: "Source", selector: (row) => row.source },
  { name: "Plays", selector: (row) => row.plays },
  { name: "%", selector: (row) => row.percentage },
];

const territoryColumns = [
  { name: "Territory", selector: (row) => row.title },
  { name: "Streams", selector: (row) => row.streams },
  {
    name: "Streams Change",
    selector: (row) => (
      <div>
        {row.streams_change}{" "}
        {row.changeDirection === "up" ? (
          <span className="text-success" style={{ fontSize: "20px" }}>
            &#8593;
          </span>
        ) : row.changeDirection === "down" ? (
          <span className="text-danger" style={{ fontSize: "20px" }}>
            &#8595;
          </span>
        ) : null}
      </div>
    ),
  },
];

const Spotify = ({ selectedTimeRange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ageGroup, setAgeGroupData] = useState([]);
  const [freepremieumGroup, setFreepremieumGroup] = useState([]);
  const [gender, setGenderData] = useState([]);
  const [device, setDeviceData] = useState([]);
  const [sourcechart, setSourceChart] = useState([]);
  const [streambyterritory, setStreamByTerritory] = useState([]);
  const [error, setError] = useState(null);

  const getSpotifyData = async () => {
    setIsLoading(true);
    try {
      setTimeout(async () => {
        const response = await AnalyticsService.getSpotifyData(
          selectedTimeRange
        );
        if (
          response &&
          response.age_group_spotify &&
          response.free_or_premium_spotify &&
          response.streaming_gender &&
          response.streaming_device &&
          response.source_for_spotify &&
          response.streams_by_territory
        ) {
          setAgeGroupData(response.age_group_spotify);
          setFreepremieumGroup(response.free_or_premium_spotify);
          setGenderData(response.streaming_gender);
          setDeviceData(response.streaming_device);

          // Format the source chart data before setting it
          const formattedSourceForSpotify = formatSourceData(
            response.source_for_spotify
          );
          setSourceChart(formattedSourceForSpotify);

          const formattedStreamsByTerritory = formatSourceData(
            response.streams_by_territory
          );
          setStreamByTerritory(formattedStreamsByTerritory);
          setIsLoading(false);
          setError(null);
        } else {
          setError("Incomplete data received from the server.");
          setIsLoading(false);
        }
      }, 2000);
    } catch (error) {
      console.error("Error fetching Spotify data:", error);
      setError("Error fetching data. Please try again later.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSpotifyData(selectedTimeRange);
  }, [selectedTimeRange]);

  const ageGroupData = generateAgeGroupData(ageGroup);
  const freePremiumData = generateFreePremiumData(freepremieumGroup);
  const genderData = generateGenderData(gender);
  const deviceData = generateDeviceData(device);
  const sourceChartData = generateSourceChartData(sourcechart);

  const paginationOptions = {
    rowsPerPage: 10, // Display 10 rows per page
    rowsPerPageText: "Rows per page:", // Text for the rows per page dropdown
    noRowsPerPage: false, // Show/hide rows per page dropdown
    noRowsPerPageOptions: false, // Hide rows per page dropdown options
    paginationRowsPerPageOptions: [10, 20, 30], // Custom rows per page options
    paginationText: "1-{totalRows} of {totalRows}", // Text for pagination info
  };

  return (
    <Container>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <GridLoader />
        </div>
      ) : error ? (
        <div className="text-center">
          <Alerts attrAlert={{ color: "primary " }}>
            <H4 attrH4={{ className: "alert-heading" }}>Error</H4>
            <P>
              An error occurred while fetching data. Please try again later or
              contact support for assistance.
            </P>
            <hr />
            <P attrPara={{ className: "mb-0" }}>
              If you need immediate assistance, please contact support at
              support@reddiamond.com.
            </P>
          </Alerts>
        </div>
      ) : !ageGroup.length &&
        !freepremieumGroup.length &&
        !gender.length &&
        !device.length &&
        !sourcechart.length &&
        !streambyterritory.length ? (
        <div className="text-center">
          <Alerts attrAlert={{ color: "warning " }}>
            <H4 attrH4={{ className: "alert-heading" }}>No Data</H4>
            <P>No data available at the moment. Please try again later.</P>
          </Alerts>
        </div>
      ) : (
        <>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h6>Source For Spotify</h6>
                  </CardTitle>
                </CardHeader>
                <CardBody className="d-flex">
                  {sourcechart.length > 0 ? (
                    <>
                      <div className="col-md-7">
                        <DataTable columns={sourceColumns} data={sourcechart} />
                      </div>
                      <div className="col-md-5 mt-5 overflow-auto">
                        <Chart
                          options={sourceChartData.options}
                          series={sourceChartData.series}
                          type="pie"
                          width={500}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="text-center">Not Available</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle>
                    <H6>Spotify Streams by Territory</H6>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={territoryColumns}
                    data={streambyterritory}
                    pagination={true}
                    paginationPerPage={10}
                    {...paginationOptions}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {/* Iterate over the data and render charts */}
            {["AGEGROUP", "FREE_PREMIUM", "GENDER", "DEVICE"].map(
              (title, index) => (
                <Col md="4" key={index}>
                  <Card>
                    {/* Card content */}
                    <CardBody>
                      <div id={`piechart-${title}`}>
                        {/* Check if data for this chart is available */}
                        {title === "AGEGROUP" && ageGroupData.series.length ? (
                          <Chart
                            options={ageGroupData.options}
                            series={ageGroupData.series}
                            type="pie"
                          />
                        ) : title === "FREE_PREMIUM" &&
                          freePremiumData.series.length ? (
                          <Chart
                            options={freePremiumData.options}
                            series={freePremiumData.series}
                            type="pie"
                          />
                        ) : title === "GENDER" && genderData.series.length ? (
                          <Chart
                            options={genderData.options}
                            series={genderData.series}
                            type="pie"
                          />
                        ) : title === "DEVICE" && deviceData.series.length ? (
                          <Chart
                            options={deviceData.options}
                            series={deviceData.series}
                            type="pie"
                          />
                        ) : (
                          // Display message when data is not available
                          <div className="text-center">Not available yet</div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </>
      )}
    </Container>
  );
};

export default Spotify;
