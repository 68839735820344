import React, { useState } from 'react';
import { Col, Row , Button} from 'reactstrap';

import { H6, Image, P, Progressbar } from '../../../../AbstractElements';

import { AccountProgress } from '../../../../Constant';

const CusClass = ({ item, onEdit }) => {
  // Dropdown toggle
  //const [dropdownOpen, setDropdownOpen] = useState(false);
 // const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Define color classes for each role
  const roleBadgeColors = {
    admin: 'badge-primary',
    artist: 'badge-warning',
    staff: 'badge-secondary',
    label: 'badge-success',
  };

  const statusBadgeColors = {
    true: 'badge-success',
    false: 'badge-primary',
  };


  const formatDateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  
  const formattedDate = new Date(item.created_at).toLocaleString('en-US', formatDateOptions);

  return (
    <Col className='col-xxl-4' md='6'>
      <div className='project-box'>
        <span className={`badge ${roleBadgeColors[item.role]}`}>{item.role}</span>
        <div className='media'>
          <Image attrImage={{ className: 'img-40 me-1 rounded-circle', src: `${require(`../../../../assets/images/user/1.jpg`)}`, alt: '' }} />
          <div className='align-self-center'>
              <H6>{item.name}</H6>
          </div>
        </div>

        <Row className='details'>
          <Col xs='10'>
            Email: <span className='text-dark'><b>{item.email}</b></span>
          </Col>
          <Col xs='10'>
            Username: <span className='text-dark'><b>{item.username}</b></span>
          </Col>
          <Col xs='10'>
            Creation Date: <span className='text-dark'><b>{formattedDate}</b></span>
          </Col>
          <Col xs='10' className='d-flex align-items-center'>
            Security Status: &nbsp;
            <span className={`text-${item.status_compte ? 'success' : 'danger'}`}>
              <b>{item.status_compte ? 'Activated' : 'Disabled'}</b>
            </span>
          </Col>

        </Row>



        <div className='project-status mt-4'>
          <div className='media mb-0'>
            <P>{item.progress}% </P>
            <div className='media-body text-end'>
              <span>{AccountProgress}</span>
            </div>
          </div>
          <div className='progress'>
            <div
              className='progress-bar bg-primary'
              role='progressbar'
              style={{ width: `${item.progress}%` }}
              aria-valuenow='100'
              aria-valuemin='0'
              aria-valuemax='100'
            ></div>
          </div>
        </div>

        {/* Edit button */}
        <div className='d-flex justify-content-center mt-2'>
          <Button color='primary' onClick={() => onEdit(item)}>
            Edit
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default CusClass;
