import React, { useState } from "react";
import { Breadcrumbs, Btn, H6 } from "../../../AbstractElements";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Input, Badge,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ProductService from "../../../Services/products/ProductService";
import GridLoader from "../../Loaders/GridLoader";
import { getProductCoverUrl } from "../../../_helper/Images/ImageHelper";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
function ProductsContainer() {
  const queryClient = useQueryClient();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["products"],
    queryFn: ProductService.getAllProducts,
  });

  const syncProductsMutation = useMutation({
    mutationFn: ProductService.syncProducts,
    onSuccess: () => {
      toast.success("Products synced successfully!");
      queryClient.invalidateQueries("products");
    },
    onError: (error) => {
      toast.error(`Failed to sync products: ${error.message}`);
    },
  });

  const handleSyncProducts = () => {
    syncProductsMutation.mutate();
  };

    const customStyles = {
        rows: {
            style: {
                margin: '0', 
                padding: '0.5rem 0', 
            },
        },
        cells: {
            style: {
                padding: '0.5rem',
            },
        },
    };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data?.data.filter(
        (product) =>
            (filter === "All" ||
                (filter === "Active" && product.takedown_status === false) ||
                (filter === "Unactive" && product.takedown_status === true)) &&
            (product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.artist_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.UPC.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const tableColumns = [
        {
            name: "Title",
            cell: (row) => (
                <Link to={`${process.env.PUBLIC_URL}/app/products/${row.id}/assets`}>
                    {row["product_name"]}
                </Link>
            ),
        },
        {
            name: "UPC",
            selector: (row) => row["UPC"],
        },
        {
            name: "Image",
            cell: (row) => (
              <img
              src={row.image ? getProductCoverUrl(row.image) : require("../../../assets/images/user/7.jpg")}
              alt="product"
              style={{ height: "50px", width: "50px" }}
            />
            ),
        },
        {
            name: "Release Date",
            selector: (row) => row["release_date"],
        },
        {
            name: "Takedown Status",
            cell: (row) => (
                <Badge color={row["takedown_status"] ? "primary" : "success"}>
                    {row["takedown_status"] ? "Off" : "active"}
                </Badge>
            ),
        },
        {
            name: "Takedown Date",
            selector: (row) => (row["takedown_date"] ?  row["takedown_date"] : "N/A" ),
        },
        {
            name: "Artist Name",
            selector: (row) => row["artist_name"],
        },
    ];

  if (isLoading) return <GridLoader />;
  if (isError) return <div>Error loading products: {error.message}</div>;

  return (
    <>
      <Breadcrumbs
        parent="Products"
        title="Products"
        mainTitle="Products List"
        buttons={[
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",

              //   outline: true,
              onClick: handleSyncProducts,
            }}
          >
            <i class="fa fa-refresh"></i>  Sync Products
          </Btn>,
        ]}
      />


        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <h5>Products Table</h5>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                        <Input
                                            type="text"
                                            placeholder="Search by product name, artist name, or UPC"
                                            value={searchTerm}
                                            className="form-control"
                                            onChange={handleSearch}
                                            style={{ width: "400px", marginRight: "10px" }}
                                        />
                                        <Input
                                            type="select"
                                            value={filter}
                                            onChange={handleFilterChange}
                                            style={{ width: "150px", marginRight: "10px" }}
                                        >
                                            <option value="All">All</option>
                                            <option value="Active">Active</option>
                                            <option value="Unactive">Off</option>
                                        </Input>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={tableColumns}
                                data={filteredData}
                                striped={true}
                                center={true}
                                responsive={true}
                                pagination
                                customStyles={customStyles}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default ProductsContainer;
