import React, { Fragment } from 'react';
import { Row, Card, Col } from 'reactstrap';
import { H6, P } from '../../../../AbstractElements';
import { NoBookmarksFound } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { Heart, Share, Play, MessageCircle } from 'react-feather'; 
import GridLoader from '../../../Loaders/GridLoader';
const DataLoop = ({ instagramData }) => {
    if (!instagramData) {
        return <span> No Instagram Data Found </span>; 
      }
    return (
        <Fragment>
            {instagramData.length > 0 ? (
                <Row id='bookmarkData'>
                    {instagramData.map((dataItem) => (
                        <Col key={dataItem.id} xl='3' md='4' className='mb-4'>
                            <Card className='p-3 border-0 shadow-sm bg-light' >
                                <div className='text-center'>
                                    <div className='mb-1' style={{ height: '200px', overflow: 'hidden' }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundImage: `url(${dataItem.thumbnail_url})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        />
                                    </div>
                                    <span className='text-center text-muted'>{new Date(dataItem.created_at).toLocaleDateString()}</span>
                                    <div className='text-center'>
                                        <H6 className='mb-0'>{dataItem.title}</H6>
                                        <P className='text-muted mb-2'>
                                            <Link to={dataItem.posturl} target='_blank' rel='noopener noreferrer'>
                                                {dataItem.posturl}
                                            </Link>
                                        </P>
                                        <P className='mb-2'>{dataItem.desc}</P>
                                        <div className='text-muted'>
                                            <span title='Shares'>
                                                <Share size={16} style={{ marginRight: '5px' }} /> {dataItem.share_count || 0} |
                                            </span>
                                            <span title='Likes'>
                                                <Heart size={16} style={{ marginLeft: '5px', marginRight: '5px' }} /> {dataItem.like_count || 0} |
                                            </span>
                                            <span title='Plays Count' style={{ marginLeft: '5px' }}>
                                                <Play size={16} /> {dataItem.play_count || 0} |
                                            </span>
                                            <span title='Comments Count' style={{ marginLeft: '5px' }}>
                                                <MessageCircle size={16} /> {dataItem.comment_count || 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                <Col xl='12' className='text-center'>
                    Not Available yet
                </Col>
            )}
        </Fragment>
    );
};

export default DataLoop;
