import { Users, Video, ThumbsUp} from "react-feather";

export const AnghamiWidgetsData = [
  {
    title: "Total Followers",
    color: "primary",
    total: 2435,
    gros: 50,
    suffix: 'k',
    Icon: Users,
  },
  {
    title: "Total Plays",
    color: "secondary",
    total: 1234,
    suffix: 'k',
    gros: 25,
    Icon: Video,
  },
  {
    title: "Total Likes",
    color: "success",
    total: 3567,
    suffix: 'k',
    gros: 75,
    Icon: ThumbsUp,
  },
];
