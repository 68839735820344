import React, { Fragment, useContext } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import {
  Breadcrumbs,
  Btn,
  H4,
  H6,
  P
} from "../../../../AbstractElements";
import { SampleCard } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import { useNavigate, useParams  } from "react-router-dom";
import CustomizerContext from '../../../../_helper/Customizer';
import RealeaseTransfetDatable from './RealeaseTransfetDatable';
import { useBarcodeContext } from '../../../../_helper/Barcode/BarcodeProvider';





const ImportRelease = () => {
  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const { transfers, importProducts } = useBarcodeContext();




  return (
    <Fragment>
      <Breadcrumbs 
      mainTitle="Releases" 
      parent="Releases" 
      title="Import Releases" 
      buttons={[
        <Btn
          attrBtn={{
            size: "l",
            color: "primary",
            onClick: () => {
              navigate(
                `${process.env.PUBLIC_URL}/app/releases/catalog/${layoutURL}`
              );
            },
          }}
        >
          <i className="fa fa-arrow-left me-1"></i>
          {"Go to catalog"}
        </Btn>,
/*         <Btn
          attrBtn={{
            size: "l",
            color: "primary",
            //onClick: onUpdateMetada, 
          }}
        >
          <i className="fa fa-edit me-1"></i>
          Update Metatada
        </Btn>, */
        <Btn
          attrBtn={{
            size: "l",
            color: "primary",
            //onClick: handleDelivery, 
            onClick: () => document.getElementById('product-upload').click(), 
          }}
        >
          <i className="fa fa-check me-1"></i>
            Import Products
        </Btn>,
      ]}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title={'Transfert Product'} span1="This is where you can import products in bulk from CSV." />
              <CardBody>
                Here is your credentials of your FTP server:
                <RealeaseTransfetDatable importProducts={importProducts} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ImportRelease;